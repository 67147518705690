export const page5 = {
  id: "da7427e6-9ef8-4862-95ea-255f065926c9",
  slug: "page-5",
  title: "Page 5",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/blurredchairs.png") center`
};
