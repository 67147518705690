import { BaseSyntheticEvent } from "react";

export function mapEvent<T extends (...args: any[]) => any>(handler?: T, ...params: Parameters<T>) {
  return handler
    ? (e: BaseSyntheticEvent) => {
        return handler(
          ...params.map(param => {
            return param && typeof param === "function" ? param(e) : param;
          })
        );
      }
    : undefined;
}

export function nodeValue(e: BaseSyntheticEvent): string | null {
  return e && e.target && (e.target.nodeValue || e.target.value);
}
