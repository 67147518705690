import { ArticleData } from "../../../article";
import { pensioenPraat } from "./pages/pensioenpraat";

export const bondigPensioenpraat: ArticleData = {
  id: "572f4ce4-ed82-4906-996c-dd90cca3dc01",
  slug: "bondig-article-3",
  title: "PensioenPraat",
  index: "6",
  description: "",
  image: "/article/images/PensioenPraat.png",
  backgroundImage: "/article/images/orange_gradient.png",
  content: "",
  pages: [pensioenPraat],
  type: "article"
};
