import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D6C9B1",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const Rechtzaak2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.contentWrapper }}
      >
        <ContentLayoutColumns size="small" animation="trickle">
          <ContentColumn>
            <Typography variant="h6" paragraph>
              Eenmaal ter plaatse staan een paar welopgevoede heren mij
              vriendelijk te woord. Ogenschijnlijk zijn zij onbevooroordeeld
              over mijn komst.
            </Typography>
            <Typography>
              Wat niet wegneemt dat mij dringend wordt verzocht om mij te
              ontdoen van alle zware metalen. Portemonnee, telefoon en
              sleutelbos. De striptease van al die artikelen wordt afgerond als
              ook mijn broekriem eraan moet geloven. Met een besmuikt gelaat
              kijken ze toe hoe ik met afgezakte pantalon door het
              detectiepoortje wandel dat vervolgens indringend begint te piepen.
              Mijn horloge is de boosdoener. Deze meeslepende ervaring krijgt
              een vervolg als ik voor de balie sta waarachter een dame staat die
              niet meer precies weet of ze die ochtend het strijkijzer wel heeft
              uitgezet. Gedecideerd vraagt ze naar mijn oproep. Schijnbaar ben
              ik geslaagd want ik word doorverwezen naar de tweede etage.
            </Typography>
          </ContentColumn>
          <ContentColumn>
            <Typography>
              Daar staan tafels, stoelen en banken. Ook is er gratis koffie.
              Ondanks die versnapering hangt er een spanningsveld onder de
              wachtenden. Alleen zij weten wat ze hebben gedaan en van welke
              vergrijpen zij worden verdacht. Er zijn ook advocaten. Zij zijn
              gehuld in een toga en in een ernstig gesprek gewikkeld met hun
              cliënten. Ik neem plaats op een bank naast een echtpaar dat ook
              iets op hun kerfstok heeft. ‘Geen richting aangegeven op een
              rotonde’, vertelt de man mij desgevraagd. ‘En jij?’, vraagt hij
              nieuwsgierig. ‘Door rood licht gereden’, antwoord ik. ‘Maar wel
              met een geldige reden, maar dat zegt natuurlijk iedereen’, zeg ik
              ter verontschuldiging. Hij knikt en gelooft mij. Maar hij is niet
              de rechter. ‘Mijn vrouw heeft gezegd dat ik geen schijn van kans
              maak. We hebben zelfs gewed om honderd euro.’ Ik zeg dat met enige
              spijt in mijn stem. ‘Als ze die weddenschap wint gaat ze
              natuurlijk weer schoenen kopen.’ De man naast mij lacht en kijkt
              schuin naar zijn echtgenote die wijselijk zwijgt.
            </Typography>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
