import * as React from "react";
import { Typography, Hidden , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { SlideFade } from "../../../../../content/components/slide-fade";

interface OwnProps
  extends PageContentProps,
    WithStyles<
       "root" | "image" | "gridItem" | "contentWrapper"
    > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#d6cab1",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    margin: "0 50px"
  }
});

export const A3P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns spacing={4} size="big" animation="closeIn">
        <ContentColumn>
            <Typography paragraph variant="h6">
              Hij ontpopt zich als een ware plaag voor onze twee honden die,
              weliswaar met respect, maar ook met grote tegenzin, de indringer
              accepteren. Hij wordt gevolgd door onze twee kleinkinderen die
              onmiddellijk naar binnen rennen waarna ook hun twee ouders de
              woning betreden. Het is maar voor twee maanden, zeggen ze
              opgewekt. Daarna komen hun spullen.
            </Typography>
            <Typography>
              Vier fietsen, een brommer, speelgoed, een tuinameublement dat niet
              meer in de opslag past en een gedemonteerde schommelinstallatie.
              Maar ook kleding en jassen. Vervolgens schoenen, heel veel
              schoenen. Buiten worden onze parkeerplekken bezet. Want ook de
              twee auto’s maken deel uit van de tijdelijke inwoning.
            </Typography>
        </ContentColumn>
        <Hidden mdDown>
          <ContentColumn>
            <SlideFade direction="right" timeout={600}>

            <img
              src={"/article/images/dogs.png"}
              alt=""
              className={classes.image}
              />
              </SlideFade>
          </ContentColumn>
        </Hidden>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
