import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#73788C",
    display: "flex",
    justifyContent: "center",
    color: "#fff"

  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const Transitievergoeding3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.contentWrapper }}
      >
        <ContentLayoutColumns size="big" animation="trickle">
          <ContentColumn>
            <Typography gutterBottom variant="h5" className={classes.header}>
              Wanneer heb je geen recht op een transitievergoeding?
            </Typography>
            <Typography paragraph>
              Jouw werkgever hoeft geen transitievergoeding te betalen als de
              arbeidsovereenkomst met wederzijds goedvinden wordt ontbonden. Je
              doet er uiteraard echter wel goed aan om hierover stevig te
              onderhandelen.
            </Typography>
            <Typography paragraph>
              Ook als je ontslagen wordt wegens ernstig verwijtbaar handelden of
              ernstig verwijtbare nalatigheid kan je aanspraak op een
              transitievergoeding verloren gaan.
            </Typography>
            <Typography>
              Daarnaast zijn van het recht op een transitievergoeding
              uitgezonderd werknemers die nog geen 18 jaar zijn, die worden
              ontslagen en gemiddeld ten hoogste 12 uur per week werkzaam waren.
              Dit zal bij jouw werkgever vrijwel niet voorkomen.
            </Typography>
          </ContentColumn>
          <ContentColumn>
            <Typography paragraph>
              Indien je de dienst verlaat omdat je de AOW-gerechtigde of andere
              pensioengerechtigde leeftijd hebt bereikt of de dienst verlaat na
              het bereiken van deze leeftijd, heb je ook geen recht op een
              transitievergoeding.
            </Typography>
            <Typography>
              Een belangrijke uitzondering van het recht op een
              transitievergoeding is als in de cao een andere voorziening is
              afgesproken. In de cao kan vanaf 1 januari 2020 (ten nadele van de
              werknemer) worden afgeweken van de transitievergoeding en is meer
              maatwerk mogelijk. De cao moet dan wel voorzien in een redelijke
              financiële vergoeding of in voorzieningen die de kans op nieuw
              werk vergroten. In de nieuwe cao Rijk is daar (vooralsnog) geen
              sprake van.
            </Typography>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
