import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const voorwoord: ArticleData = {
  id: "8f7a7850-2cba-490d-87f2-7876699c0866",
  slug: "voorwoord",
  title: "Voorwoord",
  index: "7",
  description: "",
  image: "/article/images/voorwoord_thumb.png",
  backgroundImage: "/article/images/orange_gradient.png",
  content: "",
  pages: [page1],
  type: "article"
};
