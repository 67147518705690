import * as React from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Poll } from "../../../../../content/components/poll";

interface OwnProps
  extends PageContentProps,
    WithStyles<"root" | "background" | "content" | "montserrat"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundImage: "url(/article/images/poll_background.svg)",
    background:
      "linear-gradient(235deg, #005555 0%, #001111 100%) 0% 0% no-repeat padding-box",
    minHeight: `calc(100vh - ${theme.spacing(5)}px)`,
    alignItems: "center !important"
  },
  background: {
    opacity: 1,
    mixBlendMode: "darken"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  content: {
    maxWidth: 380
  }
});

export const BondigPoll1 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <>
        <LayoutDefault
          presenter={presenter}
          classes={{
            root: classes.root,
            background: classes.background,
            inner: classes.content
          }}
        >
          <Poll questionIndex={0} />
        </LayoutDefault>
      </>
    );
  }
);
