import * as React from "react";
import { Typography , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { Quote } from "../../../../../interface/partials/quote";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";

type OwnProps = PageContentProps &
  WithStyles<"root" | "smallTitle" | "gridItem" | "contentWrapper" | "desktop" | "mobile">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#161D51C2",
    // backgroundBlendMode: "multiply",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  mobile: {
    
  },
  desktop: {
    backgroundImage: "url('/article/images/sjoerd pc-gradient.png')",
    
  }
});

export const A5P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root, mobile: classes.mobile, desktop: classes.desktop }}>
      <ContentLayoutColumns size="small" animation="trickle">
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Kun je in het kort je fiscale loopbaan schetsen?
          </Typography>
          <Typography paragraph>
            ‘Het was nog een hele klus om bij de dienst aangenomen te worden.
            Bij mijn eerste sollicitatiegesprek in 1979 voor een baan bij de
            Douane in Zwolle, stond ik binnen één minuut weer buiten. Toen ik de
            vraag of ik nog in militaire dienst moest positief beantwoordde, kon
            ik gelijk weer gaan. Wél met de mededeling om het daarna zeker weer
            te proberen. Zo gezegd, zo gedaan. Na mijn diensttijd, waarbij ik
            vier maanden in Libanon heb gediend bij UNIFIL, kon ik in september
            1981 beginnen met de Douane-opleiding in het DOI in Elspeet. Die
            duurde vier maanden, maar dat had voor mij wel vier jaar mogen zijn.
            Een mooie tijd was dat.
          </Typography>
        </ContentColumn>

        <ContentColumn>
          <Typography paragraph>
            Na de opleiding werkte ik in Amsterdam, drie jaar in de Coenhaven en
            drie jaar in de Oliehaven. Vanwege de lange reistijd hospiteerde ik
            vaak in Amsterdam. In 1988 begon ik bij Douane Leeuwarden, waar ik
            ook drie jaar werkte.
          </Typography>

          <Quote variant="h4">
            Na verloop van tijd mocht ik mee op controles. Omdat dat naar meer
            smaakte, begon ik met het staatspraktijkdiploma boekhouden (SPD).
          </Quote>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
