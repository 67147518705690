import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { QandA } from "../../../../../interface/partials/qanda";
import classnames from "classnames";
import { RenderWhile } from "../../../../../content/components/render-while";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "bold" | "montserrat">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    margin: "auto 50px",
    maxWidth: "100%"
  },
  bold: {
    fontWeight: 600
  },
  montserrat: {
    fontFamily: "Montserrat"
  }
});

export const A6P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <QandA
        topHeader="Vraag"
        question={
          <Typography className={classnames(classes.montserrat, classes.bold)}>
            Ik wil na de invoering van het IKB graag mijn vakantie-uitkering en
            eindejaarsuitkering op hetzelfde tijdstip blijven ontvangen, zoals
            het nu ook geregeld is, dus in mei en november. Eigenlijk wil ik dus
            dat er voor mij niets verandert, maar hoe regel ik dit?
          </Typography>
        }
        bottomHeader="Antwoord"
        answer={
          <>
            <RenderWhile desktop mobile>
              <ContentLayoutColumns size="big" animation="trickle">
                <ContentColumn>
                  <Typography paragraph>
                    Vanaf 1 januari kan je iedere maand een keuze maken wat je
                    wil doen met jouw IKB-budget. In jouw geval moet je dan in
                    de maand mei 2020 aangeven – via het P-Direktportaal – dat
                    je jouw IKB-budget wil laten uitbetalen met de
                    salarisbetaling van mei.
                  </Typography>
                  <Typography paragraph>
                    Hetzelfde geldt in november 2020. In november moet je dan
                    aangeven dat je jouw IKB-budget wil laten uitbetalen met de
                    salarisbetaling van november. Vervolgens krijg je
                    automatisch het restant van het IKB-budget uitbetaald in
                    december.
                  </Typography>
                </ContentColumn>
                <ContentColumn>
                  <Typography>
                    Overigens zijn we nog bezig om juist deze keuze (die het
                    meest ligt bij hoe het nu wordt uitbetaald) op een heel
                    eenvoudige manier te laten registreren. Het is de bedoeling
                    dat deze keuze gelijk gemaakt kan worden via een knop bij
                    P-Direkt. Maar hierover is de NCF nog in onderhandeling met
                    de rijkswerkgever, dus hier hoor je later nog meer over.
                  </Typography>
                </ContentColumn>
              </ContentLayoutColumns>
            </RenderWhile>

            <RenderWhile print>
              <Typography paragraph>
                Vanaf 1 januari kan je iedere maand een keuze maken wat je wil
                doen met jouw IKB-budget. In jouw geval moet je dan in de maand
                mei 2020 aangeven – via het P-Direktportaal – dat je jouw
                IKB-budget wil laten uitbetalen met de salarisbetaling van mei.
              </Typography>
              <Typography paragraph>
                Hetzelfde geldt in november 2020. In november moet je dan
                aangeven dat je jouw IKB-budget wil laten uitbetalen met de
                salarisbetaling van november. Vervolgens krijg je automatisch
                het restant van het IKB-budget uitbetaald in december.
              </Typography>
              <Typography>
                Overigens zijn we nog bezig om juist deze keuze (die het meest
                ligt bij hoe het nu wordt uitbetaald) op een heel eenvoudige
                manier te laten registreren. Het is de bedoeling dat deze keuze
                gelijk gemaakt kan worden via een knop bij P-Direkt. Maar
                hierover is de NCF nog in onderhandeling met de rijkswerkgever,
                dus hier hoor je later nog meer over.
              </Typography>
            </RenderWhile>
          </>
        }
      />
    </LayoutDefault>
  );
});
