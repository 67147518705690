import { ClientFragment } from "./fragments";

export const ClientCreated = `
subscription {
  magazineCreated {
    ${ClientFragment}
  }
}
`;

export const ClientUpdated = `
subscription {
  magazineUpdated {
    ${ClientFragment}
  }
}
`;

export const ClientDeleted = `
subscription {
  magazineDeleted {
    ${ClientFragment}
  }
}
`;
