import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn6 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Re-integreren
              </Typography>
              <Typography paragraph>
                Bij re-integreren komt de re-integratiemanager om de hoek
                kijken. Deze begeleidt de zieke medewerker in het proces om weer
                volledig aan de slag te gaan. Re-integreren kan op je eigen
                werkplek, een andere plek binnen de Douane/Belastingdienst en in
                sommige gevallen buiten de Dienst. Maar re-integratie moet er
                uiteindelijk voor zorgen dat je weer op je oude werkplek komt
              </Typography>
              <Typography>
                ‘De aanpak van de re-integratiemanagers is heel verschillend’,
                legt Piet uit. ‘Er is altijd een spanningsveld tussen het ziek
                zijn van de medewerker en het weer zo spoedig mogelijk aan het
                werk gaan. In dit spanningsveld laveert de re-integratiemanager.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography paragraph>
                Er zijn re-integratiemanagers (samen met de casemanager, meestal
                de leidinggevende) die alles doen om de zieke medewerker weer
                zospoedig mogelijk aan het werk te krijgen, ook omdat ze onder
                druk staan van het ziekteverzuimcijfer.
              </Typography>
              <Typography>
                Dit kan leiden tot spanningen bij de zieke medewerker en zelfs
                tot conflicten. Maar er zijn ook re-integratiemanagers die
                rekening houden met de menselijke kant van het ziek zijn. Hun
                focus ligt veel meer bij de gezondheid van de medewerker en zij
                zullen veel meer hun best doen om de zieke medewerker te laten
                herstellen en te laten re-integreren op een verantwoorde en
                duurzame manier.’
              </Typography>
            </ContentColumn>
            <ContentColumn>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Probleemgevallen
              </Typography>
              <Typography paragraph>
                ‘Als IB’er kom je in dit proces vaak de probleemgevallen tegen’,
                vertelt Piet verder. Piet heeft in 1979 de opleiding gedaan op
                het Douane Opleidings Instituut in Mennorode en werd daarna bij
                Douane Amsterdam geplaatst. Daar werkte hij bij de secties
                Westhaven, Pakketpost en Douane Post Surveillance. In 2001 vroeg
                hij overplaatsing aan naar het kantoor in Enschede. In 2003
                rondde hij de F-opleiding af en in 2012 de EDP-audit opleiding.
                Vanaf 2003 is hij werkzaam in een klantmanagementteam van Douane
                Arnhem, locatie Enschede. Sinds 2005 is hij actief als IB’er van
                de NCF.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
