import * as React from "react";
import { Typography, Theme, WithStyles, Link } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#73788C",
    display: "flex",
    color: "#fff",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "column",
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const Transitievergoeding4 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.contentWrapper }}
      >
        <Typography paragraph variant="h5" className={classes.header}>
          Hoogte transitievergoeding
        </Typography>
        <Typography paragraph>
          De transitievergoeding bedraagt 1/3 maandsalaris voor elk kalenderjaar
          dat je in dienst was bij deze werkgever en een evenredig deel daarvan
          voor een periode dat de arbeidsovereenkomst korter dan een
          kalenderjaar heeft geduurd. Voor de berekening van de duur van de
          arbeidsovereenkomst worden een of meer voorafgaande
          arbeidsovereenkomsten tussen dezelfde partijen, die elkaar met
          tussenpozen van ten hoogste zes maanden hebben opgevolgd, samengeteld.
          De maximale hoogte van de transitievergoeding wordt elk jaar opnieuw
          bepaald. In 2019 bedroeg de transitievergoeding maximaal € 81.000,- of
          maximaal één bruto jaarsalaris als het jaarsalaris hoger was dan €
          81.000,-.  
        </Typography>
        <Typography style={{ fontFamily: "Montserrat" }}>
          Heb je vragen over de transitievergoeding? <BR />
          Neem dan{" "}
          <Link href="#" underline="always">
            contact
          </Link>{" "}
          met ons op <EndOfArticle />
        </Typography>
      </LayoutDefault>
    );
  }
);
