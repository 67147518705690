const StyledTextFragment = `
content
element
variant
style {
  fontWeight
  fontFamily
}
`;

export const ArticleFragment = `
  id
  image
  backgroundImage
  title
  content
  type
  index
  slug
  color
  featuredImage
  style {
    background
    backgroundColor
    backgroundImage
    backgroundRepeat
    backgroundSize
    backgroundAttachment
    backgroundPosition
    color
  }
  pages {
    id
    title
    content {
      title {
        ${StyledTextFragment}
      }
      readTime
      subtitle {
        ${StyledTextFragment}
      }
      category
      layout
      align
      description {
        ${StyledTextFragment}
      }
      authors {
        image
        credits {
          credit
          name
        }
      }
      media {
        embed
        maxWidth
        caption
      }
      size
      animation
    }
    background
    type
    filter
    overlay
    color
    layout
  }
`;
