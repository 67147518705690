import { IPresenter } from "../../helpers/with-presenter";
import { PagePresenter } from "./_page-default-presenter";

export class PageIndexPresenter extends PagePresenter implements IPresenter {
  public get items(): any[] {
    if (this.magazine && !this.edition) {
      return this.magazine.editions || [];
    }

    if (this.edition) {
      return this.edition.articles;
    }

    return this.magazines || [];
  }

  public get magazines() {
    return this._magazineInteractor.all;
  }

  public get articles() {
    return this._articleInteractor.articles;
  }

  public get editions() {
    return (this.magazine && this.magazine.editions) || [];
  }

  public mount = async () => {
    this._articleInteractor.transition = "fade-in-out";
    this._articleInteractor.selectedArticle = undefined;
  };

  public unmount = () => {
    //
  };

  public selectItem = (id: string) => {
    if (!this.magazine) {
      this._magazineInteractor.select(id);
      return;
    }

    if (!this.edition) {
      this._magazineInteractor.selectEdition(id);
      return;
    }

    this._articleInteractor.selectArticle(id);
  };
}
