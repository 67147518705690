import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { Quote } from "../../../../../interface/partials/quote";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<"root" | "background">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundImage: "url(/article/images/blurredchairs-gradient.png)",
    backgroundPosition: "center",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  background: {
    // background:
    //   "transparent linear-gradient(283deg, #FFFFFF 0%, #ddd3de 4%, #786679 100%) 0% 0% no-repeat padding-box",
    mixBlendMode: "multiply"
  }
});

export const A4P5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ desktop: classes.root, background: classes.background }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Quote variant="h4" gutterBottom>
            Medezeggenschap en vakbond zijn dringend nodig om alle ideeën die de
            werkgever heeft, te toetsen op consequenties voor de medewerker.
          </Quote>
        </ContentColumn>
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Lopen MZ- en vakbonds-belangen doorgaans niet door elkaar heen?
          </Typography>
          <Typography>
            ‘Ja, het klopt dat MZ- en vakbondsbelangen vaak door elkaar heen
            lopen. Bij de start moet je daarom zaken consequent scheiden, want
            anders loop je geheid vast. Daar waar het vakbondsbelang in het
            geding komt, verwijs ik collega’s direct door naar de
            belangen&shy;behartigers van hun bond. Als MZ’er wil ik zoveel als
            mogelijk proberen de juiste zaken in de juiste banen te leiden. En
            daar waar het nodig is zal ik me hard maken voor het bijstellen van
            de plannen. Dat is mooi werk, maar af en toe ook best wel moeizaam,
            tijdrovend en frustrerend.’
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Waarom ambieer jij een zetel in de OR-werkgroep Communicatie?
          </Typography>
          <Typography>
            ‘Ik moet bekennen dat goede communicatie met onze collega’s op de
            werkvloer de laatste jaren helaas ver te zoeken was. Dat heb ik
            gemerkt als lid van de onderdeelscommissie. Omdat we altijd in
            Utrecht vergaderden en we plaats- en tijdonafhankelijk werken, komen
            we allemaal minder op ons eigen kantoor dan voorheen. Tóch is goede
            communicatie met onze achterban van groot belang. Daarom wil ik me
            er dan ook sterk voor maken om dát te verbeteren. Lastig is en
            blijft natuurlijk altijd wél om te bepalen welke informatie de OR al
            dan niet kan delen met de achterban. Dat zal de OR elke keer opnieuw
            strategisch af moeten wegen.’
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
