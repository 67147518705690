import { DefaultStore, Store, StorageRecord } from "../storage/store";
import { GraphQLModel } from "./graphql-model";
import { GraphQLOptions } from "@aws-amplify/api/lib/types";

import { toJS } from "mobx";
import uuid from "uuid/v4";
import Application from "../application";

export interface GraphQLProviderProps<T extends GraphQLModel<TData>, TData extends StorageRecord> extends Store<TData> {
  createOperation(instance: TData): GraphQLOptions | undefined;
  fetchOperation(instance: TData): GraphQLOptions | undefined;
  updateOperation(instance: TData): GraphQLOptions | undefined;
  deleteOperation(instance: TData): GraphQLOptions | undefined;
  deleteOperation(instance: TData): GraphQLOptions | undefined;
  find(id: string): T | undefined;
  store(instance: T): Promise<void>;
  create: (data: Partial<TData>, persist?: boolean) => T;

  model: { new (...args: any[]): GraphQLModel<TData>; get: (id: string) => T } | undefined;
  serialize(instance: T): TData;
  subscribe(key: string, handler: (model: T) => void): void;
}

export default class GraphQLProvider<T extends GraphQLModel<TData>, TData extends StorageRecord> extends DefaultStore<
  TData
> {
  public subscribers: Record<string, Array<(model: T) => void>> = {
    //
  };

  constructor() {
    super();
    if ((this as any)._subscriptions) {
      (this as any)._subscriptions.forEach((subscribe: any) => {
        subscribe(this);
      });
    }
  }

  public store = async (instance: T) => {
    const operationFunc = (this as any).createOperation;
    const operation = operationFunc(this.serialize(instance));
    this.setRecord(instance.id, this.serialize(instance));

    if (operation) {
      await Application.network.fetch(operation.querystring, operation.variables);
    }
  };

  public find = (id: string) => {
    return this.createInstance(id);
  };

  public create = (data: Partial<TData> = {}, persist: boolean = true) => {
    const id = data.id || uuid();
    this.setRecord(id, data as TData);
    return this.createInstance(id);
  };

  public serialize(instance: T): TData {
    const data = toJS(this.getRecord(instance.id));
    const updates = toJS(instance.updated);
    return instance.serialize({ id: instance.id, ...data, ...updates });
  }

  public createInstance(id: string): T {
    return new (this as any).model(id, this);
  }

  public subscribe(key: string, handler: (model: T) => void) {
    this.subscribers[key] = this.subscribers[key] || [];
    this.subscribers[key].push(handler);
  }
}
