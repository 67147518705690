import { BaseModule } from "../module";
import DefaultMagazineInteractor, { MagazineInteractor } from "../data/magazine/magazine-interactor";
import DefaultArticleInteractor, { ArticleInteractor } from "../data/article/article-interactor";
import DefaultTemplateInteractor, { TemplateInteractor } from "../data/template/template-interactor";
import DefaultClientInteractor, { ClientInteractor } from "../data/client/client-interactor";
import { PollInteractor } from "./interactor/poll-interactor";
import { PrintInteractor } from "./interactor/print-interactor";

export interface BusinessInteractors {
  magazine: MagazineInteractor;
  article: ArticleInteractor;
  template: TemplateInteractor;
  client: ClientInteractor;
  poll: PollInteractor;
  print: PrintInteractor;
}

export interface BusinessModule extends BusinessInteractors {}

export class DefaultBusinessModule extends BaseModule<{}, BusinessInteractors, {}> implements BusinessModule {
  public get magazine(): MagazineInteractor {
    return this.loadInteractor("magazine", DefaultMagazineInteractor);
  }

  public get article(): ArticleInteractor {
    return this.loadInteractor("article", DefaultArticleInteractor, this);
  }

  public get template(): TemplateInteractor {
    return this.loadInteractor("template", DefaultTemplateInteractor);
  }

  public get client(): ClientInteractor {
    return this.loadInteractor("client", DefaultClientInteractor);
  }

  public get poll(): PollInteractor {
    return this.loadInteractor("poll", PollInteractor);
  }

  public get print(): PrintInteractor {
    return this.loadInteractor("print", PrintInteractor);
  }

  public get mockMode(): boolean {
    return true;
  }

  public get printLayout(): boolean {
    return false;
  }
}
