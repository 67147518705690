import * as React from "react";
import { observer } from "mobx-react";
import { StyleRules, WithStyles, withStyles } from "@material-ui/styles";
import { Theme, Typography, Fade } from "@material-ui/core";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faChevronUp,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { InterfacePresenter } from "../interface-presenter";
import { ArticleRedirect } from "../../content/components/article-redirect";
import { RenderWhile } from "../../content/components/render-while";

//TODO: [OMA-207] Create Page navigational components

interface OwnProps
  extends WithStyles<
    | "root"
    | "button"
    | "next"
    | "prev"
    | "page"
    | "article"
    | "arrow"
    | "arrowUp"
    | "arrowDown"
    | "link"
    | "buttonWrapper"
    | "fa"
    | "faHover"
  > {
  presenter: InterfacePresenter;
  color?: string;
}

const styles = (theme: Theme): StyleRules => ({
  "@keyframes bounce-up": {
    from: { transform: "translateY(0%) scale(1,1)" },
    "2%": { transform: "translateY(-2%) scale(0.95, 1.05)" },
    "4%": { transform: "translateY(-10%) scale(1.05,0.95)" },
    "12%": { transform: "translateY(1%)" },
    "15%": { transform: "translateY(0%) scale(1,1)" },
    to: { transform: "translateY(0%)" },
  },
  "@keyframes bounce-down": {
    from: { transform: "translateY(0%) scale(1,1)" },
    "2%": { transform: "translateY(2%) scale(0.95, 1.05)" },
    "4%": { transform: "translateY(10%) scale(1.05,0.95)" },
    "12%": { transform: "translateY(-1%)" },
    "15%": { transform: "translateY(0%) scale(1,1)" },
    to: { transform: "translateY(0%)" },
  },
  root: {
    zIndex: 10,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: theme.spacing(5),
    pointerEvents: "none",
    "& *": {
      pointerEvents: "all",
      fontFamily: "Montserrat",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    transition: "color 0.2s",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  button: {
    display: "flex",
    color: "inherit",
    flexDirection: "column",
    alignItems: "center",
    background: "none",
    border: "none",
    cursor: "pointer",
    outline: 0,
    opacity: 0.8,
    WebkitTapHighlightColor: "transparent",
    "&:hover": {
      opacity: 1,
      "& $arrow": {
        borderWidth: "15px",
      },
      "& $fa": {
        opacity: 0,
      },
      "& $faHover": {
        opacity: 1,
      },
      "& $buttonText": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },
  prev: {
    flexDirection: "column",
    "& $arrow": {
      animation: "$bounce-down 3500ms ease-in-out infinite",
    },
    "& $buttonText": {
      transform: "translateY(-20px)",
    },
  },
  next: {
    flexDirection: "column-reverse",
    "& $arrow": {},
    "& $buttonText": {
      transform: "translateY(20px)",
    },
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "inherit",
    border: "2px solid",
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    transform: "translateY(0)",
    transition: "all 0.1s ease-out",
  },
  faHover: {
    opacity: 0,
  },
  fa: {
    position: "absolute",
    transition: "all 0.2s linear",
    "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
      transform: "translate(-0.39em, -0.435em)",
    },
  },
  buttonText: {
    margin: theme.spacing(1),
    opacity: 0,
    transition: "all 0.1s ease-out",
  },
});

interface ButtonProps
  extends WithStyles<
    | "button"
    | "next"
    | "prev"
    | "article"
    | "arrow"
    | "arrowUp"
    | "fa"
    | "faHover"
    | "buttonText"
  > {
  onClick(): void;
  children: React.ReactNode;
  down?: boolean;
  up?: boolean;
}

const Button = withStyles(styles)(
  observer(({ classes, onClick, children, up, down }: ButtonProps) => {
    return (
      <Fade in timeout={800}>
        <div>
          <button
            onClick={onClick}
            className={classnames(
              classes.button,
              up && classes.next,
              down && classes.prev
            )}
          >
            <Typography className={classes.buttonText} variant="body2">
              {children}
            </Typography>
            {up && (
              <div className={classnames(classes.arrow, classes.arrowUp)}>
                <FontAwesomeIcon
                  className={classes.fa}
                  icon={faChevronUp}
                  size={"lg"}
                />
                <FontAwesomeIcon
                  className={classnames(classes.fa, classes.faHover)}
                  icon={faChevronCircleUp}
                  size={"2x"}
                />
              </div>
            )}
            {down && (
              <div className={classnames(classes.arrow, classes.arrowUp)}>
                <FontAwesomeIcon
                  className={classes.fa}
                  icon={faChevronDown}
                  size={"lg"}
                />
                <FontAwesomeIcon
                  className={classnames(classes.fa, classes.faHover)}
                  icon={faChevronCircleDown}
                  size={"2x"}
                />
              </div>
            )}
          </button>
        </div>
      </Fade>
    );
  })
);

export const PageNavigator = withStyles(styles)(
  observer(({ classes, presenter }: OwnProps) => {
    console.log(presenter.isArticle, presenter.aroundEnd);

    return (
      (presenter.isArticle && (
        <div className={classes.root} style={{ color: presenter.color }}>
          <div className={classes.buttonWrapper}>
            {presenter.aroundStart && (
              <>
                {presenter.hasPreviousPage && (
                  <RenderWhile desktop>
                    <Button up onClick={presenter.toPreviousPage}>
                      Vorige pagina
                    </Button>
                  </RenderWhile>
                )}
              </>
            )}
          </div>

          <div className={classes.buttonWrapper}>
            {presenter.aroundEnd && (
              <>
                {presenter.hasNextPage && (
                  <RenderWhile desktop>
                    <Button down onClick={presenter.toNextPage}>
                      Volgende pagina
                    </Button>
                  </RenderWhile>
                )}

                <RenderWhile desktop mobile>
                  {presenter.showNextArticle && (
                    <ArticleRedirect
                      onClick={presenter.toNextArticle}
                      thumbnail={
                        presenter.nextArticle &&
                        (presenter.nextArticle.featuredImage
                          ? presenter.nextArticle.featuredImage
                          : presenter.nextArticle.image)
                      }
                    >
                      {presenter.nextArticle && presenter.nextArticle.title}
                    </ArticleRedirect>
                  )}
                </RenderWhile>
              </>
            )}
          </div>
        </div>
      )) ||
      null
    );
  })
);
