export const page1 = {
  id: "6a4bb5c2-0252-48e7-8941-56c4a13ff32a",
  slug: "page-1",
  title: "Article 2, Page 1",
  description: "Some page description",
  content: {
    category: "Interview",
    title: {
      content: "Edwin Janssens: ‘De OR \nleeft écht bij mijn collega’s \nop de werkvloer’",
      variant: "h1"
    },
    readTime: "6,5 min",
    layout: "centered",
    authors: {
      image: "/article/images/frank.png",
      credits: [
        { credit: "Tekst", name: "Frank Verweij" },
        { credit: "Beeld", name: "Willem van Duijn" }
      ]
    }
  },
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  layout: "ContentLayoutCover"
};
