import { IPresenter } from "../../helpers/with-presenter";
import { MagazineInteractor } from "../../application/data/magazine/magazine-interactor";
import { ArticleInteractor } from "../../application/data/article/article-interactor";
import { Article, ArticlePage } from "../../application/data/article/article";
import { EditionData, Magazine } from "../../application/data/magazine/magazine";
import { computed, observable } from "mobx";
import { PrintInteractor } from "../../application/business/interactor/print-interactor";
// import { Article } from "../../application/data/article/article";

export class InterfacePresenter implements IPresenter {
  private _scrollTimeout: NodeJS.Timeout | undefined = undefined;
  private _xDown = 0;
  private _yDown = 0;
  private _resetTimeout: any = null;

  @observable public pollOpen: boolean = true;
  @observable private _pageYOffset: number = 0;
  @observable private _scrollHeight: number = 0;
  // Lists

  @computed public get articles(): Article[] {
    return this._articleInteractor.articles;
  }

  @computed public get pages(): ArticlePage[] {
    return this._articleInteractor.pages;
  }

  // Current Objects

  @computed public get magazine(): Magazine | undefined {
    return this._magazineInteractor.selected;
  }

  @computed public get edition(): EditionData | undefined {
    return this._magazineInteractor.selectedEdition;
  }

  @computed public get article(): Article | undefined {
    return this._articleInteractor.selectedArticle;
  }

  @computed public get page(): ArticlePage | undefined {
    return this._articleInteractor.selectedPage;
  }

  @computed public get color(): string {
    let color = this._articleInteractor.selectedPage && this._articleInteractor.selectedPage.color;

    return color || "white";
  }

  // Indexes

  @computed public get pageIndex(): number {
    return this._articleInteractor.pageIndex;
  }

  @computed public get articleIndex(): number {
    return this._articleInteractor.articleIndex;
  }

  // Previous / Next components

  @computed public get nextPage(): ArticlePage | undefined {
    return this.pageIndex + 1 < this.pages.length ? this.pages[this.pageIndex + 1] : undefined;
  }

  @computed public get previousPage(): ArticlePage | undefined {
    return this.pageIndex - 1 >= 0 ? this.pages[this.pageIndex - 1] : undefined;
  }

  @computed public get nextArticle(): Article | undefined {
    return this.articleIndex + 1 < this.articles.length ? this.articles[this.articleIndex + 1] : undefined;
  }

  @computed public get previousArticle(): Article | undefined {
    return this.articleIndex - 1 >= 0 ? this.articles[this.articleIndex - 1] : undefined;
  }

  // Booleans

  @computed public get showPollButton(): boolean {
    return this.pollOpen && !this.isCover && !this.isPoll && ((!this.isDesktop && !this.aroundEnd) || this.isDesktop);
  }

  @computed public get showNextArticle(): boolean {
    if (this.nextArticle && this.aroundEnd) {
      if (this.isDesktop && !this.nextPage) {
        return true;
      }

      if (!this.isDesktop) {
        return true;
      }
    }
    return false;
  }

  @computed public get isDesktop(): boolean {
    return window.innerWidth > 960;
  }

  @computed public get isPrint(): boolean {
    return this._printInteractor.printActive;
  }

  @computed public get isCover(): boolean {
    return this.article && this.article.type === "cover" ? true : false;
  }

  @computed public get isPoll(): boolean {
    return this.article && this.article.type === "poll" ? true : false;
  }

  @computed public get isArticle(): boolean {
    const art = this.article;

    return art && art.type === "article" ? true : false;
  }

  @computed public get hasNextPage(): boolean {
    return this.nextPage ? true : false;
  }

  @computed public get hasNextArticle(): boolean {
    return this.nextArticle ? true : false;
  }

  @computed public get hasPreviousPage(): boolean {
    return this.previousPage ? true : false;
  }

  @computed public get hasPreviousArticle(): boolean {
    return this.previousArticle ? true : false;
  }

  @computed public get isSingleMagazine(): boolean {
    return this._magazineInteractor.all.length === 1;
  }

  // Start/End Helpers

  @observable public aroundStart = false;
  @observable public aroundEnd = false;

  // Initialisation

  constructor(
    protected _magazineInteractor: MagazineInteractor,
    protected _articleInteractor: ArticleInteractor,
    protected _printInteractor: PrintInteractor
  ) {
    //
  }

  public mount = () => {
    this._articleInteractor.onArticleChange(this.resetValues);
    this._scrollHeight = document.body.scrollHeight;
    this._pageYOffset = window.pageYOffset;

    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);

    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("wheel", this.onScroll);

    window.onresize = this.resetValues;
    window.onscroll = () => {
      this._pageYOffset = window.pageYOffset;
    };
  };

  public resetValues = () => {
    this._pageYOffset = window.pageYOffset;
    this._scrollHeight = document.body.scrollHeight;
    this.aroundStart = false;
    this.aroundEnd = false;

    clearTimeout(this._resetTimeout);
    this._resetTimeout = setTimeout(() => {
      this.checkScrollHeight();
    }, 1500);
  };

  private checkScrollHeight = () => {
    if (this._scrollHeight !== document.body.scrollHeight) {
      this._scrollHeight = document.body.scrollHeight;
    }

    this.aroundStart = this._pageYOffset < 70;
    this.aroundEnd = this._pageYOffset + window.innerHeight > this._scrollHeight - 70;
  };

  public unmount = () => {
    window.removeEventListener("keydown", this.onKeyDown);
  };

  // Actions

  public toNextPage = () => {
    this._pageYOffset = 0;
    this._articleInteractor.nextPage();
  };

  public toPreviousPage = () => {
    this._pageYOffset = 0;
    this._articleInteractor.previousPage();
  };

  public toNextArticle = () => {
    this._pageYOffset = 0;
    this._articleInteractor.nextArticle();
  };

  public toPreviousArticle = () => {
    this._pageYOffset = 0;
    this._articleInteractor.previousArticle();
  };

  public hidePoll = () => {
    this.pollOpen = false;
  };

  public toPoll = () => {
    this._articleInteractor.selectArticle("686fd9a7-324f-44af-9b20-eb90dfb7e87c", true);
  };

  public printArticle = () => {
    this._printInteractor.printActive = !this._printInteractor.printActive;
  };

  public sharePage = (e: Event) => {
    document.execCommand("copy", false, window.location.href);
    // Werkt niet?
  };

  // Privates

  private onKeyDown = (e: KeyboardEvent) => {
    if (e.defaultPrevented) {
      return;
    }

    switch (e.key) {
      case "Up":
      case "ArrowUp":
        if (this.isDesktop) {
          this.toPreviousPage();
        }
        break;

      case "Down":
      case "ArrowDown":
        if (this.isDesktop) {
          this.toNextPage();
        }
        break;

      case "Left":
      case "ArrowLeft":
        this.toPreviousArticle();
        break;

      case "Right":
      case "ArrowRight":
        this.toNextArticle();
        break;

      default:
        break;
    }
  };

  private onScroll = (e: MouseWheelEvent) => {
    const delta = Math.sign(e.deltaY);
    const strength = Math.abs(e.deltaY);
    const scrollStart = window.pageYOffset === 0;
    const scrollEnd = window.pageYOffset + window.innerHeight === document.body.scrollHeight;

    if ((scrollStart || scrollEnd) && strength > 250) {
      if (!this.isDesktop || this.isPrint) {
        return;
      }

      this.checkScrollHeight();

      if (!this._scrollTimeout) {
        if (delta > 0) {
          if (this.hasNextPage) {
            this.toNextPage();
          }
        }

        if (delta < 0) {
          if (this.hasPreviousPage) {
            this.toPreviousPage();
          }
        }
      }
    }
    this._scrollTimeout = setTimeout(() => {
      this._scrollTimeout = undefined;
      this.checkScrollHeight();
    }, 500);
  };

  private getTouches = (evt: any) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  private handleTouchStart = (evt: any) => {
    const firstTouch = this.getTouches(evt)[0];
    this._xDown = firstTouch.clientX;
    this._yDown = firstTouch.clientY;
  };

  private handleTouchMove = (evt: any) => {
    if (!this._xDown || !this._yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this._xDown - xUp;
    var yDiff = this._yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      console.log("x swipe");
      if (xDiff > 0) {
        this.toNextArticle();
      } else {
        this.toPreviousArticle();
      }
    } else {
      this.checkScrollHeight();
      setTimeout(this.checkScrollHeight, 500);

      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this._xDown = 0;
    this._yDown = 0;
  };
}
