export const page5 = {
  id: "142eaf22-aa27-4b9b-a9cd-224a7dd96c92",
  slug: "page-5",
  title: "Page 5",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  background: "#786679",
  color: "#4a4a4a"
};
