import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "background"
    | "title"
    | "wrapper"
    | "gridItem"
    | "maxWidth"
    | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    color: "#4A4A4A",
    backgroundImage: "url('/article/images/ziek_zijn-cover.png')",
    backgroundColor: "#FFE288",
    backgroundSize: "auto 90vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `35vw 50%`,
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "auto 100vh",
      backgroundPosition: "calc(60% + 15vw) 0",
    },
    paddingLeft: `calc(${theme.spacing(6)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
  },
  mobile: {
    backgroundImage: "none",
    // backgroundSize: "auto 45vh",
    // backgroundPosition: "50vw 100%",


  },
  background: {
    // background: "linear-gradient(to right, #FFE288 40%, #00000000 100%)",
    // opacity: 0.7
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  wrapper: {
    maxWidth: 1400,
    width: "100%",
    margin: "0 auto",
  },
});

export const ZiekZijnCover = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
        }}
      >
        <ContentLayoutCover
          category="Interview"
          readTime="5 min"
          title={
            <Typography variant="h1" className={classes.title}>
              Ziek zijn... <BR />
              wat nu? <BR />
            </Typography>
          }
          subtitle={
            <Typography variant="h5">
              Interview met Piet van Andel, <BR/>Individueel Belangenbehartiger NCF
            </Typography>
          }
          authors={{
            image: "/article/images/peter.png",
            credits: [{ credit: "Tekst", name: "Peter van Diepen" }],
          }}
          description=""
          size="small"
        />
      </LayoutDefault>
    );
  }
);
