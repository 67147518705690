import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";

export const wistJeDat: ArticleData = {
  id: "d7e2f4d7-9a4f-484f-a5fc-8859cf7e773b",
  slug: "bondig-article-8",
  title: "Wist je dat?",
  description: "",
  index: "8",
  image: "/article/images/wist_je_dat_52-thumb.png",
  backgroundImage: "/article/images/a8cover.png",
  content: "",
  pages: [page1, page2, page3, page4, page5],
  type: "article",
  style: {
    backgroundColor: "#528CB2"
  }
};
