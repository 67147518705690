import * as React from "react";
import { withStyles, Typography, Slide, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import classnames from "classnames";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps
  extends WithStyles<
    "root" | "quote" | "symbol" | "topLine" | "slide" | "fade"
  > {
  children?: any;
  variant?: any;
  gutterBottom?: boolean;
  dark?: boolean;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: "20px 0 0 0",
    position: "relative",
    overflow: "hidden"
  },
  topLine: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 5,
    width: "100%",
    transition: "transform 0.3s ease-out",
    transform: "scaleX(0)",
    transformOrigin: "100% 0"
  },
  slide: {
    transform: "scaleX(1)"
  },
  symbol: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    width: "20px",
    transition: "opacity 0.6s ease-out"
  },
  quote: {
    fontFamily: "Domaine",
    fontStyle: "Italic"
  }
});

class QuoteComponent extends React.PureComponent<OwnProps, { play: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      play: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ play: true });
    }, 500);
  }

  public render() {
    const { children, classes, variant, gutterBottom, dark } = this.props;
    return (
      <>
        <RenderWhile desktop>
          <div className={classes.root}>
            <span
              className={classnames(
                classes.topLine,
                this.state.play && classes.slide
              )}
              style={{ backgroundColor: dark ? "#4a4a4a" : "white" }}
            ></span>
            <Slide in={this.state.play} direction="down" timeout={1200}>
              <img
                src={
                  dark
                    ? "/assets/images/quotes-dark.svg"
                    : "/assets/images/quotes.svg"
                }
                alt=""
                className={classes.symbol}
              />
            </Slide>

            {children && (
              <Slide in={this.state.play} direction="down" timeout={1200}>
                <Typography
                  gutterBottom={gutterBottom}
                  className={classes.quote}
                  variant={variant ? variant : "h5"}
                >
                  {children}
                </Typography>
              </Slide>
            )}
          </div>
        </RenderWhile>
        <RenderWhile mobile>
          <div className={classes.root}>
            <span
              className={classnames(
                classes.topLine,
                this.state.play && classes.slide
              )}
              style={{ backgroundColor: dark ? "#4a4a4a" : "white", margin: "20 0" }}
            ></span>
            <Slide in={this.state.play} direction="down" timeout={1200}>
              <img
                src={
                  dark
                    ? "/assets/images/quotes-dark.svg"
                    : "/assets/images/quotes.svg"
                }
                alt=""
                className={classes.symbol}
              />
            </Slide>
            <Typography
              paragraph
              className={classes.quote}
              variant={variant ? variant : "h5"}
            >
              {children}
            </Typography>
          </div>
        </RenderWhile>

        <RenderWhile print>
          <div className={classes.root} style={{ padding: 0 }}>
            <span className={classnames(classes.topLine, classes.fade)}></span>
            <img
              src="/assets/images/quotes.svg"
              alt=""
              className={classes.symbol}
            />
            <Typography
              className={classes.quote}
              variant={"h6"}
              style={{ fontSize: "2.5em" }}
              gutterBottom
            >
              {children}
            </Typography>
          </div>
        </RenderWhile>
      </>
    );
  }
}

export const Quote = withStyles(styles)(QuoteComponent);
