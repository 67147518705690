import { bondigArticle1 } from "../../article/__mock__/bondig-51/bondig-article-1/article";
import { bondigArticle2 } from "../../article/__mock__/bondig-51/bondig-article-2/article";
import { bondigArticle3 } from "../../article/__mock__/bondig-51/bondig-article-3/article";
import { bondigArticle4 } from "../../article/__mock__/bondig-51/bondig-article-4/article";
import { bondigArticle5 } from "../../article/__mock__/bondig-51/bondig-article-5/article";
import { bondigArticle6 } from "../../article/__mock__/bondig-51/bondig-article-6/article";
import { bondigArticle7 } from "../../article/__mock__/bondig-51/bondig-article-7/article";
import { bondigArticle8 } from "../../article/__mock__/bondig-51/bondig-article-8/article";
import { bondigVoorwoord } from "../../article/__mock__/bondig-51/bondig-voorwoord/article";
import { bondigWinnaars } from "../../article/__mock__/bondig-51/bondig-winnaars/article";
import { bondigPensioenpraat } from "../../article/__mock__/bondig-51/bondig-pensioenpraat/article";
import { bondigPoll } from "../../article/__mock__/bondig-51/bondig-poll/article";
import { bondigArticleFotograafGezocht } from "../../article/__mock__/bondig-51/fotograaf-gezocht/article";
import { bondigBestuursverkiezing } from "../../article/__mock__/bondig-51/bondig-bestuursverkiezing/article";
import { bondigVerruimingBedrijfsfitness } from "../../article/__mock__/bondig-51/bondig-verruiming-bedrijfsfitness/article";
import { cover } from "../../article/__mock__/bondig-52/cover/article";
import { noaHolleman } from "../../article/__mock__/bondig-52/noa_holleman/article";
import { china } from "../../article/__mock__/bondig-52/china/article";
import { rechtzaak } from "../../article/__mock__/bondig-52/rechtzaak/article";
import { ziekZijn } from "../../article/__mock__/bondig-52/ziek_zijn/article";
import { transitievergoeding } from "../../article/__mock__/bondig-52/transitievergoeding/article";
import { voorwoord } from "../../article/__mock__/bondig-52/voorwoord/article";
import { wistJeDat } from "../../article/__mock__/bondig-52/wist_je_dat/article";

export const bondigMagazine: any = {
  id: "39178448-2935-45c9-be79-d2f8cbd6e37a",
  image: "assets/images/article_cover.png",
  logo: "/assets/images/bondig-logo.svg",
  favicon: "/assets/images/ncf-favicon.ico",
  title: "Bondig Magazine",
  description: "Het vakbondsblad van de NCF",
  slug: "bondig",
  editors: [
    { type: "Hoofdredactie", users: ["Chris Regtop"] },
    {
      type: "Redactie",
      users: [
        "Frank Verweij",
        "Peter van Diepen",
        "Gerard Pereboom",
        "Bart Lammers",
        "Ad van der Donk",
        "Carien Scholtmeijer",
        "Petra Bosma"
      ]
    },
    { type: "Eindredactie", users: ["Daphne Duif"] },
    {
      type: "Fotografie",
      users: ["Rob van Polanen", "Paul Heijne", "Willem van Duijn"]
    },
    { type: "Cartoonist", users: ["Cees den Otter"] },
    { type: "Ontwerp", users: ["Creative Bastards Enschede"] }
  ],
  contactDetails: {
    email: "redactie@ncf.nl"
  },
  editions: [
    {
      id: "51",
      index: "51",
      coverArticle: bondigArticle2,
      image: "/article/images/cover.png",
      title: "Inclusiviteit",
      subtitle: "Een nieuw jasje",
      slug: "inclusiviteit",
      description: [
        {
          style: {
            fontWeight: "500"
          },
          content:
            "We hebben de afgelopen maanden hard gewerkt om ons vakbondsblad in een nieuw jasje te steken. Als redactie zijn we van mening dat dit goed is gelukt en met trots kunnen we nu de eerste interactieve Bondig aan jou presenteren.",
          element: "small"
        },

        {
          content:
            "We hopen natuurlijk dat jij ook blij bent met deze vernieuwing. Laat ons via het laatste hoofdstuk dus vooral weten hoe jouw leeservaring van Bondig 51 was. De input die we van onze lezers krijgen zullen we zeker meenemen bij het maken van de volgende editie.",
          element: "small"
        },

        {
          content: "We wensen je veel lees- en kijkplezier en tevens natuurlijk alvast fijne feestdagen!",
          element: "small"
        },

        {
          content: "Chris Regtop",
          element: "small",
          marginBottom: false
        },
        {
          content: "Hoofdredacteur NCF",
          element: "small",
          marginBottom: false
        }
      ],

      articles: [
        bondigArticle1,
        bondigVoorwoord,
        bondigArticle4,
        bondigArticle3,
        bondigVerruimingBedrijfsfitness,
        bondigPensioenpraat,
        bondigArticle2,
        bondigArticleFotograafGezocht,
        bondigArticle6,
        bondigArticle5,
        bondigArticle7,
        bondigWinnaars,
        bondigBestuursverkiezing,
        bondigArticle8,
        bondigPoll
      ]
    },
    {
      id: "52",
      index: "52",
      coverArticle: bondigArticle2,
      image: "/article/images/cover.png",
      title: "Nieuw jaar",
      subtitle: "Bondig 2020",
      slug: "bondig-2020",
      description: [
        {
          element: "small",
          content: "De NCF wenst jullie een prettig nieuw jaar"
        }
      ],
      articles: [cover, noaHolleman, china, rechtzaak, ziekZijn, transitievergoeding, wistJeDat, voorwoord]
    }
  ],

  colofon: []
};
