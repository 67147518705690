import * as React from "react";
import { Typography, Hidden , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Quote } from "../../../../../interface/partials/quote";

type OwnProps = PageContentProps & WithStyles<"root" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#161D51",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    width: "50vw",
    margin: "auto 2vw auto 0"
  }
});

export const A5P6 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="closeIn">
        <Hidden mdDown>
          <ContentColumn style={{ display: "flex", maxWidth: 600 }}>
            <img
              className={classes.image}
              src="/article/images/geldzak.svg"
              alt=""
            />
          </ContentColumn>
        </Hidden>

        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography variant="h5" style={{fontFamily: "Montserrat", fontWeight: 500}} gutterBottom>
            De Belastingdienst is de laatste jaren vaak minder goed in het
            nieuws. Raakt je dat?
          </Typography>

          <Typography paragraph>
            ‘Zeker! Vooral ook omdat het negen van de tien keer onze schuld niet
            is. Wij zijn immers een uitvoerende organisatie. De laatste tijd
            gonst het weer rond Toeslagen. Dat vind ik erg jammer voor de
            collega’s daar, omdat ik weet dat ze knetterhard werken. Ook ben ik
            in mijn omgeving vaak aangesproken over onze ‘goudgerande’
            vertrekregeling. Maar als ik dan vertel dat we daarbij geen recht
            hebben op WW, reageren ze erg verbaasd en beseffen ze dat het niet
            alles goud is wat er blinkt.’
          </Typography>
          <Quote variant="h4">
            Geef B’ers en C’ers een reële kans op een toekomst bij de
            Belastingdienst!
          </Quote>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
