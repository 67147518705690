import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "image"
    | "title"
    | "link"
    | "montserrat"
    | "wrapper"
    | "mobile"
    | "print"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#528CB2",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center",
    },
  },
  print: {},
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
  },
  wrapper: {
    maxWidth: "calc(500px + 5vw)",
  },
});

export const WistJeDat2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile,
          print: classes.print,
        }}
      >
        <ContentLayoutCentered
          textAlign="left"
          media={
            <TextBoard
              image="/article/images/vent-turquoise.png"
              color="#B8FEE7"
              titleColor="#4a4a4a"
              title={
                <>
                  <Typography variant="h5">1/4 Wist je dat...?</Typography>
                </>
              }
              subtitle={
                <Typography variant="h5" style={{ fontStyle: "italic" }}>
                  ...je een tegemoetkoming kunt <BR />
                  krijgen in de kosten voor een <BR />
                  beeldschermbril?
                </Typography>
              }
            >
              <Typography>
                De maximale vergoeding hiervoor is € 209,-. Het kan zijn dat
                jouw ogen niet toekunnen met een gangbare beeldschermbril, maar
                dat jij een speciale beeldschermbril nodig hebt waarvan de
                kosten veel hoger liggen. In dat geval worden de kosten (op
                grond van Arbo-regelgeving) door jouw werkgever betaald.
              </Typography>
            </TextBoard>
          }
          size="small"
        />
      </LayoutDefault>
    );
  }
);
