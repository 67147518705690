export const page3 = {
  id: "d093feb7-218b-49a6-b2b7-c58515f28681",
  slug: "page-3",
  title: "Page 3",
  description: "Some page description",
  content: "Article 2, page 3",
  type: "article",
  filter: "blur(10px)",
  background: "#786679"
};
