import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const cover: ArticleData = {
  id: "64cca16e-1b41-48a8-9c02-3c07b275f484",
  slug: "cover",
  index: "1",
  title: "Cover",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  image: "/article/images/cover.png",
  content: "",
  pages: [page1],
  type: "cover"
};
