import * as React from "react";

import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { ArticlePresenter } from "./article-presenter";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";

import "../transitions/slide-left.css";
import { RenderWhile } from "../content/components/render-while";

import { ArticlePrintContent } from "./device/article/print";
import { ArticleDesktopContent } from "./device/article/desktop";
import { ArticleMobileContent } from "./device/article/mobile";

interface OwnProps extends RouteComponentProps {
  id: string;
  render: boolean;
  color?: string;
}

export interface PageContentProps {
  presenter: ArticlePresenter;
}

const ArticlePageComponent = observer(
  ({ presenter }: OwnProps & PresenterProps<ArticlePresenter>) => {
    return (
      <>
        <RenderWhile print>
          <ArticlePrintContent presenter={presenter} />
        </RenderWhile>

        <RenderWhile mobile>
          <ArticleMobileContent presenter={presenter} />
        </RenderWhile>

        <RenderWhile desktop>
          <ArticleDesktopContent presenter={presenter} />
        </RenderWhile>
      </>
    );
  }
);

export const ArticlePage = withRouter(
  withPresenter<ArticlePresenter, OwnProps>(
    ({ match, history, location, staticContext }: OwnProps, { business }) =>
      new ArticlePresenter(business.magazine, business.article, {
        match,
        history,
        location,
        staticContext
      }),
    ArticlePageComponent
  )
);
