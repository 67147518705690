import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "background"
    | "wrapper"
    | "smallTitle"
    | "gridItem"
    | "maxWidth"
    | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundImage: "url('/article/images/a3cover-gradient.png')",
    backgroundColor: "#d6cab1",
    // backgroundBlendMode: "multiply",
    color: "#fff",
    display: "flex",
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
  },
  mobile: {},
  background: {
    // background: "linear-gradient(245deg, #4A4A4A00 0%, #312F2F 100%) 0% 0%",
    mixBlendMode: "multiply",
  },
  wrapper: {
    maxWidth: 1800,
    width: "100%",
  },
});

export const A3P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  // const Layout = presenter.layout;
  // const content = presenter.contentData;
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile,
      }}
    >
      <ContentLayoutCover
        // {...content}
        category="Column"
        readTime="2 min"
        title={
          <Typography variant="h1" style={{ fontFamily: "Montserrat" }}>
            Peertjes
            <BR /> hersenspinsels
          </Typography>
        }
        subtitle={
          <Typography gutterBottom variant="h4">
            We krijgen gezinsuitbreiding en ons <BR />
            rustige en bezadigde leventje wordt <BR />
            abrupt beëindigd.
          </Typography>
        }
        authors={{
          image: "/article/images/peer.png",
          credits: [
            { credit: "Tekst", name: "Peertje" },
            { credit: "Beeld", name: "Cornelis den Otter" }
          ]
        }}
        description={
          <Typography variant="body2">
            De eerste die ons huis betreedt is een bejaarde en demente <BR />
            bull terriër. Hij is blind en doof.
          </Typography>
        }
      />
    </LayoutDefault>
  );
});
