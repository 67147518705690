export const page4 = {
  id: "003ee8bd-a4d8-4ff1-a8a8-21ad55aabdef",
  slug: "page-4",
  title: "Page 4",
  background: "/article/images/blue_gradient.png",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  overlay: "linear-gradient(to right, rgba(18,102,128,1) 0%,rgba(19,115,145,0) 61%,rgba(20,124,156,0) 100%)"
};
