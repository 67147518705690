import { MagazineFragment } from "./fragments";

export const QueryMagazine = `
  query Magazine($id:ID!) {
    Magazine(id:$id){
      ${MagazineFragment}
    }
  }
`;

export const QueryMagazineCollection = `
  query MagazineCollection {
    MagazineCollection{
      ${MagazineFragment}

    }
  }
`;
