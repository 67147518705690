export const page5 = {
  id: "7099d627-411b-4ed6-8b87-c33ba7d04e93",
  slug: "page-5",
  title: "Page 5",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/blurredchairs.png") center`
};
