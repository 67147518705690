import * as React from "react";

import { ArticlePresenter } from "../../article-presenter";
import { observer } from "mobx-react-lite";
import { Theme } from "@material-ui/core";

import { withStyles, WithStyles, StyleRules } from "@material-ui/styles";
import { Error404Content } from "../../error-404";

const styles = (theme: Theme): StyleRules => ({
  root: {},
  subpages: {
    padding: theme.spacing(2, 0, 20, 0)
  },
  cover: {
    padding: theme.spacing(8, 0),
    display: "flex",
    alignItems: "center"
  }
});

interface OwnProps extends WithStyles<"root" | "cover" | "subpages"> {
  presenter: ArticlePresenter;
}

export const ArticleDesktopContent = withStyles(styles)(
  observer(({ classes, presenter }: OwnProps) => {
    const content = presenter.content_by_id(
      (presenter.currentPage && presenter.currentPage.id) || ""
    );

    if (!presenter.currentPage || !content) {
      return <Error404Content />;
    }

    return <content.component presenter={presenter} />;
  })
);
