import * as React from "react";
import { Trickle } from "./trickle";
import { CloseIn } from "./close-in";

export type animationSets = keyof (typeof components);

const components = {
  trickle: <Trickle></Trickle>,
  closeIn: <CloseIn></CloseIn>
};

export const LayoutAnimator = ({
  animation,
  children
}: animationSets & any) => {
  const animationSet = animation ? components[animation] : <React.Fragment />;
  const last = React.Children.count(children);
  return (
    <>
      {React.Children.map(children, (child: any, index) => {
        return React.cloneElement(
          animationSet,
          {
            style: { ...child.props.style },
            animation: animation,
            index: index,
            last: index >= last - 1
          },
          child
        );
      })}
    </>
  );
};
