import * as React from "react";
import { Theme, Typography, Link } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "wrapper" | "background" | "title" | "smallTitle" | "gridItem" | "maxWidth" | "mobile" | "desktop"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#71D1E7",
    display: "flex",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(/article/images/fotografen_gezocht-cover.png)",
    minHeight: "100vh"
  },
  mobile: {
    backgroundImage: "none",
    paddingTop: "0px !important",
    "& $wrapper": {
      paddingBottom: theme.spacing(10)
    }
  },
  desktop: {
    paddingTop: "0px !important",
    backgroundSize: "auto 100vh",
    backgroundPosition: "30vw 0",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${theme.spacing(8)}px + 10vw)`,
      paddingRight: `calc(${theme.spacing(8)}px + 10vw)`,
      backgroundPosition: `50vw 0`
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: `calc(${theme.spacing(8)}px + 15vw)`,
      paddingRight: `calc(${theme.spacing(8)}px + 15vw)`,
      backgroundPosition: "45vw 0"
    }
  },
  background: {
    opacity: 0.5
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {
    maxWidth: 1300,
    width: "100%"
  }
});

export const FotograafGezochtP1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile,
        desktop: classes.desktop
      }}
    >
      <ContentLayoutCover
        category="Vacature"
        title={
          <Typography variant="h1" gutterBottom className={classes.title}>
            Fotografen <BR />
            gezocht!
          </Typography>
        }
        subtitle={
          <Typography variant="h4" paragraph>
            De redactie van Bondig zoekt <BR />
            enthousiaste fotografen.
          </Typography>
        }
        description={
          <Typography>
            Heb jij affiniteit met fotografie en kun je goed met mensen overweg? <BR />
            Lijkt het jou leuk om voor ons magazine op pad te gaan en het <BR />
            beeldmateriaal voor onze artikelen te verzorgen? Dan zou jij <BR />
            zomaar eens de creatieveling kunnen zijn waar wij naar <BR />
            zoeken! Interesse? Stuur een mailtje naar <Link href="mailto:chris@ncf.nl">chris@ncf.nl</Link>.
          </Typography>
        }
      />
    </LayoutDefault>
  );
});
