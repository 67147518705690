import * as React from "react";

import { ArticlePresenter } from "../../article-presenter";
import { observer } from "mobx-react-lite";
import { Theme } from "@material-ui/core";

import { withStyles, WithStyles, StyleRules } from "@material-ui/styles";
import { Error404Content } from "../../error-404";

const styles = (theme: Theme): StyleRules => ({
  root: {
    fontSize: "calc(0.6rem + 1.5vw)",
    "& h1": {
      fontSize: "calc(1rem + 1.5em)",
    },
    "& h1, & h2, & h3, & h4, & h5, & h6": {
      margin: "1em 0 0.5em 0",
    },
    "& p": {
      margin: "0.5em 0 1em 0",
    },
  },
  subpages: {
    padding: theme.spacing(2, 0, 20, 0),
  },
  cover: {
    padding: theme.spacing(2, 0),
    display: "flex",
    alignItems: "flex-end",
    minHeight: `calc(100vh - ${theme.spacing(5)}px)`,
  },
});

interface PrintContentProps extends WithStyles<"root" | "cover" | "subpages"> {
  presenter: ArticlePresenter;
}

export const ArticleMobileContent = withStyles(styles)(
  observer(({ classes, presenter }: PrintContentProps) => {
    const { article } = presenter;

    if (!article || !article.pages.length) {
      return <Error404Content />;
    }

    const firstPage = article.pages[0];
    const subpages = article.pages.slice(1, article.pages.length);
    const firstPageContent = presenter.content_by_id(firstPage.id);

    return (
      <div className={classes.root}>
        <firstPageContent.component
          presenter={presenter}
          classes={{ root: classes.cover }}
        />

        {subpages.length ? (
          <div className={classes.subpages} style={{...article.style}}>
            {subpages.map((page, index): any => {
              const content = presenter.content_by_id(page.id);
              return content ? (
                <content.component presenter={presenter} key={index} />
              ) : (
                <></>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  })
);
