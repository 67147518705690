import * as React from "react";
import { withStyles, Typography, Theme, WithStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import classnames from "classnames";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps
  extends WithStyles<any> {
  image?: any;
  children?: any;
  embed?: string;
  width?: string;
  height?: string;
  className?: any;
  style?: any;
  caption?: string;
  maxWidth?: string;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: "10px auto 20px auto",
    width: "100%",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  blurb: {
    display: "inline-block",
    padding: "25px 0 0 15px",
    borderLeft: "1px solid",
    position: "absolute",
    left: "10%",
    top: "100%",
    transform: `translate(0, -20px)`,
    textTransform: "none",
    fontFamily: "Montserrat",
    textAlign: "left",
    zIndex: 0,
  },
  media: {
    border: "none",
    maxWidth: "100%",
    // width: "100%",
    // height: "250px"
  },
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
    marginBottom: 15,
    height: 0,
    width: "100%",
    zIndex: 1,
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#000",
  },
  inner: {
    position: "relative",
    // width: "fit-content",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "flex"
    }
  }
});

export const Media = withStyles(styles)(
  ({
    children,
    classes,
    embed,
    image,
    width,
    height,
    className,
    style,
    caption,
    maxWidth,
  }: OwnProps) => (
    <RenderWhile desktop mobile>
      <div
        className={classnames(classes.root, className)}
        style={{ ...style, maxWidth: maxWidth ? maxWidth : "100%" }}
      >
        <div className={classes.inner}>
          {image && !embed && (
            <img
              width={width ? width : "unset"}
              height={height ? height : "unset"}
              src={image}
              className={classes.media}
              alt=""
            />
          )}
          {embed && (
            <div className={classes.videoWrapper}>
              <iframe
                title={embed}
                width={width!}
                allowFullScreen={true}
                height={height!}
                src={embed}
                className={classnames(classes.media, classes.video)}
              ></iframe>
            </div>
          )}

          {caption && (
            <Typography variant="caption" className={classes.blurb}>
              {caption}
            </Typography>
          )}
          {children}
        </div>
      </div>
    </RenderWhile>
  )
);
