import * as React from "react";
import { withStyles, Grid, Typography, Link, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { ContactOption } from "./contact-option";
import { Location } from "./location";
import classNames from "classnames";

interface OwnProps
  extends WithStyles<
    | "root"
    | "text"
    | "bold"
    | "normal"
    | "small"
    | "block"
    | "column"
    | "wrapper"
  > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: "auto",
    display: "flex"
  },
  text: {
    lineHeight: "1.1 !important"
  },
  normal: {
    fontWeight: 400
  },
  bold: {
    fontWeight: 600,
    fontFamily: "Montserrat"
  },
  small: {
    // fontSize: "0.7em"
  },
  block: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  column: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 4, 0, 0),
    "&:not(:last-of-type)": {}
  },
  wrapper: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 5)
    }
  }
});

export const About = withStyles(styles)(({ classes }: OwnProps) => (
  <div className={classNames(classes.root, classes.text)}>
    <Grid
      container
      className={classes.wrapper}
      justify="space-between"
      alignContent="stretch"
      spacing={0}
    >
      <Grid item xs={12} style={{ padding: "0 auto !important" }}>
        <Typography
          variant="h4"
          className={classNames(classes.normal, classes.column)}
          style={{ fontWeight: 400, marginBottom: 12 }}
        >
          Over de NCF
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.column}>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>
              Nederlandse Categoriale vakvereniging Financiën
            </small>
          </Typography>
          <Typography
            paragraph
            className={classNames(classes.text, classes.normal)}
          >
            <small className={classes.small}>
              Oorspronkelijk opgericht op 24 november 1889 en aangesloten bij
              Ambtenarencentrum en CESI.
            </small>
          </Typography>
        </div>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>Grondslag</small>
          </Typography>
          <Typography
            paragraph
            className={classNames(classes.text, classes.normal)}
          >
            <small className={classes.small}>
              De bond is onafhankelijk, zonder binding met een bepaalde
              geestelijke stroming of politieke partij, onder volledige
              eerbiediging van de godsdienstige, wereldbeschouwelijke of
              politieke overtuiging van de leden. De bond streeft
              genderneutraliteit na.
            </small>
          </Typography>
          <Location
            role="Secretariaat"
            address="Strevelsweg 700/305"
            zipcode="3083 AS"
            city="Rotterdam"
          />
        </div>

        <div>
          <ContactOption
            icon="T"
            footer={
              <Link color="inherit" href="tel:0104101658">
                010 - 410 16 58
              </Link>
            }
          />

          <ContactOption
            icon="I"
            footer={
              <Link color="inherit" href="https://www.ncf.nl/" target="_blank">
                www.ncf.nl
              </Link>
            }
          />

          <ContactOption
            icon="E"
            footer={
              <Link color="inherit" href="mailto:mijnncf@ncf.nl">
                mijnncf@ncf.nl
              </Link>
            }
          />
        </div>
        <div className={classes.block}>
          {/* <Typography className={classes.text}>
            <small className={classes.small}>
              IBAN NL22 INGB 0000 141632 t.n.v. NCF
            </small>
          </Typography> */}
        </div>
      </Grid>

      <Grid item xs={12} md={4} className={classes.column}>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>
              Voorzitter, collectieve belangenbehartiging, ledencontact en CESI
            </small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>Albert van der Smissen</small>
          </Typography>

          <small className={classes.small}>
            <Link href="tel:0624698866" color="inherit">
              06-24 69 88 66
            </Link>
          </small>
        </div>

        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>Penningmeester</small>
          </Typography>

          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>Thomas Geelhoed</small>
          </Typography>

          <small className={classes.small}>
            <Link href="tel:0624693999" color="inherit">
              06-24 69 39 99
            </Link>
          </small>
        </div>

        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>IBAN</small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>NL22 INGB 0000 141632</small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>t.n.v. NCF</small>
          </Typography>
        </div>

        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>Secretarissen</small>
          </Typography>
          <Typography
            className={classNames(classes.text, classes.normal)}
            gutterBottom
          >
            <small className={classes.small}>Elisabeth Stroet</small>
          </Typography>

          <ContactOption
            icon="T"
            footer={
              <Link color="inherit" href="tel:0624698892">
                06 - 24 69 88 92
              </Link>
            }
          />
          <Typography
            className={classNames(classes.text, classes.normal)}
            gutterBottom
          >
            <small className={classes.small}>Karim Houfaty</small>
          </Typography>
          <ContactOption
            icon="T"
            footer={
              <Link color="inherit" href="tel:0624698892">
                06 - 34 38 52 68
              </Link>
            }
          />
        </div>
      </Grid>

      <Grid item xs={12} md={4} className={classes.column}>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>DGO en redactie</small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>Han Vonk</small>
          </Typography>

          <ContactOption
            icon="T"
            footer={
              <Link href="tel:0624698871" color="inherit">
                06-24 69 88 71
              </Link>
            }
          />
        </div>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>
              Individuele belangenbehartiging, CESI en Douane{" "}
            </small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>Eelke Renkema</small>
          </Typography>

          <ContactOption
            icon="T"
            footer={
              <Link href="tel:0624698580" color="inherit">
                06-24 69 85 80
              </Link>
            }
          />
        </div>
        <div className={classes.block}>
          <Typography className={classNames(classes.text, classes.bold)}>
            <small className={classes.small}>Medezeggenschap en CESI</small>
          </Typography>
          <Typography className={classNames(classes.text, classes.normal)}>
            <small className={classes.small}>Ad van Gaans</small>
          </Typography>

          <ContactOption
            icon="T"
            footer={
              <Link color="inherit" href="tel:0624698893">
                06 - 24 69 88 93
              </Link>
            }
          />
        </div>
      </Grid>
    </Grid>
  </div>
));
