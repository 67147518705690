import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";

export const bondigBestuursverkiezing: ArticleData = {
  id: "ddcf9be5-4165-4608-9401-e5fb0b17706f",
  slug: "bondig-bestuursverkiezing",
  title: "Bestuursverkiezing",
  description: "",
  index: "13",
  image: "/article/images/bestuursverkiezing_thumb.png",
  backgroundImage: "/article/images/bestuursverkiezing_cover.svg",
  content: "",
  pages: [page1, page2, page3],
  type: "article",
  color: "white",
  style: {
    backgroundColor: "#D32949"
  }
};
