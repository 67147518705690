import * as React from "react";
import { Typography, Hidden , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Quote } from "../../../../../interface/partials/quote";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD",

  },
  image: {
    maxWidth: "100%",
    margin: "0 0 0 50px"
  }
});

export const A7P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="closeIn">
        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography paragraph>
            Stel, zo is het commentaar uit de zaal, dat je in de eerste twee
            rondes al gekozen hebt voor een aantal nachtdiensten achter elkaar.
            Je werkgever kan dan in de derde ronde beslissen dat je daarna nog
            een nachtdienst moet draaien indien het rooster nog niet ingevuld
            is. Het aantal nachtdiensten dat je dan moet draaien kan in conflict
            komen met je gezondheid. Dat is het gevolg van het verminderen van
            de beschermingsmaatregelen. Als dit veelvuldig voorkomt kan dit
            een reden zijn om meeroosteren te evalueren en te kijken of dit wel
            de meest geschikte oplossing is voor een organisatie. In die
            situatie is collectief roosteren beter.
          </Typography>
          <Quote variant="h5">
            Er moet duidelijk en transparant gecommuniceerd worden over
            capaciteit binnen een organisatie.
          </Quote>
        </ContentColumn>
        <Hidden mdDown>
          <ContentColumn style={{ maxWidth: 400 }}>
            <img
              className={classes.image}
              src="/article/images/stickies.svg"
              alt=""
            />
          </ContentColumn>
        </Hidden>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
