import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn9 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography paragraph>
                In de praktijk kan het voorkomen dat weer volledig
                arbeidsgeschikte medewerkers niet op de hoogte zijn van de
                1%-regeling en daar bij toeval achterkomen als ze bijvoorbeeld
                in een gesprek met hun leidinggevende aangeven dat zij er weer
                aan toe zijn om volledig te werken en daarmee compensatie-uren
                willen opbouwen.
              </Typography>
              <Typography>
                De leidinggevende zal in dit geval moeten uitleggen dat dit niet
                mogelijk is, omdat de medewerker nog voor 1% is ziekgemeld. ‘Als
                ik dan voor een gesprek met de medewerker en leidinggevende word
                uitgenodigd, wijs ik de leidinggevende op de uitspraak van de
                rechter (zie onder) hierover’, legt Piet uit.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography variant="h5" paragraph className={classes.header}>
                De rechter heeft geoordeeld dat de 1%-regeling NIET wettig is en
                daarom in de praktijk niet uitgevoerd mag worden.
              </Typography>
              <Typography>
                Als een voorheen arbeidsongeschikte medewerker weer voor 100%
                arbeidsgeschikt is, maar na een periode van 28 dagen of meer
                opnieuw arbeidsongeschikt raakt (door dezelfde of eventueel
                andere reden dan de eerste keer) dan moet de werkgever dit
                behandelen als een nieuwe ziekmelding en dan begint het proces
                weer van voren af aan.
              </Typography>
            </ContentColumn>
            <ContentColumn>
              <Typography paragraph>
                Het lijkt erop dat werkgevers de 1%-regeling gebruiken omdat ze
                geen zin hebben om het proces van arbeidsongeschikt zijn opnieuw
                te beginnen. De rechter heeft geoordeeld dat de 1%-regeling NIET
                wettig is en daarom in de praktijk niet uitgevoerd mag worden.
              </Typography>
              <Typography>
                Mocht je als medewerker in aanraking komen met deze 1%-regeling,
                neem dan contact op met een IB’er. Ziek of arbeidsongeschikt
                zijn is voor niemand een pretje. Noch voor de werknemer, in
                eerste instantie, en noch voor de werkgever.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
