import * as React from "react";
import {
  withStyles,
  WithStyles,
  Button,
  Theme,
  Typography
} from "@material-ui/core";
import { ProgressBar } from "../../interface/partials/progress-bar";
import { StyleRules } from "@material-ui/styles";
import { PollPresenter } from "./poll-presenter";
import { withPresenter, PresenterProps } from "../../../helpers/with-presenter";
import { observer } from "mobx-react";
import { MultipleChoice } from "../../interface/partials/multiple-choice";
import { HeartSlider } from "./slider";
import { toJS } from "mobx";

interface OwnProps
  extends WithStyles<
    | "root"
    | "progressBar"
    | "button"
    | "answers"
    | "question"
    | "disclaimer"
    | "modal"
    | "montserrat"
    | "answer"
  > {
  questionIndex?: number;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  progressBar: {
    maxWidth: 400,
    width: "100%",
    margin: "auto"
  },
  button: {
    margin: "auto",
    transition: "color cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    color: "white",
    borderColor: "#fff",
    "&:hover": {
      backgroundColor: "white",
      color: "#4a4a4a"
    }
  },
  question: {
    marginBottom: 40
  },
  answers: {
    marginBottom: 40,
    width: "100%",
  },
  answer: {
    width: "100%",
    color: "white",
    fontSize: "16px",
    backgroundColor: "transparent",
    height: 200,
    padding: 10,
    borderRadius: "15px",
    border: "1px solid",
    "&:focus": {
      outline: "none"
    }
  },
  disclaimer: {
    marginTop: 30
  },
  modal: {
    backgroundColor: "transparent",
    color: "white",
    padding: theme.spacing(2),
    boxShadow: "none"
  },
  montserrat: {
    fontFamily: "Montserrat"
  }
});

const PollComponent = observer(
  ({ classes, presenter }: OwnProps & PresenterProps<PollPresenter>) => {
    if (presenter.loading) {
      return <div style={{width: "100%", textAlign: "center"}}>Loading</div>;
    }

    return (
      (presenter.currentQuestion && (
        <div className={classes.root}>
          <div className={classes.progressBar}>
            <ProgressBar
              subject="Vraag"
              total={presenter.questionCount}
              current={presenter.currentQuestionIndex + 1}
            />
          </div>

          <div className={classes.question}>
            <Typography
              variant="h6"
              className={classes.montserrat}
              align="center"
            >
              {presenter.currentQuestion.question}
            </Typography>
          </div>

          <div className={classes.answers}>
            {presenter.currentQuestion.type === "multiselect" && (
              <MultipleChoice
                multiselect
                options={toJS(presenter.currentQuestion.options) || []}
                answers={presenter.currentValue || []}
                onChange={presenter.updateValue}
              />
            )}

            {presenter.currentQuestion.type === "rating" && (
              <HeartSlider
                maxValue={5}
                onChange={presenter.updateValue}
                currentValue={presenter.currentValue}
              ></HeartSlider>
            )}

            {presenter.currentQuestion.type === "select" && (
              <MultipleChoice
                options={toJS(presenter.currentQuestion.options) || []}
                answers={presenter.currentValue || []}
                onChange={presenter.updateValue}
              />
            )}

            {presenter.currentQuestion.type === "text" && (
              <textarea
                className={classes.answer}
                placeholder="Type hier jouw bericht"
                onChange={e => presenter.updateValue(e.target.value)}
                defaultValue={
                  (presenter.currentValue && presenter.currentValue[0]) || ""
                }
              />
            )}
          </div>

          {presenter.errorMessage && (
            <Typography gutterBottom variant={"body2"}>
              {presenter.errorMessage}
            </Typography>
          )}
          <Button
            onClick={presenter.onSubmit}
            className={classes.button}
            variant="outlined"
          >
            Antwoord verzenden
          </Button>

          <Typography className={classes.disclaimer}>
            <small>Jouw antwoorden worden vertrouwelijk gebruikt.</small>
          </Typography>
        </div>
      )) || (
        <div>
          <Typography gutterBottom variant="h4" align="center">
            Dank je wel!
          </Typography>
          <Typography variant="body1" align="center">
            Wij hebben jouw feedback ontvangen en gaan ermee aan de slag.
          </Typography>
        </div>
      )
    );
  }
);

export const Poll = withStyles(styles)(
  withPresenter<PollPresenter, OwnProps>(
    (props, app) => new PollPresenter(props.questionIndex, app.business.poll),
    PollComponent
  )
);
