import { IPresenter } from "../../../helpers/with-presenter";
import { PollInteractor, PollQuestion } from "../../../application/business/interactor/poll-interactor";
import { computed, observable } from "mobx";

export class PollPresenter implements IPresenter {
  @observable public currentValue: any = null;
  @observable public loading: boolean = false;
  @observable public dialogClosed: boolean = false;
  @observable public errorMessage: string | null = null;

  @computed public get currentQuestion(): PollQuestion | undefined {
    return this._pollInteractor.currentQuestion;
  }

  @computed public get currentQuestionIndex(): number {
    return this._pollInteractor.currentQuestionIndex;
  }

  @computed public get questionCount(): number {
    return this._pollInteractor.questions.length;
  }

  @computed public get isFinalQuestion(): boolean {
    return this.currentQuestionIndex >= this._pollInteractor.questions.length + 1;
  }

  @computed public get isFinished(): boolean {
    return this._pollInteractor.finished;
  }

  constructor(_questionIndex: number | undefined, private _pollInteractor: PollInteractor) {
    if (_questionIndex) {
      this._pollInteractor.currentQuestionIndex = _questionIndex;
    }
  }

  public mount = async () => {
    if (!this._pollInteractor.fetched) {
      this.loading = true;
      await this._pollInteractor.fetch("poll-bondig-51");
      this.loading = false;
    }
    this._pollInteractor.initPoll();
  };

  public unmount = () => {
    //
  };

  public onSubmit = () => {
    this.errorMessage = null;

    if (this.currentValue && this.currentValue.length) {
      this._pollInteractor.submitAnswer(this.currentValue);
      this._pollInteractor.nextQuestion();
      this.currentValue = (this.currentQuestion && this.currentQuestion.answers) || null;
    } else {
      this.errorMessage = "Gelieve eerst een antwoord te geven.";
    }
  };

  public previousQuestion = () => {
    this._pollInteractor.currentQuestionIndex--;
  };

  public updateValue = (value: any) => {
    this.currentValue = value;
  };

  public closeDialog = () => {
    this.dialogClosed = true;
  };

  public answerQuestion() {}
}
