import * as React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { withStyles, StyleRules, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { RenderWhile } from "../../content/components/render-while";

import dataGrijs from "./json/data grijs.json";
import dataWit from "./json/endofarticle.json";
import Lottie from "react-lottie";

interface OwnProps extends WithStyles<"root" | "svg"> {
  dark?: boolean;
}

const styles = (theme: Theme): StyleRules => ({
  "@keyframes blink-animation": {
    to: {
      visibility: "hidden"
    }
  },
  root: {
    animation: "$blink-animation 4s steps(20, start) infinite",
    color: "inherit",
    margin: "0 10px"
  },
  svg: {
    display: "inline-flex",
    marginLeft: 10,
    verticalAlign: "text-top"
  }
});

const EndOfArticleComponent = ({ classes, dark }: OwnProps) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    delay: 500,
    animationData: dark ? dataGrijs : dataWit,
    rendererSettings: {
      preserveAspectRatio: "xMaxYMax"
    }
  };
  return (
    <>
      <RenderWhile desktop>
        {/* <FontAwesomeIcon className={classes.root} icon={faSquare} size="xs" /> */}
        <div className={classes.svg}>
          <Lottie options={defaultOptions} width={60} height={22} />
        </div>
      </RenderWhile>
    </>
  );
};

export const EndOfArticle = withStyles(styles)(EndOfArticleComponent);
