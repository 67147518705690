import * as React from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { IconButton, Typography, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import { mapEvent } from "../../../helpers/formatters";
import {
  faChevronRight,
  faChevronLeft
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

interface OwnProps
  extends WithStyles<
    | "root"
    | "counter"
    | "buttonNext"
    | "buttonPrevious"
    | "buttonDisabled"
    | "button"
  > {
  list: string[];
  current: string;
  onNext(index: number): void;
  onPrevious(index: number): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    position: "absolute",
    left: "50%",
    height: "100%",
    transform: "translateX(-50%)",
    display: "flex",
    alignItems: "center",
    alignContent: "stretch",
    // marginRight: "auto",
    // marginLeft: "auto",
    color: "#4A4A4A"
  },
  counter: {
    padding: theme.spacing(0, 2.5),
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "1.1rem"
  },
  buttonNext: {},
  buttonPrevious: {},
  buttonDisabled: {},
  button: {
    borderRadius: 0,
    height: "100%",
    width: theme.spacing(5),
    color: "#4A4A4A",
    borderRight: "1px solid #E7E7E7",
    borderLeft: "1px solid #E7E7E7",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3.5)
    }
  }
});

const ArticleNavigatorComponent = observer(
  ({ list, classes, current, onNext, onPrevious }: OwnProps) => {
    const prevDisabled = list.indexOf(current) <= 0;
    const nextDisabled = list.indexOf(current) >= list.length - 1;

    return (
      <div className={classes.root}>
        <IconButton
          disabled={prevDisabled}
          className={classnames(classes.buttonPrevious, classes.button)}
          onClick={mapEvent(onPrevious, list.indexOf(current) - 1)}
        >
          <FontAwesomeIcon size="sm" icon={faChevronLeft} />
        </IconButton>

        <Typography className={classes.counter} variant={"body1"}>
          {list.indexOf(current) + 1}/{list.length}
        </Typography>

        <IconButton
          disabled={nextDisabled}
          className={classnames(classes.buttonNext, classes.button)}
          onClick={mapEvent(onNext, list.indexOf(current) + 1)}
        >
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </IconButton>
      </div>
    );
  }
);

export const ArticleNavigator = withStyles(styles)(ArticleNavigatorComponent);
