import * as React from "react";
import { withStyles, Hidden, Theme, Typography } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { RenderWhile } from "./render-while";

const styles = (theme: Theme): StyleRules => ({
  root: {}
});

const LineBreakComponent = ({ classes, gutterBottom }: any) => {
  return (
    <Hidden smDown>
      <RenderWhile mobile desktop>
        <br />
        {gutterBottom && <Typography variant="h5" gutterBottom></Typography>}
      </RenderWhile>
    </Hidden>
  );
};

export const BR = withStyles(styles)(LineBreakComponent);
