import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD",

  },
  image: {
    margin: "-100px"
  }
});

export const A7P5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Collectief roosteren of meeroosteren
          </Typography>
          <Typography paragraph>
            Indien de medezeggenschap samen met de medewerkers kiest voor
            meeroosteren, wordt dit ook geëvalueerd. Wat als meeroosteren geen
            succes is en medewerkers steeds in de derde ronde worden aangewezen
            voor diensten? Marianne legde uit dat in deze kwesties de
            medezeggenschap een belangrijke rol heeft. Zij moet in gesprek gaan
            met de directie om te achterhalen wat de beste oplossing voor de
            organisatie en de medewerkers is. Collectief roosteren of
            meeroosteren? AC Rijksvakbonden kan de medezeggenschap in dit soort
            kwesties wel ondersteunen met verschillende faciliteiten. De sfeer
            in de zaal zat er vanaf het begin van de bijeenkomst al goed in.
            Buiten was het anders. De druilerige regen daalde neer op de
            plastic, witte, ronde tafeltjes waartegen blauwe, plastic stoeltjes
            passief leunden in de tuin naast het restaurant. Op een zonnige dag
            zal dit tuinameublement waarschijnlijk gebruikt worden door
            dagjesmensen die een kop koffie of thee komen drinken met misschien
            nog een versnapering erbij.
          </Typography>
        </ContentColumn>

        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Positie
          </Typography>
          <Typography>
            Het tweede punt dat ter sprake kwam was de positie van de
            medezeggenschap om bij onderbezetting maatregelen te kunnen nemen.
            Er is daarvoor een instrument ontwikkeld waaruit blijkt wat een
            organisatie nodig heeft aan aantal medewerkers en wat de organisatie
            tot haar beschikking heeft: het capaciteitsoverzicht. Er moet
            duidelijk en transparant gecommuniceerd worden over capaciteit
            binnen een organisatie. Er moeten geen trucjes worden uitgehaald om
            de capaciteit op orde te krijgen. Peter Wulms legde uit dat er een
            rijksbrede gestandaardiseerde manier moet komen om de capaciteit in
            een organisatie te bepalen. De zaal merkte op dat het berekenen van
            de capaciteit problemen op kan leveren als er binnen de organisatie
            veel mensen werken die voor een nevenactiviteit een vrijstelling
            voor hun eigenlijke werk hebben. Dit kunnen mensen zijn in de
            medezeggenschap, mensen die trainingen of cursussen geven of andere
            nevenactiviteiten hebben. Peter Wulms legde uit dat in situaties
            waarbij er onduidelijkheden zijn in de berekeningen van de
            capaciteit, je altijd terecht kunt bij een geschillencommissie.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
