import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const bondigArticle1: ArticleData = {
  id: "7ab08800-4b99-4e45-9cc0-e7cde30af5b7",
  slug: "bondig-article-1",
  index: "1",
  title: "Cover",
  description: "Bondig Article 1 description",
  backgroundImage: "/article/images/orange_gradient.png",
  image: "/article/images/cover.png",
  content: "",
  pages: [page1],
  type: "cover"
};
