import * as React from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { BR } from "../../../../../content/components/linebreak";
import { Media } from "../../../../../interface/partials/media";
// import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";

interface OwnProps
  extends PageContentProps,
    WithStyles<
      "root" | "background" | "content" | "montserrat" | "mobile" | "desktop"
    > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundImage: "url('/article/images/e51-cover-bg.png')",
    background:
      "radial-gradient(closest-side at 63% 52%, #FFB361 0%, #F85B00 100%)",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    color: "#fff",
    textAlign: "center",
    height: "calc(100vh - 48px)",
    // alignItems: "flex-start !important"
  },
  desktop: {
    display: "flex",
    justifyContent: "center",
  },
  mobile: {
    minHeight: "100vh",
    paddingTop: theme.spacing(2) + "px !important",
    paddingBottom: theme.spacing(19) + "px !important",
    alignItems: "flex-start !important",
  },
  background: {
    background:
      "transparent linear-gradient(180deg, #00000000 0%, #FFB361 100%) 0% 0% no-repeat padding-box",
    mixBlendMode: "multiply",
    opacity: 0.59,
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  content: {
    maxWidth: 1150,
    paddingBottom: 150,
  },
});

export const Voorwoord = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    // const Layout = presenter.layout;
    // const content = presenter.contentData;
    // console.log(content)

    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          desktop: classes.desktop,
          background: classes.background,
          inner: classes.content,
          mobile: classes.mobile,
        }}
      >
        {/* {<Layout {...content} />} */}
        <ContentLayoutCentered
          size="small"
          animation="trickle"
          title={
            <Typography variant="h2" style={{ fontWeight: 500, marginTop: 0 }}>
              Voorwoord
            </Typography>
          }
          subtitle={
            <Typography gutterBottom variant="body1">
              Albert van der Smissen, voorzitter van de NCF, vertelt over de
              overgang <BR />
              van een magazine op papier naar een interactief magazine.
            </Typography>
          }
          media={
            <Media
              maxWidth="630px"
              embed={"https://www.youtube.com/embed/ZPBqdOb0nEo"}
              caption="Albert van der Smissen, voorzitter van de NCF"
            ></Media>
          }
        />
      </LayoutDefault>
    );
  }
);
