import * as React from "react";
import { Grid, Theme, Typography, Hidden, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import { AnimationWrapper, animationComponents } from "./animation-wrapper";
import { RenderWhile } from "../../content/components/render-while";
import classnames from "classnames";

interface OwnProps extends WithStyles<any> {
  children: any;
  spacing?: number;
  style?: React.CSSProperties;
  className?: any;
  animation?: keyof typeof animationComponents;
  baseTimeout?: number;
  sharedHeader?: boolean;
  wide?: boolean;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
  },

  gridItem: {
    padding: "1vw",
    maxWidth: "480px",
    flexBasis: "24vw",
    
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between"
  },
  wide: {
    maxWidth: "630px",
  },
  spacer: {
    opacity: 0,
    cursor: "default",
  },
});

export const ContentColumn = withStyles(styles)(
  ({
    classes,
    spacing,
    children,
    style,
    className,
    animation,
    baseTimeout,
    sharedHeader,
    wide,
  }: OwnProps) => {
    return (
      <>
        <RenderWhile desktop>
          <Grid
            item
            xs={12}
            md
            className={classnames(
              classes.gridItem,
              wide && classes.wide,
              className
            )}
            style={{ padding: `${spacing}vw`, ...(style || {}) }}
          >
            {sharedHeader && (
              <Hidden smDown>
                <Typography
                  className={classes.spacer}
                  gutterBottom
                  variant="h5"
                >
                  A
                </Typography>
              </Hidden>
            )}
            <AnimationWrapper animation={animation} baseTimeout={baseTimeout}>
              {children}
            </AnimationWrapper>
          </Grid>
        </RenderWhile>
        <RenderWhile mobile>{children}</RenderWhile>
        <RenderWhile print>
          <div style={{ margin: "36px 0" }}>{children}</div>
        </RenderWhile>
      </>
    );
  }
);
