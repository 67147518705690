export const page1 = {
  id: "b982fa30-4ede-481a-a01d-b83fd78e3129",
  slug: "page-1",
  title: "Article 2, Page 1",
  description: "Some page description",
  content: {
    category: "Uit de praktijk",
    readTime: "2,5 min",
    title: {
      variant: "h1",
      content: "Ontslag na \n1 januari 2020"
    },
    authors: {
      image: "/article/images/jaq.png",
      credits: [{ credit: "Tekst", name: "Jacqueline Choufoer-van der Wel" }]
    },
    description: {
      variant: "h4",
      content:
        "Het is je vast niet ontgaan dat vanaf \n1 januari 2020 de Wet \nnormalisering rechtspositie \nambtenaren (Wnra) wordt \ningevoerd."
    },
    size: "big"
  },
  type: "article",
  overlay: "linear-gradient(to right, rgba(18,102,128,1) 0%,rgba(19,115,145,0) 61%,rgba(20,124,156,0) 100%)",
  layout: "ContentLayoutCover"
};
