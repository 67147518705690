import * as React from "react";
import { withStyles, Fade, Typography, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

interface OwnProps
  extends WithStyles<
    | "root"
    | "imageContainer"
    | "image"
    | "textContainer"
    | "arrow"
    | "icon"
    | "montserrat"
  > {
  thumbnail: any;
  children?: any;
  onClick?: any;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
    height: "100px",
    backgroundColor: "#0000",
    border: "none",
    cursor: "pointer",
    position: "relative",
    alignItems: "center",
    "&:hover": {
      "& $arrow": {
        transform: "translate(-50%, 50%) scale(1.2)"
      }
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto"
    }
  },
  imageContainer: {
    borderRadius: "100%",
    height: "100px",
    width: "100px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      height: "70px",
      width: "70px",
      marginRight: theme.spacing(2)
    }
  },
  image: {
    height: "100%",
    width: "auto"
  },
  textContainer: {
    color: "inherit",
    maxWidth: 300,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    padding: 25,
    [theme.breakpoints.down("xs")]: {
      padding: 0
      // display: "none"
    }
  },
  arrow: {
    left: 105,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    border: "15px solid",
    position: "absolute",
    transform: "translate(-50%, 50%)",
    boxShadow: "0 0 20px 5px #0002",
    transition: "transform 0.3s cubic-bezier(.75,-0.5,0,1.75)",
    [theme.breakpoints.down("xs")]: {
      left: 75,
      top: 15,
      height: "36px",
      width: "36px"
    }
  },
  icon: {
    position: "absolute"
  },
  montserrat: {
    fontFamily: "Montserrat"
  }
});

export const ArticleRedirect = withStyles(styles)(
  ({ children, thumbnail, classes, onClick }: OwnProps) => (
    <Fade in unmountOnExit timeout={800}>
      <div className={classes.root} onClick={onClick}>
        <div className={classes.imageContainer}>
          <img src={thumbnail} className={classes.image} alt="" />
        </div>
        <div className={classes.arrow}>
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            size="2x"
            className={classes.icon}
          />
        </div>
        {children && (
          <div className={classes.textContainer}>
            <Typography className={classes.montserrat}>
              Volgend artikel:
            </Typography>
            <Typography
              className={classes.montserrat}
              style={{ lineHeight: 1 }}
            >
              <small>{children}</small>
            </Typography>
          </div>
        )}
      </div>
    </Fade>
  )
);
