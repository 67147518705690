export const page3 = {
  id: "6cf32d80-f662-4d17-a88a-b36026efddba",
  slug: "page-3",
  title: "Page 3",
  description: "Some page description",
  content: "Article 2, page 3",
  type: "article",
  filter: "blur(10px)",
  background: "#D6C9B1",
  color: "#4a4a4a"
};
