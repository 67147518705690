import * as React from "react";

import { StyleRules, WithStyles, withStyles } from "@material-ui/styles";
import { SlideFade } from "../../content/components/slide-fade";
import { Theme } from "@material-ui/core";

interface OwnProps extends WithStyles<"root" | "gridItem"> {
  children: any;
  style?: React.CSSProperties;
  animation?: keyof typeof animationComponents;
  baseTimeout?: number;
}

const styles = (theme: Theme): StyleRules => ({
  root: {},

  gridItem: {
    padding: "1vw",
    maxWidth: 460,
  },
});

export const animationComponents = {
  fadeUp: <SlideFade direction="up"></SlideFade>,
  fadeLeft: <SlideFade direction="left"></SlideFade>,
  fadeRight: <SlideFade direction="right"></SlideFade>,
};

export const AnimationWrapper = withStyles(styles)(
  ({ classes, children, style, animation, baseTimeout }: OwnProps) => {
    const animationSet = animation ? (
      animationComponents[animation]
    ) : (
      <React.Fragment />
    );
    return (
      <>
        {React.Children.map(children, (child: any, index) => {
          if (!child) {
            return <></>;
          }
          return React.cloneElement(
            animationSet,
            {
              style: { ...child.props.style },
              timeout: (baseTimeout || 0) + index * 100,
            },
            child
          );
        })}
      </>
    );
  }
);
