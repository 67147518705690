import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "background" | "title" | "smallTitle" | "gridItem" | "maxWidth"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundImage: "url(/article/images/a4blurredbackground-gradient.png)",
    backgroundColor: "#786679",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  background: {
    // background:
    // "transparent linear-gradient(283deg, #FFFFFF 0%, #FEFDFD 4%, #786679 100%) 0% 0% no-repeat padding-box",
    mixBlendMode: "multiply"
  }
});

export const A4P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ desktop: classes.root, background: classes.background }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography gutterBottom variant="h6">
            Onze NCF-collega Edwin Janssens is in juni gekozen in de
            ondernemings&shy;raad (OR) van Belastingkantoor Eindhoven, segment
            Midden- en Kleinbedrijf (MKB).
          </Typography>
          <Typography>
            Nu de OR-verkiezingen vers achter de rug zijn, staat hij met
            opgestroopte mouwen fier in de startblokken om zijn directe
            collega’s te gaan vertegenwoordigen in de OR. Bondig is benieuwd
            naar Edwins motieven voor zijn belangeloze inzet voor zijn directe
            collega’s en voelt hem daarom uitgebreid aan de tand.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
            gutterBottom
          >
            Heb je al eerder ervaring opgedaan op medezeggen&shy;schapsgebied?
          </Typography>
          <Typography paragraph>
            Edwin: ‘Jazeker, de afgelopen drie jaar heb ik deelgenomen aan de
            onderdeelscommissie MKB (OC MKB). Ik heb daar bijvoorbeeld ervaren
            op welke geraffineerde wijze het medezeggenschapsspel door de
            dienstleiding gespeeld wordt.
          </Typography>
          <Typography>
            Ik zal dat toelichten. Eerst hadden we lokale ondernemingsraden (per
            regio een OR), maar onze hogere legerleiding wilde zaken gaan
            centraliseren.
          </Typography>
        </ContentColumn>

        <ContentColumn>
          <Typography paragraph>
            Door het verschuiven van zeggenschap van lokaal naar centraal niveau
            werd toen ook onze medezeggenschap (MZ) aangepast. We kregen een
            landelijke OC MKB, OC Particulieren (OC P) en nog meer
            onderdeelscommissies. Daarboven opereerde een OR, waarin meerdere
            smaakjes (waaronder MKB en P) bijeen zaten. Het idee was dat dat
            beter zou zijn. Een gevolg was dat feitelijk minder MZ-leden nodig
            waren. Zo’n “MZ-bezuiniging” kwam de dienstleiding natuurlijk om
            voor de hand liggende redenen niet slecht uit.’
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
