import * as React from "react";
import { Typography, Hidden, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { SlideFade } from "../../../../../content/components/slide-fade";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

interface OwnProps
  extends PageContentProps,
    WithStyles<"root" | "image" | "gridItem" | "contentWrapper"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D6C9B1",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    margin: "0 50px",
    height: "100%"
  },
});

export const Rechtzaak3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <ContentLayoutColumns spacing={4} size="big" animation="closeIn">
          <ContentColumn>
            <Typography paragraph>
              De rechter en de griffier zijn dames op leeftijd. Ook de officier
              van justitie is een vrouw. Ik probeer te bedenken of zij ook
              knuffelmomenten kennen en weleens in de keuken staan om die dingen
              te doen die normaal zijn in een huishouden. Maar dat doet er nu
              niet toe. Zij worden betaald om mijn schuldvraag te beoordelen.
              ‘Voor mij reed een vrachtwagen met een open achterklep en waarop
              spullen stonden die er elk moment uit konden vallen’, zo begin ik
              mijn betoog. De officier onderbreekt mij en is hard en
              meedogenloos in haar oordeel. ‘Schuldig!’, roept ze en verwijst
              naar een foto waarop mijn kenteken valt te lezen. De rechter
              twijfelt. ‘Ik halveer de boete’, bepaalt ze uiteindelijk en slaat
              met haar hamertje op de tafel voor haar. Als ik de rechtszaal
              verlaat, steekt de man in het voorbijgaan zijn wijsvinger in de
              lucht en zegt: ‘Dat is maar één schoen.’ Dat treft, want dat
              verkopen ze nergens.
            </Typography>
            <Typography>
              Peertje. <EndOfArticle dark />
            </Typography>
          </ContentColumn>
          <Hidden mdDown>
            <ContentColumn>
              <SlideFade direction="right" timeout={600}>
                <img
                  src={"/article/images/rechtzaak-3.png"}
                  alt=""
                  className={classes.image}
                />
              </SlideFade>
            </ContentColumn>
          </Hidden>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
