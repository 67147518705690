import * as React from "react";
import { Typography } from "@material-ui/core";

import { RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { BasicPagePresenter } from "./_page-basic-presenter";
import { LayoutDefault } from "./layout/layout-default";

const Component = (
  props: RouteComponentProps & PresenterProps<BasicPagePresenter>
) => {
  return (
    <LayoutDefault>
      <Typography gutterBottom variant={"h1"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography gutterBottom variant={"h2"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography gutterBottom variant={"h3"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography gutterBottom variant={"h4"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography gutterBottom variant={"h5"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography gutterBottom variant={"h6"}>
        Nulla ac <br /> molestie lectus et.
      </Typography>

      <Typography paragraph variant={"body1"}>
        Nulla ac tortor turpis. Sed id est sodales, molestie lectus et,
        convallis urna. Donec sed diam tristique, dapibus lorem ut, faucibus
        mauris. Proin ullamcorper justo vel maximus mollis. Mauris et sem
        imperdiet diam consectetur mollis. Nulla nec convallis nunc, vel luctus
        turpis. Mauris mauris libero, placerat non quam sed,
      </Typography>

      <Typography paragraph variant={"body2"}>
        Nulla ac tortor turpis. Sed id est sodales, molestie lectus et,
        convallis urna. Donec sed diam tristique, dapibus lorem ut, faucibus
        mauris. Proin ullamcorper justo vel maximus mollis. Mauris et sem
        imperdiet diam consectetur mollis. Nulla nec convallis nunc, vel luctus
        turpis. Mauris mauris libero, placerat non quam sed,
      </Typography>
    </LayoutDefault>
  );
};

export const AssetsPage = withPresenter<
  BasicPagePresenter,
  RouteComponentProps
>(props => new BasicPagePresenter(props), Component);
