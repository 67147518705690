import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";
// import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "background"
    | "title"
    | "smallTitle"
    | "gridItem"
    | "maxWidth"
    | "desktop"
    | "mobile"
    | "inner"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#786679",
    backgroundImage: "url(/article/images/a4background-gradient.png)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
  },
  desktop: {},
  mobile: {
    backgroundPosition: "top",
  },
  background: {
    // background:
    //   "transparent linear-gradient(283deg, #FFFFFF 0%, #FEFDFD 4%, #786679 100%) 0% 0% no-repeat padding-box",
    mixBlendMode: "multiply",
  },
  inner: {
    width: "100%",
  },
});

export const A4P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  // const Layout = presenter.layout;
  // const content = presenter.contentData;

  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        desktop: classes.desktop,
        mobile: classes.mobile,
        inner: classes.inner,
      }}
    >
      {/* {console.log(content)} */}
      <ContentLayoutCover
        title={
          <Typography variant="h1">
            Edwin Janssens: ‘De OR <BR />
            leeft écht bij mijn collega’s <BR />
            op de werkvloer’
          </Typography>
        }
        layout="centered"
        subtitle=""
        category="Interview"
        readTime="6,5 min"
        authors={{
          image: "/article/images/frank.png",
          credits: [
            { credit: "Tekst", name: "Frank Verweij" },
            { credit: "Beeld", name: "Willem van Duijn" },
          ],
        }}
      />
    </LayoutDefault>
  );
});
