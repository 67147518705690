import * as React from "react";

export const CloseIn = ({ children, index, last }: any) => {
  return (
    <>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          animation: last ? "fadeRight" : "fadeLeft",
          baseTimeout: 400 + index * 100
        });
      })}
    </>
  );
};
