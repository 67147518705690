import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { Quote } from "../../../../../interface/partials/quote";
import { Media } from "../../../../../interface/partials/media";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#F1C9A4",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const China4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ root: classes.root, inner: classes.contentWrapper }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography paragraph>
            De campus was vreselijk en ik begreep niet eens wat voor eten ik
            bestelde, maar aan het einde van de vier weken wilde ik niet meer
            naar huis.’
          </Typography>
          <Typography variant="h5" className={classes.header} gutterBottom>
            Krijg je verbaasde reacties als ze merken dat je als westerling
            Chinees spreekt?
          </Typography>
          <Typography paragraph>
            ‘Eerst denken ze dat ik Amerikaans of Russisch ben. Dan praten ze
            een beetje Engels, maar als ik in het Chinees antwoord dan zie je ze
            verbaasd kijken. Dat komt ook doordat ik in het dialect van Beijing
            spreek.’
          </Typography>
          <Quote dark>De muren hebben oren</Quote>
        </ContentColumn>
        <ContentColumn>
          <Typography variant="h5" className={classes.header} gutterBottom>
            Kom je vaak in vreemde situaties terecht?
          </Typography>
          <Typography gutterBottom>
            ‘Constant. China heeft een Social Credit System. Dan moet je direct
            na aankomst in China op het vliegveld je vingerafdrukken afgeven.
            Overal hangen camera’s, zelfs op de meest afgelegen plekken langs de
            Chinese Muur zie je camera’s in bomen hangen. Overal hebben ze wifi.
            Dan denk ik: hoe dan?’
          </Typography>
          <Media
            image="/article/images/china-4.png"
            caption="gezichtsherkenningstechnologie die door-rood-lopers automatisch een bekeuring geeft en hun gezichten op het scherm weergeeft"
          />
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
