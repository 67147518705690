import { bondigArticle1 } from "../../article/__mock__/bondig-51/bondig-article-1/article";
import { bondigArticle2 } from "../../article/__mock__/bondig-51/bondig-article-2/article";

export const coderehabMagazine: any = {
  id: "587d6d82-58bb-4316-a3f7-99191b4ad92d",
  image: "assets/images/article_cover_coderehab.png",
  logo: "/assets/images/coderehab-logo.svg",
  title: "Code.Rehab",
  description: "Het vakbondsblad van de NCF",
  slug: "coderehab",
  editors: [
    { type: "Chief Editor", users: ["Chris Regtop"] },
    { type: "Editorial Staff", users: ["Frank Verweij", "Peter van Diepen"] },
    { type: "Final Editing", users: ["Daphne Duif"] }
  ],
  contactDetails: {
    email: "redactie@ncf.nl"
  },
  editions: [
    {
      id: "1",
      coverArticle: bondigArticle2,
      image: "/assets/images/article_cover_coderehab.png",
      title: "Inclusiviteit",
      subtitle: "test",
      slug: "inclusiviteit",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle1]
    },
    {
      id: "2",
      coverArticle: bondigArticle2,
      image: "/assets/images/article_cover.png",
      title: "Doen wat werkt",
      subtitle: "test",
      slug: "doen-wat-werkt",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle1, bondigArticle2]
    },
    {
      id: "3",
      coverArticle: bondigArticle1,
      image: "/assets/images/article_cover.png",
      title: "Derde artikel",
      subtitle: "test",
      slug: "derde-artikel",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle1]
    },
    {
      id: "1",
      coverArticle: bondigArticle2,
      image: "/assets/images/article_cover.png",
      title: "Inclusiviteit",
      subtitle: "test",
      slug: "inclusiviteit",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle1, bondigArticle2]
    },
    {
      id: "4",
      coverArticle: bondigArticle2,
      image: "/assets/images/article_cover.png",
      title: "Doen wat werkt",
      subtitle: "test",
      slug: "doen-wat-werkt",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle2]
    },
    {
      id: "5",
      coverArticle: bondigArticle1,
      image: "/assets/images/article_cover.png",
      title: "Vijfde artikel",
      subtitle: "test",
      slug: "vijfde-artikel",
      description:
        "Een 'moeilijk' woord, dat volgens mij wil zeggen dat iedereen erbij hoort. Gewoon zijn is goed genoeg en anders bestaat niet. Ongeveer dit was de boodschap op de goedbezochte ALV van 18 april 2019 en is de boodschap van onze voorpagina. Een volle inclusieve zaal, in een inspirerende omgeving midden in de diverse omgeving van 'Blijdorp'. Wat kan een bond nog meer wensen? Ooh ja... volgend jaar het dubbele aantal van de nu 175 aanwezigen!",
      articles: [bondigArticle1]
    }
  ],

  colofon: []
};
