import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { RenderWhile } from "../../../../../content/components/render-while";
import { LayoutAnimator } from "../../../../layout/layout-animator";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "image"
    | "title"
    | "link"
    | "montserrat"
    | "inner"
    | "mobile"
    | "desktop"
    | "image"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#2E3878",
    display: "flex",
    justifyContent: "center",
    color: "#fff"
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset"
    }
  },
  desktop: {
    "& $inner": {
      marginLeft: "calc(50vw - 40%)"
    }
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  inner: {
    maxWidth: "calc(480px + 5vw)",
    position: "relative"
  },
  image: {
    position: "absolute",
    transform: "translate(-110%, -10%)"
  }
});

export const VerruimingBedrijfsfitnessP4 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile,
          desktop: classes.desktop
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <RenderWhile desktop>
                <img
                  src={"/article/images/fles.png"}
                  className={classes.image}
                  alt={""}
                />
              </RenderWhile>
              <Typography variant="h5" className={classes.montserrat} paragraph>
                IKB en sportabonnement
              </Typography>
              <Typography paragraph>
                Het is een grote frustratie van de NCF (en veel medewerkers) dat
                de Belastingdienst weigert om meer sportscholen op hun lijst te
                zetten. De NCF probeert al lang om deze lijst uit te breiden,
                maar helaas is de Belastingdienst hiertoe niet bereid. Je bent
                als medewerker daardoor erg beperkt in je sportschoolkeuze.
              </Typography>
              <Typography>
                De NCF heeft ook al geprobeerd of we geen rijksbrede lijst
                kunnen maken met sportscholen, maar ook dat heeft niet tot een
                gewenst resultaat geleid. We geven niet op maar we stuiten
                helaas steeds op de halsstarrige houding van de dienstleiding.
                <EndOfArticle />
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
