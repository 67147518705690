import { TemplateFragment } from "./fragments";

export const CreateTemplate = `
mutation CreateTemplate($input:inputTemplate!) {
createTemplate(input:$input){
  ${TemplateFragment}
}
}
`;

export const UpdateTemplate = `
mutation updateTemplate($input:inputTemplate!) {
  updateTemplate(input:$input) {
    ${TemplateFragment}
  }
}
`;

export const DeleteTemplate = `
mutation DeleteTemplate($id:ID!) {
  deleteTemplate(id:$id){
    ${TemplateFragment}
  }
}
`;
