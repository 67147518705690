import { observable, computed } from "mobx";

import { BaseModule } from "../../module";
import { Article, ArticlePage } from "./article";
import DefaultArticleProvider, { ArticleProvider } from "./article-provider";
import { BusinessModule } from "../../business/business";
import { PageTransition, route } from "../../../config/routes";
import Application from "../../application";

export interface ArticleInteractor {
  provider: ArticleProvider;
  articles: Article[];
  transition: PageTransition;
  selectArticle(id: string, redirect?: boolean): Promise<void>;
  selectedArticle: Article | undefined;
  selectedPage: ArticlePage | undefined;
  selectPage(id: string, redirect?: boolean): Promise<void>;
  nextPage(): void;
  previousPage(): void;
  nextArticle(): void;
  previousArticle(): void;
  pageIndex: number;
  articleIndex: number;
  pages: ArticlePage[];
  onArticleChange(func: () => void): void;
}

export default class DefaultArticleInteractor extends BaseModule<any, any, any> implements ArticleInteractor {
  @observable public created: Article | undefined = undefined;
  @observable public selectedArticle: Article | undefined = undefined;
  @observable public selectedPage: ArticlePage | undefined = undefined;
  @observable public previous: Article | undefined = undefined;

  public transition: PageTransition = "cross-fade";
  private _listeners: Array<() => void> = [];

  constructor(private _business: BusinessModule) {
    super();
  }

  @computed public get articles(): Article[] {
    return (this._business.magazine.selectedEdition && this._business.magazine.selectedEdition.articles) || [];
  }

  @computed public get articleIndex(): number {
    return this.articles.findIndex(article => article.id === (this.selectedArticle && this.selectedArticle.id));
  }

  @computed public get pages(): ArticlePage[] {
    return (this.selectedArticle && this.selectedArticle.pages) || [];
  }

  @computed public get pageIndex(): number {
    return this.pages.findIndex(page => page.id === (this.selectedPage && this.selectedPage.id));
  }

  public nextArticle = () => {
    this.transition = "slide-left";
    const index = this.articleIndex + 1;
    if (this.articles[index]) {
      this.selectArticle(this.articles[index].id);
    }
  };

  public previousArticle = () => {
    this.transition = "slide-right";
    const index = this.articleIndex - 1;
    if (this.articles[index]) {
      this.selectArticle(this.articles[index].id);
    }
  };

  public nextPage = () => {
    this.transition = "slide-up";
    const index = this.pageIndex + 1;
    if (this.pages[index]) {
      this.selectPage(this.pages[index].id);
    }
  };

  public previousPage = () => {
    this.transition = "slide-down";
    const index = this.pageIndex - 1;
    if (this.pages[index]) {
      this.selectPage(this.pages[index].id);
    }
  };

  public selectPage = async (id?: string, redirect: boolean = true) => {
    if (id === (this.selectedPage && this.selectedPage.id)) {
      return;
    }

    let result =
      this.selectedArticle &&
      this.selectedArticle.pages &&
      this.selectedArticle.pages.filter(page => {
        return page.id === id;
      });

    this.selectedPage = result && result[0];

    if (redirect && this.selectedPage) {
      Application.router &&
        Application.router.history.push(
          route("article.page", {
            magazine: (this._business.magazine.selected && this._business.magazine.selected.id) || "undefined",
            edition:
              (this._business.magazine.selectedEdition && this._business.magazine.selectedEdition.id) || "undefined",
            article: (this.selectedArticle && this.selectedArticle.id) || "undefined",
            page: (this.selectedPage && this.selectedPage.id) || "undefined"
          })
        );
    }
  };

  public selectArticle = async (id: string, redirect: boolean = true) => {
    if (id === (this.selectedArticle && this.selectedArticle.id)) {
      return;
    }

    const found = this.provider.find(id);

    if (found) {
      this.selectedPage = undefined;
      this.selectedArticle = found;

      if (this.selectedArticle) {
        await this.selectedArticle.fetch();
        this._listeners.forEach(func => func());
        const pages = (this.selectedArticle && this.selectedArticle.pages) || [];
        this.selectPage(pages[0] && pages[0].id, redirect);
      }
    }
  };

  public get provider(): ArticleProvider {
    return this.loadProvider("provider", DefaultArticleProvider);
  }

  public onArticleChange = (func: () => void) => {
    this._listeners.push(func);
  };
}
