import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const bondigWinnaars: ArticleData = {
  id: "d9ccc3b5-d5c8-49ea-ac61-5683dc2c4169",
  slug: "bondig-winnaars",
  title: "Winnaar",
  index: "12",
  description: "",
  image: "/article/images/winnaars_thumb.png",
  backgroundImage: "",
  content: "",
  pages: [page1],
  type: "article",
  color: "#4a4a4a"
};
