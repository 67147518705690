import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";
import { page6 } from "./pages/page-6";
import { page7 } from "./pages/page-7";

export const bondigArticle4: ArticleData = {
  id: "515604de-e4c5-4e9d-98b5-c029d89acace",
  slug: "bondig-article-4",
  title: "Interview - Edwin Janssens",
  description: "",
  index: "3",
  image: "/article/images/Interview - Edwin Janssens.png",
  backgroundImage: "/article/images/a4background.png",
  content: "",
  pages: [page1, page2, page3, page4, page5, page6, page7],
  type: "article",
  style: { backgroundColor: "#786679", color: "white" }
};
