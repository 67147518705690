import * as React from "react";
import { RouteInfo } from "../config/routes";

export const TransitionItem = (route: RouteInfo) => (routerprops: any) => {
  const transition = typeof route.transition === "function" ? route.transition(route, routerprops) : route.transition;

  return (
    <div className={`transition-item ${transition || "fade-in-out"}`}>
      <route.component />
    </div>
  );
};
