import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { ArticleModel, Article, ArticleData } from "./article";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";

import { subscribe } from "../../network/decorators/graphql-subscribe";
import { ArticleCreated, ArticleUpdated, ArticleDeleted } from "./graphql/subscriptions";
import { CreateArticle, UpdateArticle, DeleteArticle } from "./graphql/mutations";
import { QueryArticle } from "./graphql/queries";

export interface ArticleProvider extends GraphQLProviderProps<Article, ArticleData> {
  // fetchAll(): Promise<void>;
}

export interface ArticleValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultArticleProvider extends GraphQLProvider<Article, ArticleData> implements ArticleProvider {
  public model = ArticleModel;

  protected serializers: RecordSerializers<ArticleData> = {
    values: JSONSerializer
  };

  @subscribe(ArticleCreated) articleCreated = (article: Article) => {
    return article;
  };

  @subscribe(ArticleUpdated) articleUpdated = (article: Article) => {
    return article;
  };

  @subscribe(ArticleDeleted) articleDeleted = (article: Article) => {
    return article;
  };

  public createOperation(article: ArticleData) {
    return graphqlOperation(CreateArticle, { input: article });
  }

  public fetchOperation(article: ArticleData) {
    return graphqlOperation(QueryArticle, { id: article.id });
  }

  public updateOperation(article: ArticleData) {
    return graphqlOperation(UpdateArticle, { input: article });
  }

  public deleteOperation(article: ArticleData) {
    return graphqlOperation(DeleteArticle, { id: article.id });
  }
}

// export function articleUsersOptions(article: any) {
//   return {
//     model: UserModel,
//     operation: graphqlOperation(QueryMagazineUsers, { id: article.id })
//   };
// }
