export class BaseModule<Providers, Interactors, Modules> {
  private _cache: any = {
    provider: {},
    interactor: {},
    module: {}
  };

  protected loadProvider<ClassName>(key: keyof Providers, ClassName: new () => ClassName): ClassName {
    if (!this._cache.provider[key]) {
      this._cache.provider[key] = new ClassName();
    }

    return this._cache.provider[key];
  }

  protected loadInteractor<ClassName>(
    key: keyof Interactors,
    ClassName: new (...args: any[]) => ClassName,
    ...args: any[]
  ): ClassName {
    if (!this._cache.interactor[key]) {
      this._cache.interactor[key] = new ClassName(...args);
    }

    return this._cache.interactor[key];
  }

  protected loadModule<ClassName>(key: keyof Modules, ClassName: new () => ClassName): ClassName {
    if (!this._cache.module[key]) {
      this._cache.module[key] = new ClassName();
    }

    return this._cache.module[key];
  }
}
