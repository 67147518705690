import * as React from "react";
import {
  Grid,
  withStyles,
  WithStyles,
  Zoom,
  Theme,
  Typography,
  Drawer,
  Button,
  Hidden,
} from "@material-ui/core";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { PageIndexPresenter } from "./index-presenter";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CoverButton } from "../content/components/cover-button";
import { MagazineIndexInfo } from "../content/index/magazine-index-info";
import {
  Magazine,
  EditionData,
} from "../../application/data/magazine/magazine";
import { EditionIndexInfo } from "../content/index/edition-index-info";
import { DefaultIndexInfo } from "../content/index/default-index-info";
import { EditionBall } from "../interface/partials/edition-ball";
import { StyleRules } from "@material-ui/styles";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Colofon } from "../interface/partials/colofon-slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { About } from "../interface/partials/about-slide";
import classnames from "classnames";

interface OwnProps
  extends RouteComponentProps,
    WithStyles<
      | "root"
      | "header"
      | "content"
      | "grid"
      | "paper"
      | "montserrat"
      | "title"
      | "button"
      | "closeButton"
      | "icon"
      | "aboutButtons"
      | "aboutHeader"
      | "aboutContent"
    > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "5vw",
    paddingBottom: `calc(5vw + ${theme.spacing(5)}px)`,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: "5vw",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  content: {
    maxWidth: 1500,
    width: "100%",
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  paper: {
    width: "65vw",
    color: "white",
    minHeight: "fit-content",
    height: "100vh",
    backgroundColor: "#E9550D",
    "::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1050px",
      padding: theme.spacing(5, 0),
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100vw",
    },
  },
  montserrat: {
    fontFamily: "Montserrat",
    lineHeight: "1.3",
  },
  title: {
    fontWeight: 500,
  },
  aboutButtons: {
    marginTop: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1, 0, 1, 0),
    minWidth: "unset !important",
    fontWeight: 400,
  },
  closeButton: {
    padding: 10,
    borderRadius: "100%",
    border: "2px solid white",
    width: "50px !important",
    height: "50px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "white",
      color: "#E9550D",
    },
  },
  icon: {
    color: "inherit",
    width: "25px !important",
    height: "25px !important",
  },
  aboutHeader: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      top: theme.spacing(6),
      right: theme.spacing(6),
    },
  },
  aboutContent: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3, 6, 6, 6),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(6, 1, 6, 1),
    },
    minHeight: "100%",
  },
});

interface RenderIndexProps extends WithStyles {
  magazine?: Magazine;
  edition?: EditionData;
}

const RenderIndexInfo = withStyles(styles)(
  ({ magazine, edition, classes }: RenderIndexProps) => {
    let result;
    if (magazine) {
      if (edition) {
        result = <EditionIndexInfo edition={edition} />;
      } else {
        result = <MagazineIndexInfo magazine={magazine} />;
      }
    } else {
      result = <DefaultIndexInfo />;
    }

    return <div className={classes.indexInfo}>{result}</div>;
  }
);

const Component = observer(
  ({
    presenter,
    classes,
    match,
  }: OwnProps & PresenterProps<PageIndexPresenter>) => {
    const [state, setState] = React.useState({
      open: false,
      content: <Colofon />,
    });
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.header}>
            {presenter.magazine && (
              <>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={presenter.magazine.logo}
                    alt={presenter.magazine.title}
                  />
                  {presenter.edition && (
                    <div>
                      <EditionBall>nr. {presenter.edition.id}</EditionBall>
                    </div>
                  )}
                </div>
                <Typography
                  align={"center"}
                  variant={"h6"}
                  style={{ marginTop: -10 }}
                >
                  Inhoud
                </Typography>
              </>
            )}
          </div>
          <Grid container className={classes.grid} spacing={3}>
            <Grid item xs={12} md={3} lg={2}>
              <RenderIndexInfo
                magazine={presenter.magazine}
                edition={presenter.edition}
              />
              <Hidden smDown>
                <div className={classes.aboutButtons}>
                  <Button
                    className={classnames(classes.montserrat, classes.button)}
                    onClick={() =>
                      setState({ open: true, content: <Colofon /> })
                    }
                  >
                    <small>Colofon</small>
                  </Button>
                  <hr style={{ margin: 0 }} />
                  <Button
                    className={classnames(classes.montserrat, classes.button)}
                    onClick={() => setState({ open: true, content: <About /> })}
                  >
                    <small>Over de NCF</small>
                  </Button>
                </div>
              </Hidden>
            </Grid>

            <Grid item xs={12} md={8} lg={9}>
              <Grid container spacing={3}>
                {presenter.items.map((record: any, index: number) => (
                  <Zoom
                    key={index}
                    in
                    style={{ transitionDelay: 300 + index * 50 + "ms" }}
                  >
                    <Grid item key={index} xs={6} sm={4} lg={3}>
                      <CoverButton
                        id={record.id}
                        index={record.index || index}
                        title={record.title}
                        image={record.image}
                        onSelect={presenter.selectItem}
                      />
                    </Grid>
                  </Zoom>
                ))}
              </Grid>
              <Drawer
                anchor="left"
                open={state.open}
                onClose={() =>
                  setState({ open: false, content: state.content })
                }
                classes={{ root: classes.root, paper: classes.paper }}
                BackdropProps={{ style: { backgroundColor: "transparent" } }}
              >
                <div style={{ margin: "auto" }}>
                  <div className={classes.aboutHeader}>
                    <div
                      onClick={() =>
                        setState({ open: false, content: state.content })
                      }
                      className={classes.closeButton}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={classes.icon}
                      />
                    </div>
                  </div>
                  <div className={classes.aboutContent}>{state.content}</div>
                </div>
              </Drawer>

              <Hidden mdUp>
                <div className={classes.aboutButtons}>
                  <Button
                    className={classnames(classes.montserrat, classes.button)}
                    onClick={() =>
                      setState({ open: true, content: <Colofon /> })
                    }
                  >
                    <small>Colofon</small>
                  </Button>
                  <hr style={{ margin: 0 }} />
                  <Button
                    className={classnames(classes.montserrat, classes.button)}
                    onClick={() => setState({ open: true, content: <About /> })}
                  >
                    <small>Over de NCF</small>
                  </Button>
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
);

export const PageIndex = withStyles(styles)(
  withRouter(
    withPresenter<PageIndexPresenter, OwnProps>(
      ({ match, history, location, staticContext }, { business }) =>
        new PageIndexPresenter(business.magazine, business.article, {
          match,
          history,
          location,
          staticContext,
        }),
      Component
    )
  )
);
