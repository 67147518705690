import { TemplateFragment } from "./fragments";

export const QueryTemplate = `
  query Template($id:ID!) {
    Template(id:$id){
      ${TemplateFragment}
    }
  }
`;

export const QueryTemplateCollection = `
  query TemplateCollection {
    TemplateCollection{
      ${TemplateFragment}
    }
  }
`;
