import { makeExecutableSchema, mockServer } from "graphql-tools";

import { loader } from "graphql.macro";
import uuid from "uuid";
import { QueryMagazineResolver, QueryMagazineCollectionResolver, magazineArticleResolver } from "./data/magazine";
import { QueryArticleCollectionResolver, QueryArticleResolver } from "./data/article";
import { EditionArticlesResolver } from "./data/edition";

export const schema = makeExecutableSchema({
  typeDefs: loader("./schema.graphql"),
  resolverValidationOptions: {
    requireResolversForResolveType: false
  }
});

export const server = mockServer(schema, {
  ID: () => uuid(),

  TextStyleCSSProperties: () => {
    return {
      fontFamily: "inherit"
    };
  },

  StyledText: () => {
    return {
      variant: "body2",
      element: "div",
      marginBottom: false,
      content: null
    };
  },

  Media: () => {
    return {
      caption: ""
    };
  },

  CSSProperties: () => {
    return {
      background: "inherit",
      backgroundColor: "inherit",
      backgroundImage: "inherit",
      backgroundRepeat: "inherit",
      backgroundSize: "inherit",
      backgroundAttachment: "inherit",
      backgroundPosition: "inherit",
      color: "inherit"
    };
  },

  Content: () => {
    return {};
  },

  Page: () => {
    return {
      background: "",
      type: "article",
      filter: "",
      overlay: "",
      color: null
    };
  },

  Edition: () => {
    return {
      articles: EditionArticlesResolver
    };
  },

  Article: () => {
    return {
      featuredImage: null
    };
  },

  Magazine: () => {
    return {
      articles: magazineArticleResolver
    };
  },

  Query: () => ({
    Magazine: QueryMagazineResolver,
    MagazineCollection: QueryMagazineCollectionResolver,

    Article: QueryArticleResolver,
    ArticleCollection: QueryArticleCollectionResolver
  })
});
