import * as React from "react";
import { Navigator } from "./partials/navigator";
import { withStyles, WithStyles, StyleRules } from "@material-ui/styles";

import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { InterfacePresenter } from "./interface-presenter";
import { observer } from "mobx-react";
import { PageNavigator } from "./partials/button-prev-next-page";
import { PollButton } from "./partials/poll-button";
import { Theme } from "@material-ui/core";
import { Helmet } from "react-helmet";

interface OwnProps extends WithStyles<"root"> {
  //
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    "@media print": {
      display: "none"
    }
  }
});

export const Component = observer(
  ({ classes, presenter }: OwnProps & PresenterProps<InterfacePresenter>) => (
    <div className={classes.root}>
      <PageNavigator presenter={presenter} />
      <Navigator location="bottom" presenter={presenter} />
      <PollButton presenter={presenter} />
      <Helmet>
        <meta name="theme-color" content="#E9550D" />
      </Helmet>
    </div>
  )
);

export const DefaultInterface = withStyles(styles)(
  withPresenter<InterfacePresenter, OwnProps>(
    (_props, { business }) =>
      new InterfacePresenter(
        business.magazine,
        business.article,
        business.print
      ),
    Component
  )
);
