import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#F1C9A4",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const China3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ root: classes.root, inner: classes.contentWrapper }}
    >
      <ContentLayoutColumns size="small" animation="trickle">
        <ContentColumn>
          <Typography variant="h6" paragraph>
            Esther Mol is sinds 2013 werkzaam bij de Belastingdienst. Ze begon
            als informant bij de BelastingTelefoon, maar werkt tegenwoordig als
            (eind)redacteur bij het Forateam. Haar opleiding verraadt een
            allesbehalve fiscale achtergrond.
          </Typography>
          <Typography variant="h5" className={classes.header} gutterBottom>
            In IBM Notes staat onder jouw naam vermeld: ‘Accountancy student
            fase 1’. Wat betekent die term?
          </Typography>
          <Typography paragraph>
            ‘Ik heb van kinds af aan op de een of andere manier een fascinatie
            voor de Chinese cultuur gehad. M’n opa vertelde vroeger spannende
            verhalen over China en bij ons in de straat woonden toen ik klein
            was Chinese mensen die heel vriendelijk waren. Toen ik de kans kreeg
            om Oriëntaalse Talen en Communicatie te studeren, trok dat meteen
            mijn aandacht. Dit is communicatie in Chinees, Engels en
            Nederlands.’
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography variant="h5" className={classes.header} gutterBottom>
          Is Chinees een moeilijke taal om te leren?
          </Typography>
          <Typography paragraph>
            ‘Chinees is heel moeilijk, maar het kent geen vervoegingen. Het is
            veel woordjes leren, veel stampen en zinsconstructies kennen. In het
            Chinees zet je woorden achter elkaar. Het Chinees kent duizenden
            karakters. Een radicaal, een teken in het karakter, geeft aan of het
            woord betrekking heeft op het menselijk lichaam, of geeft het
            geslacht van het onderwerp aan. Dat verduidelijkt veel.’
          </Typography>
          <Typography variant="h5" className={classes.header} gutterBottom>
            Hoe was je eerste bezoek aan China?
          </Typography>
          <Typography>
            ‘Het was een drama. Ik was 19 jaar oud, had één jaar Chinese les
            gehad en ging voor vier weken op zomercursus. Ze hadden
            gewaarschuwd: je hebt blond haar en blauwe ogen, iedereen wil aan je
            zitten en met je op de foto. Ik dacht: ik ben in Egypte geweest, dit
            zal wel meevallen. Maar inderdaad; iedereen wilde met me op de foto.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
