import { BaseModule } from "../module";

import { API, graphqlOperation } from "aws-amplify";
import Application from "../application";
import { server } from "./__mocks__/api";

export interface NetworkProviders {}

export interface NetworkModule extends NetworkProviders {}

export class DefaultNetworkModule extends BaseModule<NetworkProviders, {}, {}> implements NetworkModule {
  public init = async () => {};

  public fetch = async (querystring: string, variables?: any) => {
    let result;

    try {
      if (Application.business.mockMode) {
        result = await server.query(querystring, variables);
      } else {
        result = await API.graphql(graphqlOperation(querystring, variables));
      }
    } catch (err) {
      result = err;
      console.error(err);
    }
    return result;
  };
}
