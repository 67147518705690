import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { LayoutAnimator } from "../../../../layout/layout-animator";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#2E3878",
    display: "flex",
    justifyContent: "center",
    color: "#fff"
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset",
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  inner: {
    maxWidth: "calc(720px + 5vw)"
  }
});

export const VerruimingBedrijfsfitnessP3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography variant="h5" className={classes.montserrat} paragraph>
                Waar blijft het zichtbaar goed werkgeverschap?
              </Typography>
              <Typography paragraph>
                Jammer. De dienst doet een beetje flauw en wil hier niet zonder
                meer aan meewerken. Want al die andere specifieke
                arbeidsvoorwaarden van de Belastingdienst die rijksbreed niet
                gelden, vormen een knelpunt. En daar wil de dienst van af.
                Kortom: de dienstleiding wil de afweging ‘wel/geen verruiming
                van fitnesscentra’ meenemen in harmoniseringsafspraken van
                jullie arbeidsvoorwaarden en rechtspositie. Een ruime lijst van
                bedrijfsfitnesscentra zou dan bijvoorbeeld ingeruild kunnen
                worden voor de niet-werkbare dag na Hemelvaart.
              </Typography>
              <Typography variant="h5" className={classes.montserrat} paragraph>
                Kinderachtig, zo vindt de NCF. Strategisch spel, zo lijkt de
                Belastingdienst het te bekijken
              </Typography>
              <Typography paragraph>
                De NCF vraagt zich af waar de dienst mee bezig is. ‘Medewerker
                centraal’ lijkt hier niet het geval. De voordelen van gezonde
                werknemers worden genegeerd. Of moeten we straks na 1 januari
                2020 na de normalisering van de rechtspositie de dienst maar
                eens wijzen op het Burgerlijk Wetboek-begrip ‘goed
                werkgeverschap’?
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
