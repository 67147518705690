import * as React from "react";
import { withStyles , Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import classnames from "classnames";
import "../../transitions/slide-fade.css";

interface OwnProps
  extends WithStyles<"root" | "animate"> {
  children?: any;
  timeout?: number;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    opacity: 0,
    transform: "scale(0.8, 0.8)",
    transition: "opacity 1.5s ease-out, transform 1.5s ease-out"
  },
  animate: {
    opacity: 1,
    transform: "scale(1, 1)"
  }
});

class ZoomFadeComponent extends React.PureComponent<
  OwnProps,
  { play: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      play: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ play: true });
    }, this.props.timeout || 0);
  }

  public render() {
    const { children, classes } = this.props;
    return (
      <>
        {children &&
          React.Children.map(children, (child: any) => {
            return React.cloneElement(child, {
              className: classnames(
                classes.root,
                this.state.play && classes.animate,
                this.state.play && child.props.className
              )
            });
          })}
      </>
      // <div
      //   className={classnames(
      //     classes.root,
      //     classes[direction],
      //     this.state.play && classes.animate
      //   )}
      // >
      //   {children}
      // </div>
    );
  }
}

export const ZoomFade = withStyles(styles)(ZoomFadeComponent);
