import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { DefaultInterface } from "./view/interface/interface";
import Amplify from "aws-amplify";
import { amplify } from "./config/amplify";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { theme } from "./theme/default";
import Routes from "./view/routes";
import "./theme/style/fonts.css";

Amplify.configure(amplify);

const App: React.FC = () => {
  theme.palette.background.default = "white";
  document.documentElement.lang = "nl";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={{ position: "relative" }}>
        <Router>
          <Routes />
          <DefaultInterface />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
