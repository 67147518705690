import * as React from "react";
import { withStyles, Theme, useTheme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { useMediaQuery } from "react-responsive";
import { withPresenter, PresenterProps } from "../../../helpers/with-presenter";
import { RenderWhilePresenter } from "./render-while-presenter";
import { observer } from "mobx-react-lite";

interface OwnProps extends WithStyles<"root"> {
  children: any;
  mobile?: boolean;
  print?: boolean;
  desktop?: boolean;
}

const styles = (theme: Theme): StyleRules => ({
  root: {},
});

const RenderWhileComponent = observer(
  (props: OwnProps & PresenterProps<RenderWhilePresenter>) => {
    const { children, mobile, print, desktop, presenter } = props;
    const theme = useTheme();

    const isPrint = presenter.isPrint;

    const isMobile = useMediaQuery({
      query: `(max-width: ${theme.breakpoints.values.md - 1}px)`,
    });

    const isDesktop = useMediaQuery({
      query: `(min-width: ${theme.breakpoints.values.md}px)`,
    });

    const isIE11 = useMediaQuery({
      query: `@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)`,
    });

    if (print && isPrint) {
      return <>{children}</>;
    }

    if (mobile && isMobile && !isPrint && !isIE11) {
      return <>{children}</>;
    }

    if (desktop && isDesktop && !isPrint) {
      return <>{children}</>;
    }

    return null;
  }
);

export const RenderWhile = withStyles(styles)(
  withPresenter<RenderWhilePresenter, OwnProps>(
    (props, app) => new RenderWhilePresenter(app.business.print),
    RenderWhileComponent
  )
);
