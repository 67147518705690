import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";
import { data } from "../../network/decorators/graphql-data";
import { Article } from "../article/article";

export interface EditionData {
  id: string;
  slug: string;
  title: string;
  index: string;
  version: string;
  content: string;
  type: string;
  description: any;
  image: string;
  subtitle: string;
  editors: any;
  articles: Article[];
  coverArticle: string;
  highlightedArticles: number[];
}

export interface MagazineData {
  id: string;
  slug: string;
  title: string;
  favicon: string;
  version: string;
  content: string;
  type: string;
  description: any;
  image: string;
  subtitle: string;
  editors: any;
  logo: string;
  editions: EditionData[];
  contactDetails: any;
}

export interface Magazine extends GraphQLModel<MagazineData>, MagazineData {}

export interface MagazineValues {}

export class MagazineModel extends GraphQLBase<MagazineData> implements Magazine {
  typename = "Magazine";

  @data public title = "";
  @data public favicon = "";
  @data public content = "";
  @data public type = "";
  @data public index = "";
  @data public id = "";
  @data public slug = "";
  @data public version = "";
  @data public image = "";
  @data public description = [];
  @data public subtitle = "";
  @data public logo = "";
  @data public editors = {};
  @data public editions = [];
  @data public contactDetails = {};
}
