import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn5 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn wide>
              <Typography paragraph>
                Uit de wet vloeit een aantal tijdsafhankelijke verplichtingen
                waaraan voldaan moet worden om later eventuele loonsancties van
                het UWV (Uitvoeringsinstituut Werknemers-verzekeringen) te
                voorkomen. Zo staat er in de Wet Poortwachter dat er door de
                bedrijfsarts een probleemanalyse van de ziekte gemaakt moet
                worden, een Plan vanAanpak voor de re-integratie en dat een
                evaluatie moet volgen. Ook moet er, bij langdurig ziek zijn, een
                uitkering worden aangevraagd bij het UWV.
              </Typography>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Eerste jaar
              </Typography>
              <Typography>
                Gelukkig zijn veel van de werknemers maar een aantal dagen tot
                hooguit een paar weken ziek. Zij zullen daardoor niets merken in
                hun portemonnee. Dit gebeurt pas bij werknemers die 1 maand of
                langer ziek zijn. Deze mensen krijgen na 1 maand geen vaste
                reiskosten-vergoeding meer.
              </Typography>
            </ContentColumn>

            <ContentColumn wide>
              <Typography paragraph>
                Je loon krijg je dan wel 100% uitbetaald, inclusief een
                gemiddelde onregelmatigheid. Na 6 weken ziekte bouw je geen
                compensatie-uren meer op. Die 100% van je loon behoud je in de
                eerste 52 weken (een jaar) na de eerste ziekmelding. Gelukkig is
                lang niet iedereen zo lang ziek, maar de groep langdurig zieken
                binnen de Belastingdienst is nog altijd aanzienlijk.
              </Typography>
              <Typography>
                Deze mensen hebben het hele jaar te maken met de Wet
                Poortwachter. Belangrijk in deze wet is onder andere het Plan
                van Aanpak. In dit plan staat beschreven welke stappen er
                ondernomen moeten worden om de zieke medewerker te laten
                herstellen en te laten re-integreren. Zo kan in het plan staan
                dat de medewerker niet meteen voor 100% aan de slag gaat, maar
                begint met 50% wat in de loop van de tijd wordt opgevoerd tot
                100%. De aanpak van de re-integratiemanagers is heel
                verschillend.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
