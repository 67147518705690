import { observable, computed } from "mobx";

import { BaseModule } from "../../module";
import { Magazine, MagazineData, EditionData } from "./magazine";
import DefaultMagazineProvider, { MagazineProvider } from "./magazine-provider";
import Application from "../../application";
import { route } from "../../../config/routes";

export interface MagazineInteractor {
  created: Magazine | undefined;
  selected: Magazine | undefined;
  selectedEdition: EditionData | undefined;
  selectedPage: number | undefined;
  select(id: string, redirect?: boolean): Promise<void>;
  selectEdition(id: string, redirect?: boolean): Promise<void>;
  create: (data?: Partial<MagazineData>, persist?: boolean) => Magazine;
  store(magazine: Magazine): Promise<void>;
  find(id: string): Magazine | undefined;
  all: Magazine[];
  provider: MagazineProvider;
}

export default class DefaultMagazineInteractor extends BaseModule<any, any, any> implements MagazineInteractor {
  @observable public created: Magazine | undefined = undefined;
  @observable public selected: Magazine | undefined = undefined;
  @observable public selectedEdition: EditionData | undefined = undefined;
  @observable public selectedPage: number | undefined = undefined;

  public find = this.provider.find;
  public store = this.provider.store;

  constructor() {
    super();
    this.provider.fetchAll();
  }

  public create = (data: Partial<MagazineData> = {}) => {
    const magazine = this.provider.create(data);
    return magazine;
  };

  public select = async (id: string, redirect: boolean = true) => {
    if (this.selected && this.selected.id === id) {
      return;
    }

    const magazine = this.provider.find(id);

    if (magazine) {
      await magazine.fetch();
      this.selected = magazine;

      if (redirect) {
        Application.router &&
          Application.router.history.push(
            route("index.editions", {
              magazine: this.selected.id
            })
          );
      }
    }
  };

  public selectEdition = async (id: string, redirect: boolean = true) => {
    if (this.selectedEdition && this.selectedEdition.id === id) {
      return;
    }

    const edition =
      this.selected &&
      this.selected.editions.filter(edition => {
        return edition.id === id;
      });

    this.selectedEdition = edition && edition[0];

    if (redirect && this.selected && this.selectedEdition) {
      Application.router &&
        Application.router.history.push(
          route("index.articles", {
            magazine: this.selected.id,
            edition: this.selectedEdition.id
          })
        );
    }
  };

  @computed public get all() {
    return this.provider.allRecords.map(magazine => this.provider.find(magazine.id)!);
  }

  public get provider(): MagazineProvider {
    return this.loadProvider("provider", DefaultMagazineProvider);
  }
}
