import { IPresenter } from "../../helpers/with-presenter";
import { PagePresenter } from "./_page-default-presenter";
import { observable } from "mobx";
import { pageContent } from "../../config/page-content";
import { layouts } from "../pages/layout/layouts";

export class ArticlePresenter extends PagePresenter implements IPresenter {
  public pages: Element[] = [];
  public currentPageComponent: Element | undefined = undefined;
  private _contentConfig: any;

  @observable public atBottom: boolean = false;
  @observable public atTop: boolean = true;
  @observable public filter: string = "";

  public get layout() {
    if (this.currentPage && this.currentPage.layout) {
      return layouts[this.currentPage.layout];
    }

    return "div";
  }

  public get contentData() {
    if (this.currentPage && this.currentPage.content) {
      console.log(this.currentPage.content);
      return this.currentPage.content;
    }

    return {};
  }

  public get content() {
    this._contentConfig = pageContent[this.currentPage ? this.currentPage.id : "404"];
    return this._contentConfig;
  }

  public content_by_id(id: string) {
    this._contentConfig = pageContent[id];
    return this._contentConfig && this._contentConfig;
  }

  public mount = async () => {};

  public unmount = () => {
    //
  };

  public selectArticle = (id: string) => {
    this._articleInteractor.selectArticle(id);
  };

  public nextPage = () => {
    this._articleInteractor.nextPage();
  };

  public previousPage = () => {
    this._articleInteractor.previousPage();
  };

  public nextArticle = () => {
    this._articleInteractor.nextArticle();
  };

  public previousArticle = () => {
    this._articleInteractor.previousArticle();
  };

  public selectPage = (id: string) => {
    this.currentPageComponent = this.pages.find(page => page.id === id);
    this.currentPageComponent && this._articleInteractor.selectPage(this.currentPageComponent.id);
    this.filter = this.currentPage ? this.currentPage.filter! : "";
  };

  public setPage() {
    this.currentPageComponent && this.currentPageComponent.scrollIntoView();
  }

  public handleKeyDown = (e: KeyboardEvent) => {
    //
  };
}
