import * as React from "react";
import { Typography, Hidden, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { Media } from "../../../../../interface/partials/media";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps &
  WithStyles<"root" | "gridItem" | "contentWrapper" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#786679",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  gridItem: {
    padding: "1vw"
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500
  },
  image: {
    marginTop: "auto"
  }
});

export const A4P7 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ desktop: classes.root, inner: classes.contentWrapper }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography paragraph>
            Als MZ heb je daar bijna niet mee van doen, maar voor wat betreft
            communicatie heb ik er vanuit onze werkgever weinig van langs zien
            komen. Terwijl het toch rechtspositioneel heel belangrijk is. Als MZ
            zou je de werkgever hierover wel via de juiste kanalen kunnen
            aanspreken.’
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Welke prioriteiten heeft de OR op dit moment?
          </Typography>
          <Typography paragraph>
            ‘Ik denk dat op dit moment de OR, die nu nog in oprichting is en een
            groot aantal nieuwe leden telt, eerst zelf een pad uit moet
            stippelen. Wie wil binnen de OR wat doen (GOR, voorzitter, etc.) en
            hebben we daar de juiste mensen voor? Dat laatste lijkt mij van
            groot belang, ongeacht vanuit welke vakbond je deelneemt in de OR.
          </Typography>
          <Typography>
            De kennis en kunde van een persoon is naar mijn mening van groter
            belang dan haar/zijn vakbondsachtergrond.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Vervolgens zal de ingestelde OR op zoek moeten naar een bepaalde
            manier van samenwerken met onze bestuurder Gerard Blankestijn, die
            ons allen bevalt. Ook moeten we gaan kijken welke werkgroepen er
            nodig zijn om MKB-land goed te laten functioneren. Daarbij is
            natuurlijk de inbreng van de collega’s van belang, waarvan ik zoiets
            heb: laat binnen bepaalde werkgroepen collega’s (niet zijnde de
            gekozen OR-leden) aanschuiven die beschikken over veel kennis en
            kunde over een bepaald onderwerp. Op deze manier kunnen plannen en
            ideeën veel beter uitgewerkt worden met veel inbreng door die
            deskundigen.’
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Tot slot
          </Typography>
          <Typography>
            Bondig wenst ‘onze’ Edwin veel succes met zijn werkzaamheden voor de
            OR.
            <EndOfArticle />
          </Typography>
        </ContentColumn>
        <Hidden mdDown>
          <ContentColumn style={{ maxWidth: 300, display: "flex" }}>
            <Media
              className={classes.image}
              image={"/article/images/eddie2.png"}
              caption="Edwin Janssens"
            ></Media>
          </ContentColumn>
        </Hidden>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
