import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles, StyleRules, CSSProperties } from "@material-ui/styles";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Theme, WithStyles } from "@material-ui/core";

interface OwnProps extends WithStyles<typeof styles> {
  icon?: any;
  header?: any;
  footer?: any;
  style?: CSSProperties;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderBottom: "solid 1px",
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    cursor: "pointer",
  },
  footer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "100%",
    lineHeight: 1,
  },
  header: {
    marginRight: "auto",
    fontWeight: 600,
    lineHeight: 1,
    width: "100%",
  },
  contactIcon: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1.5),
  },
  contactChevron: {
    justifySelf: "flex-end",
    marginLeft: theme.spacing(1.5),
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  }
});

const ContactOptionComponent = ({
  classes,
  icon,
  header,
  style,
  footer,
}: OwnProps) => {
  return (
    <div className={classes.root} style={{ ...style }}>
      {icon && <div className={classes.contactIcon}>{icon}</div>}
      <div className={classes.row}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {header && (
            <div className={classes.row}>
              <small className={classes.header}>{header}</small>
              <FontAwesomeIcon
                icon={faChevronRight}
                size="xs"
                className={classes.contactChevron}
              />
            </div>
          )}
          {footer && <small className={classes.footer}>{footer}</small>}
        </div>
        {!header && (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="xs"
            className={classes.contactChevron}
          />
        )}
      </div>
    </div>
  );
};

export const ContactOption = withStyles(styles)(ContactOptionComponent);
