import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "image"
    | "title"
    | "link"
    | "montserrat"
    | "wrapper"
    | "mobile"
    | "print"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#528CB2",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center"
    }
  },
  print: {},
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  wrapper: {
    maxWidth: "calc(500px + 5vw)"
  }
});

export const A8P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        inner: classes.wrapper,
        mobile: classes.mobile,
        print: classes.print
      }}
    >
      <ContentLayoutCentered
        textAlign="left"
        media={
          <TextBoard
            image="/article/images/vent.png"
            color="#EDD0D2"
            titleColor="#4a4a4a"
            title={
              <>
                <Typography variant="h5">1/6 Wist je dat...?</Typography>
              </>
            }
            subtitle={
              <Typography variant="h5" style={{ fontStyle: "italic" }}>
                ...je op 1 januari 2020 nog een loonsverhoging van 2% krijgt?
              </Typography>
            }
          >
            <Typography>
              Dit volgt nog uit de afgesloten cao 2018-2020. Deze cao eindigt op
              1 juli. Begin 2020 gaat de NCF kijken naar de inzet voor de eerste
              inhoudelijke (privaatrechtelijke) cao.
            </Typography>
          </TextBoard>
        }
        size="small"
      />
    </LayoutDefault>
  );
});
