import * as React from "react";
import { Theme, Typography, Link } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "wrapper" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#528CB2",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  wrapper: {
    maxWidth: "calc(500px + 5vw)"
  }
});

export const A8P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCentered
        textAlign="left"
        media={
          <TextBoard
            image="/article/images/vent.png"
            color="#EDD0D2"
            titleColor="#4a4a4a"
            title={
              <>
                <Typography variant="h5">3/6 Wist je dat...?</Typography>
              </>
            }
            subtitle={
              <Typography variant="h5" style={{ fontStyle: "italic" }}>
                ...er door het A&amp;O fonds Rijk <BR />
                een test is ontwikkeld, genaamd <BR />
                ‘Ben jij toekomst proof’?
              </Typography>
            }
          >
            <Typography>
              Want waar ben jij goed in? Waar krijg je de meeste energie van en
              ervaar je echt werkplezier? Doe <Link target="_blank" href="https://werkenaanjouwtoekomst.nl/toekomstproof-test.html">
                hier
              </Link> de test. Op basis van jouw
              antwoorden kan je zien waar je mogelijk nog ondersteuning nodig
              hebt.
            </Typography>
          </TextBoard>
        }
        size="small"
      />
    </LayoutDefault>
  );
});
