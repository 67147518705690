import { observable, computed } from "mobx";

import { BaseModule } from "../../module";
import { Client, ClientData } from "./client";
import DefaultClientProvider, { ClientProvider } from "./client-provider";

export interface ClientInteractor {
  created: Client | undefined;
  selected: Client | undefined;
  select(id: string): Client | undefined;
  create: (data?: Partial<ClientData>, persist?: boolean) => Client;
  store(template: Client): Promise<void>;
  find(id: string): Client | undefined;
  all: Client[];
  provider: ClientProvider;
}

export default class DefaultClientInteractor extends BaseModule<any, any, any> implements ClientInteractor {
  @observable public created: Client | undefined = undefined;
  @observable public selected: Client | undefined = undefined;

  public find = this.provider.find;
  public store = this.provider.store;

  constructor() {
    super();
    this.provider.fetchAll();
  }

  public create = (data: Partial<ClientData> = {}) => {
    const template = this.provider.create(data);
    return template;
  };

  public select(id: string): Client | undefined {
    const template = (this.selected = this.provider.find(id));
    if (template) {
      template.fetch();
    }
    return template;
  }

  @computed public get all() {
    return this.provider.allRecords.map(template => this.provider.find(template.slug)!);
  }

  public get provider(): ClientProvider {
    return this.loadProvider("provider", DefaultClientProvider);
  }
}
