import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";

export const bondigArticle6: ArticleData = {
  id: "43da8f10-97e7-42b4-a67e-b1c04a263a9f",
  slug: "bondig-article-6",
  title: "Vraag het Marianne Wendt",
  description: "",
  index: "9",
  image: "/article/images/Vraag het Marianne Wendt.png",
  backgroundImage: "/article/images/a5cover.png",
  content: "",
  pages: [page1, page2, page3, page4, page5],
  type: "article",
  style: {
    backgroundColor: "#FFE288"
  }
};
