import * as React from "react";
import { Typography , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { Quote } from "../../../../../interface/partials/quote";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps &
  WithStyles<"root" | "gridItem" | "contentWrapper" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#786679",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  gridItem: {
    padding: "1vw"
  },
  contentWrapper: {
    maxWidth: "80vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none"
    }
  },
  image: {
    width: "100%",
    marginTop: "auto"
  }
});

export const A4P6 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ desktop: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Welke OR-taak ga je prioriteren?
          </Typography>
          <Typography paragraph>
            ‘Een van de hoofdzaken heb ik eerder al genoemd: de communicatie en
            het in goede banen leiden ervan. Maar ik ga ook proberen met mijn
            MKB-collega’s in kantoor Eindhoven (mijn werkplek) meer contact te
            hebben. Overigens, zij kunnen mij natuurlijk ook altijd aan mijn
            jasje trekken of telefonisch benaderen. Een e-mail sturen mag
            natuurlijk ook.
          </Typography>
          <Typography paragraph>
            Meld vooral de zaken waar je tegenaan loopt en wat je tegenkomt in
            je werk of bij je collega’s. Nu weet ik ook wel dat niet alles
            bedoeld is voor de medezeggenschap.
          </Typography>
          <Typography>
            Zaken kunnen ook vakbondsgerelateerd zijn (meestal de individuele
            zaken), maar die sluis ik uiteraard meteen door naar de juiste
            mensen.’
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Ben je tevreden met het opkomstpercentage van OR-stemmers?
          </Typography>
          <Typography paragraph>
            ‘Op een enkel kantoor na waren de opkomstcijfers overal boven de
            50%. Op sommige kantoren zelfs ver erboven. Dat ervaar ik als heel
            positief. Volgens mij duidt zo’n hoge opkomst op het feit dat de MZ
            tóch wel leeft bij de collega’s. Ook hoop ik dat zij doorhebben dat
            we als MZ écht wel wat kunnen betekenen voor hen. We zitten
            natuurlijk ingeklemd tussen twee partijen, maar ik zal het belang
            van de werknemer nimmer uit het oog verliezen.
          </Typography>
          <Typography>
            Uiteraard heeft meegeholpen dat intranet regelmatig aandacht
            besteedde aan de verkiezingen, maar ook de kandidaten én de bonden
            hebben een grote communicatieve duit in het zakje gedaan. Er zijn
            misschien wel wat zaken die beter hadden gekund, maar ik hoop dat
            deze meegenomen worden in een (landelijke) evaluatie, zodat ze zich
            volgende keer niet herhalen.’
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Hoe communiceer je met je collega’s over jouw OR-bijdragen?
          </Typography>
          <Quote variant="h4" gutterBottom>
            Ik laat zien wat ik doe en informeer mijn achterban over wat er
            speelt.
          </Quote>
          <Typography>
            ‘Wat ik hiervoor al aangaf, is heldere communicatie van groot
            belang. Ik laat zien wat ik doe en informeer mijn achterban over wat
            er speelt. Dat werkt natuurlijk nóg beter als zij mij (mogelijk te
            verwachten) zaken aanreiken, waar ze tegenaan lopen en wat ze
            ervaren als probleem. Ook denk ik daarbij aan bijvoorbeeld het
            Individueel Keuzebudget (IKB) dat volgend jaar van start gaat.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
