// import Application from "../application/application";

import { PageIndex } from "../view/pages";
import { RouteComponentProps, generatePath } from "react-router";
import { Error404 } from "../view/pages/error-404";
import { AssetsPage } from "../view/pages/assets";
import { ArticlePage } from "../view/pages/article-page";
import Application from "../application/application";

export type PageTransition =
  | undefined
  | "slide-left"
  | "slide-right"
  | "slide-up"
  | "slide-down"
  | "cross-fade"
  | "fade-in-out"
  | "circle-grow"
  | "slide";
export type PageTransitionInterceptor = (route: RouteInfo, routerprops: RouteComponentProps) => PageTransition;

export interface RouteInfo {
  path: string;
  title: string;
  component: any;
  mainmenu?: boolean;
  disabled?: boolean;
  public?: boolean;
  redirect?: string;
  transition?: PageTransition | PageTransitionInterceptor;
}

export interface PossibleRouterParams {
  magazine?: string;
  edition?: string;
  article?: string;
  page?: string;
}

export const redirections = [
  // {
  //   from: "/",
  //   to: "/51/7ab08800-4b99-4e45-9cc0-e7cde30af5b7/38a27e24-9ebb-4c18-88d7-dee38943a9f4"
  // }
  {
    from: "/53",
    to: "/53"
  }
];

export const routeMap = {
  "theme.assets": {
    path: "/theme",
    title: "Theme Assets",
    component: AssetsPage,
    transition: "fade-in-out"
  },
  "index.editions": {
    path: "/",
    title: "Edities",
    component: PageIndex,
    transition: "fade-in-out"
  },
  "index.articles": {
    path: "/:edition",
    title: "Artikelen",
    component: PageIndex,
    transition: "fade-in-out"
  },
  "article.homepage": {
    path: "/:edition/:article",
    title: "Artikelen",
    component: ArticlePage,
    transition: () => Application.business.article.transition
  },
  "article.page": {
    path: "/:edition/:article/:page",
    title: "Artikelen",
    component: ArticlePage,
    transition: () => Application.business.article.transition
  },

  "error.404": {
    path: "*",
    title: "Error 404 - Resource not found",
    component: Error404,
    transition: "fade-in-out"
  }
};

export type RouteNames = keyof typeof routeMap;

export const route = (name: RouteNames, args?: Record<string, string | boolean | number>) => {
  return generatePath(routeMap[name].path, args);
};

export const routes: RouteInfo[] = Object.keys(routeMap).map((name: string) => {
  return routeMap[name];
});

export const checkRedirect = () =>
  redirections.filter(({ from }) => from.replace(/\/$/, "") === window.location.pathname.replace(/\/$/, ""))[0];
