import * as React from "react";

import { StyleRules, withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { LayoutAnimator, animationSets } from "./layout-animator";
import { AnimationWrapper } from "../../interface/partials/animation-wrapper";
import { Theme, WithStyles, Typography } from "@material-ui/core";
import { RenderWhile } from "../../content/components/render-while";
// import { Media } from "../../interface/partials/media";
// import { FormattedText } from "../../interface/partials/formatted-text";

interface OwnProps extends WithStyles<any> {
  size?: "big" | "small";
  children?: React.ReactNode;
  spacing?: number;
  animation?: animationSets;
  title?: any;
  subtitle?: any;
  media?: any;
  textAlign?: "left" | "center" | "right";
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
  },
  mobile: {},
  desktop: {},
  subtitle: {},
  big: { maxWidth: "calc(1300px + 5vw)" },
  small: { maxWidth: "calc(960px + 5vw)" },
  [theme.breakpoints.down("md")]: {
    small: {
      maxWidth: "none",
    },
  },
  title: {
    margin: "25px 0",
    fontFamily: "Montserrat",
  },
  left: {
    textAlign: "left",
  },
  centered: {
    textAlign: "center",
    margin: "0 auto",
  },
  right: {
    textAlign: "right",
  },
});

export const ContentLayoutCentered = withStyles(styles)(
  ({
    classes,
    size,
    children,
    animation,
    title,
    subtitle,
    media,
    textAlign,
  }: OwnProps) => {
    const alignment = textAlign ? textAlign : "centered";
    return (
      <>
        <RenderWhile desktop mobile>
          <div
            className={classnames(
              classes.root,
              size && classes[size],
              classes[alignment],
              classes.desktop
            )}
          >
            <LayoutAnimator animation={animation}>
              <AnimationWrapper>
                {/* {title && <FormattedText className={classes.title} input={title} />}
                {subtitle && <FormattedText input={subtitle} />}
                {media && <Media {...media} />} */}
                <div className={classes.title}>{title && title}</div>
                {subtitle && subtitle}
                {media && media}
              </AnimationWrapper>
            </LayoutAnimator>
          </div>
        </RenderWhile>

        <RenderWhile print>
          {title && (
            <Typography {...{ ...title.variant, ...title.style }}>
              {title.content}
            </Typography>
          )}
          {subtitle && (
            <Typography {...{ ...subtitle.variant, ...subtitle.style }}>
              {subtitle.content}
            </Typography>
          )}
          <div>{media}</div>
        </RenderWhile>
      </>
    );
  }
);
