import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { Media } from "../../../../../interface/partials/media";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { SlideFade } from "../../../../../content/components/slide-fade";

type OwnProps = PageContentProps &
  WithStyles<"root" | "gridItem" | "contentWrapper" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#786679",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    width: "200px",
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      margin: "0 50px",
      marginTop: "auto"
    }
  }
});

export const A4P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ desktop: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <div className={classes.image}>
          <SlideFade direction="up" timeout={500}>
            <div>
              <Media
                image={"/article/images/eddieeee.png"}
                caption="Edwin Janssens"
              />
            </div>
          </SlideFade>
        </div>

        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Denkt onze werkgever voldoende aan haar werknemers, of kan dat
            beter?
          </Typography>
          <Typography paragraph>
            ‘Werken bij de Belastingdienst zou mooi zijn als het zonder zorgen
            kan. Ik zie dan een werkgever die alles doet met in het achterhoofd
            het belang van de medewerker. Hoe goed zou het voor die medewerker
            zijn als de werkgever iets aan, bijvoorbeeld, bepaalde
            werkomstandigheden wil wijzigen? Puur, alleen met in het achterhoofd
            dat het beter is voor de werknemer?
          </Typography>
          <Typography paragraph>
            Een harmonieus bestaan zou ontluiken binnen de dienst, waarin we
            ongeremd kunnen (samen)werken. Onze “baas” wil alleen maar het beste
            voor mij als werknemer. Dus alles wat de “baas” wijzigt, levert niet
            alleen voor hem iets op. Ook voor mij als medewerker is het
            positief.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography>
            Een mooi voorbeeld: De automatisering draait als een tierelier, de
            werkplek is goed, met goede koffie en een fijn klimaat. De posten
            die we krijgen zijn posten die ertoe doen. Je merkt dat je door die
            posten een daadwerkelijke bijdrage levert aan het bedrijfsproces en
            ‘s Rijksschatkist. En aan het eind van de maand zie je op je
            bankrekening dat je “baas” je echt waardeert. Dát voelt pas echt
            goed! Helaas, op dat punt zijn we nog lang niet beland.
            Medezeggenschap en vakbond zijn dringend nodig om alle ideeën die de
            werkgever heeft, te toetsen op consequenties voor de medewerker.
            Natuurlijk kijk je als MZ’er ook naar het bedrijfsbelang, maar het
            belang van de medewerker heeft bij mij prioriteit.’ 
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
