export const page4 = {
  id: "f9c1d83d-f33b-4610-9465-32b302255956",
  slug: "page-4",
  title: "Page 4",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  background: "#D6C9B1",
  color: "#4a4a4a"
};
