import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "wrapper" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D32949",
    display: "flex",
    justifyContent: "center",
    color: "#fff"
  },
  mobile: {
    "& $wrapper": {
      maxWidth: "unset",
    },
    "& h5": {
      fontSize: "calc(1.4rem + 0.5em)"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {}
});

export const BestuursverkiezingP3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile
        }}
      >
        <ContentLayoutColumns animation="trickle">
          <ContentColumn>
            <Typography
              gutterBottom
              variant="h5"
              className={classes.montserrat}
            >
              Kandidaten
            </Typography>
            <Typography paragraph>
              Het bestuur heeft overigens ook drie prima kandidaten.
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              className={classes.montserrat}
            >
              Thomas Geelhoed
            </Typography>
            <Typography paragraph>
              is als penningmeester aftredend en herbenoembaar. Het bestuur
              ondersteunt unaniem zijn kandidatuur en draagt hem dan ook formeel
              voor.
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              className={classes.montserrat}
            >
              Elisabeth Stroet
            </Typography>
            <Typography paragraph>
              stelt zich verkiesbaar/benoembaar als secretaris. Het bestuur
              ondersteunt unaniem haar nieuwe kandidatuur en draagt haar dan ook
              formeel voor. Zij neemt immers, samen met Karim Houfaty, deze
              functie al waar sinds 1 mei jl. toen Piet van Sintmaartensdijk de
              dienst verliet en terugtrad als secretaris.
            </Typography>
          </ContentColumn>
          <ContentColumn>
            <Typography paragraph>
              Elisabeth loopt bovendien sinds november 2018 stage bij het
              bestuur (zie Bondig 46) en daardoor is zij al aardig ingewerkt.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.montserrat}
            >
              Karim Houfaty
            </Typography>
            <Typography paragraph>
              stelt zich verkiesbaar/benoembaar als algemeen bestuurslid. Het
              bestuur ondersteunt unaniem zijn nieuwe kandidatuur en draagt hem
              dan ook formeel voor. De functie van algemeen bestuurslid komt
              beschikbaar vanaf 1 maart 2020, wanneer Ad van Gaans die nu nog
              deze functie bekleedt, de dienst verlaat.
            </Typography>
            <Typography>
              Karim neemt sinds 1 mei jl. samen met Elisabeth Stroet de functie
              van secretaris waar en loopt sinds november 2018 stage (zie Bondig
              46), waardoor hij ook al aardig ingewerkt is.
            </Typography>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
