import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const bondigArticleFotograafGezocht: ArticleData = {
  id: "686d8f78-cbcf-4d0e-b4d5-b75de55ea76d",
  slug: "fotografen-gezocht",
  title: "Fotografen gezocht",
  description: "",
  index: "8",
  image: "/article/images/fotografen_gezocht-thumb.png",
  backgroundImage: "/article/images/fotografen_gezocht-cover.png",
  content: "",
  pages: [page1],
  type: "article",
  style: {
    backgroundColor: "#71D1E7",
    color: "#4a4a4a"
  }
};
