import * as React from "react";
import { Typography, WithStyles, Theme } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { route } from "../../../config/routes";
import { InterfacePresenter } from "../interface-presenter";
import classnames from "classnames";
import { observer } from "mobx-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface OwnProps extends WithStyles<"root" | "montserrat" | "icon" | "open"> {
  presenter: InterfacePresenter;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    position: "fixed",
    margin: 10,
    right: -200,
    zIndex: 100,
    bottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#F85B00",
    borderRadius: "100%",
    width: 150,
    height: 150,
    maxWidth: "33vw",
    maxHeight: "33vw",
    boxShadow: "0px 3px 30px #00000029",
    transition:
      "right 0.3s ease-in-out, transform 0.3s cubic-bezier(.75,-0.5,0,1.75), box-shadow 0.3s cubic-bezier(.75,-0.5,0,1.75)",
    "&:hover": {
      transform: "translate(-5%, -5%) scale(1.1)",
      boxShadow: "8px 9px 30px #00000029"
    },
    "&:active": {
      outline: "none"
    }
  },
  open: {
    right: 12,
    bottom: theme.spacing(6)
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: 1.2,
    color: "white",
    textDecoration: "none !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "calc(0.8em + 1vw)"
    }
  },
  icon: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRadius: "100%",
    backgroundColor: "white",
    color: "#F85B00",
    boxShadow: "0px 3px 6px #00000029",
    cursor: "pointer",
    transition: "color 0.2s, background-color 0.2s",
    "&:hover": {
      backgroundColor: "red",
      color: "white"
    }
  }
});

export const PollButton = withStyles(styles)(
  observer(({ classes, presenter }: OwnProps) => {
    return (
      <div
        onClick={presenter.hidePoll}
        className={classnames(
          classes.root,
          presenter.showPollButton && classes.open
        )}
      >
        <div className={classes.icon}>
          {/* <FontAwesomeIcon icon={faTimes} size="xs" /> */}
          &#10005;
        </div>
        <Link
          to={route("article.page", {
            magazine: presenter.magazine ? presenter.magazine.id : "undefined",
            edition: presenter.edition ? presenter.edition.id : "undefined",
            article: "poll",
            page: "poll-bondig-51"
          })}
          onClick={presenter.toPoll}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="h6"
            className={classes.montserrat}
            align="center"
          >
            Wat
            <br />
            vind jij?
          </Typography>
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.montserrat}
            style={{ fontWeight: 400 }}
          >
            Laat het ons
            <br />
            weten!
          </Typography>
        </Link>
      </div>
    );
  })
);
