import * as React from "react";
import { withStyles, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import classnames from "classnames";
import "../../transitions/slide-fade.css";

interface OwnProps
  extends WithStyles<"root" | "up" | "down" | "left" | "right" | "animate"> {
  children?: any;
  timeout?: number;
  direction: "up" | "down" | "left" | "right";
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    opacity: 0,
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out"
  },
  up: {
    transform: "translate(0%, -50%)"
  },
  down: {
    transform: "translate(0%, 50%)"
  },
  left: {
    transform: "translate(-50%, 0%)"
  },
  right: {
    transform: "translate(50%, 0%)"
  },
  animate: {
    opacity: 1,
    transform: "translate(0%, 0%)"
  }
});

class SlideFadeComponent extends React.PureComponent<
  OwnProps,
  { play: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      play: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ play: true });
    }, this.props.timeout || 0);
  }

  public render() {
    const { children, classes, direction } = this.props;
    return (
      <>
        {children &&
          React.Children.map(children, (child: any) => {
            return React.cloneElement(child, {
              className: classnames(
                classes.root,
                classes[direction],
                this.state.play && classes.animate,
                this.state.play && child.props.className
              )
            });
          })}
      </>
    );
  }
}

export const SlideFade = withStyles(styles)(SlideFadeComponent);
