import * as React from "react";
import { withStyles, Grid, Typography, Link, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { BR } from "../../content/components/linebreak";

interface OwnProps
  extends WithStyles<"root" | "montserrat" | "small" | "text"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {},
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 600
  },
  small: {
    // fontSize: "0.7em"
  },
  text: {
    lineHeight: "1.1 !important"
  }
});

export const Colofon = withStyles(styles)(({ classes }: OwnProps) => (
  <div className={classes.root}>
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          style={{
            fontWeight: 400,
            lineHeight: "1.1 !important",
            marginBottom: 12
          }}
        >
          Colofon
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Hoofdredactie</small>
        </Typography>
        <Typography paragraph className={classes.text}>
          <small className={classes.small}>Chris Regtop</small>
        </Typography>
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Redactie</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Frank Verweij</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Peter van Diepen</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Gerard Pereboom</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Ad van der Donk</small>
        </Typography>
        <Typography paragraph className={classes.text}>
          <small className={classes.small}>Carien Scholtmeijer</small>
        </Typography>

        <Typography className={classes.montserrat}>
          <small className={classes.small}>Eindredactie</small>
        </Typography>
        <Typography paragraph className={classes.text}>
          <small className={classes.small}>Daphne Duif</small>
        </Typography>
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Fotografie</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Willem van Duijn</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>Paul Heijne</small>
        </Typography>
        <Typography paragraph className={classes.text}>
          <small className={classes.small}>Rob van Polanen</small>
        </Typography>
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Cartoonist</small>
        </Typography>
        <Typography paragraph className={classes.text}>
          <small className={classes.small}>Cees den Otter</small>
        </Typography>
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Concept en ontwerp</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>
            <Link
              href="https://creativebastards.nl"
              color="inherit"
              underline="always"
              target="_blank"
            >
              Creative Bastards
            </Link>
          </small>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Typography className={classes.montserrat}>
          <small className={classes.small}>Technische realisatie</small>
        </Typography>
        <Typography paragraph>
          <small className={classes.small}>
            <Link
              href="https://code.rehab/"
              color="inherit"
              underline="always"
              target="_blank"
              paragraph
            >
              Code.Rehab
            </Link>
          </small>
        </Typography>

        <Typography className={classes.montserrat}>
          <small className={classes.small}>Opmaak</small>
        </Typography>
        <Typography paragraph>
          <small className={classes.small}>Creative Bastards</small>
        </Typography>

        <Typography className={classes.montserrat}>
          <small className={classes.small}>E-mail redactie</small>
        </Typography>
        <Typography className={classes.text}>
          <small className={classes.small}>
            Heb je vragen of <BR />
            opmerkingen over Bondig?
          </small>
        </Typography>
        <Typography paragraph>
          <small className={classes.small}>
            Mail dan naar &nbsp;
            <Link
              href="mailto:redactie@ncf.nl"
              color="inherit"
              underline="always"
              paragraph
            >
              redactie@ncf.nl
            </Link>
          </small>
        </Typography>

        <Typography paragraph className={classes.montserrat}>
          <small className={classes.small}>Verschijnt 6x per jaar</small>
        </Typography>

        <Typography paragraph className={classes.text}>
          <small className={classes.small}>
            Ingenomen standpunten in het blad vertegenwoordigen niet
            noodzakelijkerwijs die van de NCF.
          </small>
        </Typography>

        <Typography className={classes.montserrat}>
          <small className={classes.small}>&copy; NCF 2019</small>
        </Typography>
      </Grid>
    </Grid>
  </div>
));
