export const page1 = {
  id: "3e0e45d6-95c6-433e-9a97-428fd0e015cf",
  slug: "page-1",
  title: "Article 2, Page 1",
  description: "Some page description",
  content: {
    category: "Column",

    readTime: "2 min",

    title: {
      variant: "h1",
      style: { fontFamily: "Montserrat" },
      content: "Peertjes \nhersenspinsels"
    },
    subtitle: {
      variant: "h4",
      content: "We krijgen gezinsuitbreiding en ons \nrustige en bezadigde leventje wordt \nabrupt beëindigd."
    },
    authors: {
      image: "/article/images/peer.png",
      credits: [
        { credit: "Tekst", name: "Peertje" },
        { credit: "Beeld", name: "Cornelis den Otter" }
      ]
    },
    description: {
      content: "De eerste die ons huis betreedt is een bejaarde en demente \nbull terriër. Hij is blind en doof."
    }
  },
  type: "article",
  overlay: "linear-gradient(to right, rgba(51,38,20,0.85) 0%,rgba(201,160,81,0.4) 100%)",
  layout: "ContentLayoutCover"
};
