import * as React from "react";
import { withStyles, Theme,   Typography, WithStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

interface OwnProps extends WithStyles<"root" | "text"> {
  children?: any;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translate(25%, -60%)",
    borderRadius: "100%",
    backgroundColor: "#ffc583",
    whiteSpace: "nowrap",
    color: "#e9550d",
    width: theme.spacing(4),
    height: theme.spacing(4),
    right: 0,
    top: 0
  }
});

const EditionBallComponent = ({ classes, children }: OwnProps) => {
  return (
    <div className={classes.root}>
      <Typography variant='subtitle1' style={{margin: 0}}>{children && children}</Typography>
    </div>
  );
};

export const EditionBall = withStyles(styles)(EditionBallComponent);
