import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<"root" | "wrapper" | "background" | "mobile" | "desktop">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    backgroundImage: "url('/article/images/noa_holleman-cover.png')",
    backgroundPosition: "left center",
    justifyContent: "center",
  },
  mobile: {
    backgroundPosition: "10% center",
    backgroundSize: "cover",
  },
  desktop: {
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingBottom: theme.spacing(5)
  },
  background: {
    // opacity: 0.5,
    // backgroundImage:
    //   "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    marginTop: "5vw",
    maxWidth: 1800,
    width: "100%",
    zIndex: 2,
  },
});

export const NoaHollemanCover = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
          desktop: classes.desktop,
        }}
      >
        <ContentLayoutCover
          category="Uit de praktijk"
          readTime="2,5 min"
          title={
            <Typography variant="h1">
              Het jongste NCF-<BR />lid, Noa Holleman <BR />, stelt zich aan u voor
            </Typography>
          }
          subtitle=""
          authors={{
            image: "/article/images/frank.png",
            credits: [
              { credit: "Tekst", name: "Frank Verweij" },
              { credit: "Beeld", name: "Paul Heijne" },
            ],
          }}
          description={
            <Typography variant="h4">
              De Belastingdienst is al een tijd <BR />
              drastisch aan het verjongen. Dit proces <BR />
              is dagelijks op elk belastingkantoor <BR />
              voor iedere willekeurige collega <BR />
              duidelijk waarneembaar.
            </Typography>
          }
          size="big"
        />
      </LayoutDefault>
    );
  }
);
