import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { RouteComponentProps } from "react-router";
import { withPresenter, PresenterProps } from "../../helpers/with-presenter";
import { BasicPagePresenter } from "./_page-basic-presenter";

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
});

export const Error404Content = withStyles(styles)((props: any) => (
  <main className={props.classes.root}>
    <Typography variant="h1" style={{ color: "#000" }}>
      404
    </Typography>
    <Typography variant="body1">Resource not found</Typography>
  </main>
));

const Component = (
  props: RouteComponentProps & PresenterProps<BasicPagePresenter>
) => {
  return <Error404Content />;
};

export const Error404 = withPresenter<BasicPagePresenter, RouteComponentProps>(
  props => new BasicPagePresenter(props),
  Component
);
