import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn4 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography paragraph>
                De ziekmelding was namelijk onterecht. Ga, in plaats van je ziek
                te melden, in gesprek met je leidinggevende, een
                vertrouwenspersoon, de BMW’er, een collega of een IB’er. Het is
                belangrijk dat je in dit soort conflictsituaties alles volgens
                de regels doet, en daar hoort een ziekmelding niet bij.’
              </Typography>
              <Typography>
                Je wordt alleen ziekgemeld als je echt geen werk meer kunt
                uitoefenen. In een heel aantal gevallen zul je misschien niet je
                eigen werk kunnen doen maar wel ander, vervangend werk,
                eventueel op een andere afdeling. Als je bijvoorbeeld je been
                hebt gebroken met skiën, dan zou je je normale werk bij Fysiek
                Toezicht (tijdelijk) niet meer kunnen doen, maar wel
                administratief werk. De werkgever moet je dan ook wel
                faciliteren voor dit werk door het verstrekken van een computer
                en een aangepaste stoel als dit nodig is. Het kan ook zijn dat
                een werknemer ziek wordt of geblesseerd raakt tijdens de
                uitoefening van zijn/haar werk, bijvoorbeeld tijdens een
                sportbeurt.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography paragraph>
                Alle kosten voor het ziek zijn komen in dit geval op rekening
                van de werkgever. Van belang is om in dit geval een rapport van
                een bijzonder voorval op te (laten) maken. Hiermee wordt
                vastgelegd dat de ziekte een bedrijfsongeval is. De werkgever
                moet dan ook zijn/haar uiterste best doen om de werknemer goed
                te laten herstellen en weer te laten re-integreren.
              </Typography>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Wet poortwachter
              </Typography>
              <Typography>
                Vanaf zes weken na de eerste dag van je ziekmelding is er sprake
                van langdurige ziekte en treedt de Wet Poortwachter in werking.
                De rechten en plichten van zowel de werknemer als de werkgever
                staan hierin. De wet is ingesteld om kansen op re-integratie te
                verbeteren en instroom in de WIA (Wet werk en inkomen naar
                arbeidsvermogen) te voorkomen. De Wet Poortwachter verlangt dat
                de werknemer en de werkgever zich samen inspannen, met een
                arbodienst of bedrijfsarts, om de betreffende werknemer weer zo
                snel mogelijk aan het werk te krijgen.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
