export const page4 = {
  id: "07fe9943-d156-4fdb-bea3-d670fa5c832c",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/a4blurredbackground.png") center`
};
