import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

type OwnProps = PageContentProps & WithStyles<"root" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#161D51",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    marginTop: "-5vw",
    marginBottom: "-10vw",
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "50%",
      margin: "40px auto 0 auto"
    }
  }
});

export const A5P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="small" animation="closeIn">
        <ContentColumn>
          <Typography
            paragraph
            variant="body2"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Wie zijn die NCF’ers die zich vol overgave inzetten voor hun bond en
            de leden? Wat motiveert hen om dat te doen? In dit artikel maak je
            kennis met Sjoerd Draaisma, onlangs verkozen in de OR MKB
            (ondernemingsraad midden- en kleinbedrijf).
          </Typography>
          <Typography>
            Daarvoor zat hij al zes jaar in de OR-Noord en nu is hij al acht
            jaar individueel belangenbehartiger (IB’er) in zijn regio voor de
            NCF. Daarnaast houdt hij zich nu op kantoor Leeuwarden bezig met
            werkzaamheden rondom de G-rekening.
          </Typography>
        </ContentColumn>
        <ContentColumn style={{ width: "50vw", maxWidth: "none" }}>
          <img
            className={classes.image}
            src="/article/images/kletsen.svg"
            alt=""
          />
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
