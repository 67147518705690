import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { Quote } from "../../../../../interface/partials/quote";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<"root" | "content">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#786679",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  content: {
    maxWidth: "calc(1300px + 5vw)"
  }
});

export const A4P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ desktop: classes.root, inner: classes.content }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 1.2
            }}
          >
            Hoe blijf je op de been in een hectische
            medezeggen&shy;schapsomgeving?
          </Typography>
          <Typography paragraph>
            ‘Het is soms een hele kunst in de MZ-wereld om op de been te
            blijven, omdat de veranderingen en ideeën om je heen duizelen. Maar
            het geeft genoegdoening als zaken tot een goed einde komen. Wat ik
            ervan geleerd heb, is hoe zaken af en toe lopen en dat sommige
            onderhandelingen een weg zijn van de lange adem.
          </Typography>
          <Typography>
            Veel gepraat, veel gegevens uitwisselen, vaak achter de zaken aan
            moeten voordat je hebt ontvangen wat je vroeg. Al een aantal jaren
            beweeg ik me nu voort in de MZ-wereld en een verbetering van bovenaf
            naar beneden heb ik, jammer genoeg, nog niet waargenomen. Dat wil
            dan ook zeggen dat de MZ nog steeds hard nodig is en blijft.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Ik probeer mensen overigens altijd in hun waarde te laten en vind
            het zelf ook prettig als omgekeerd hetzelfde gebeurt. Helaas hebben
            sommige leden nogal het idee dat wat zij zeggen de absolute waarheid
            is. Dat zijn wel lastige zaken om mee te dealen af en toe.
          </Typography>
          <Typography paragraph>
            Vaak spelen bij personen of partijen ook bepaalde (verborgen) niet
            ter zake doende belangen. Die moet je leren te onderscheiden van de
            belangen waar het werkelijk om gaat. Anders kun je je werk namelijk
            niet goed doen.’
          </Typography>
          <Quote variant="h4">
            Het is soms een hele kunst in de MZ-wereld om op de been te blijven,
            omdat de veranderingen en ideeën om je heen duizelen.
          </Quote>
        </ContentColumn>
        <ContentColumn>
          <Typography
            variant="h5"
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 1.2
            }}
            gutterBottom
          >
            Waarom heb je je kandidaat gesteld voor de OR?
          </Typography>
          <Typography paragraph>
            ‘Als ik zie wat wij als OC de laatste drie jaren meegemaakt hebben
            en wat we hebben kunnen doen, dan denk ik dat de OR het wel een stuk
            drukker krijgt. Het was net of de OC voorheen door de dienstleiding
            niet altijd als een volwaardige gesprekspartner werd gezien.
          </Typography>
          <Typography>
            Dat was toch immers de OR? Nu is dat, door wijziging van de
            organisatiestructuur, wél een stuk duidelijker geworden. Voor mij
            was dat een reden om me wederom kandidaat te stellen. Er is volgens
            mij voldoende te doen om voor de belangen van de collega’s op te
            blijven komen.’
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
