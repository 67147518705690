import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

type OwnProps = PageContentProps & WithStyles<"root" | "image" | "bold">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD"
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  }
});

export const A7P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="small" animation="closeIn">
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Restaurant
          </Typography>
          <Typography paragraph>
            De bijeenkomsten vonden plaats in Restaurant Oud-Leusden in Leusden,
            vlakbij Amersfoort. Het op een herberg lijkende restaurant ligt aan
            de rand van het dorp en heeft een speciale grote vergaderzaal. In
            deze vergaderzaal, met een mooie houten vloer, stonden ongeveer 25
            stoelen in een kring die allemaal bezet waren door leden van AC
            Rijksvakbonden toen de tweede bijeenkomst om 17.30 uur begon. De
            eerste bijeenkomst was die ochtend van 9.00 tot 11.00 uur en ook
            goed bezocht.
          </Typography>
          <Typography>
            Uit het hele land waren er leden naar Leusden gereisd die zich eerst
            tegoed deden aan belegde broodjes en koffie of thee, het was immers
            etenstijd. De onderhandelaars van AC Rijksvakbonden, Marianne Wendt
            en Peter Wulms, waren speciaal gekomen om de bijeenkomsten voor te
            zitten en de discussies te begeleiden.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Aan een grote muur was een projectiescherm bevestigd waarop
            informatie met de zaal gedeeld kon worden.
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Nieuwsbrief
          </Typography>
          <Typography paragraph>
            In augustus had AC Rijksvakbonden een leden-nieuwsbrief uitgegeven.
            In deze nieuwsbrief stond een heel aantal thema’s die tijdens de
            onderhandeling ter tafel kwamen en waarop ook resultaten zijn
            geboekt.
          </Typography>
          <img
            src="/article/images/laptop.svg"
            className={classes.image}
            alt=""
          />
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
