import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";

export const transitievergoeding: ArticleData = {
  id: "60ebba26-ec9f-4760-bc25-a0488d251b5d",
  slug: "transitievergoeding",
  index: "6",
  title: "Transitievergoeding",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  style: {
    backgroundColor: "#73788C"
  },
  image: "/article/images/transitievergoeding-thumb.png",
  content: "",
  pages: [page1, page2, page3, page4],
  type: "article"
};
