import * as React from "react";
import { withStyles, StyleRules } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex"
  },
  contactLink: {
    marginRight: "auto",
    marginLeft: theme.spacing(2.4),
    display: "flex",
    flexDirection: "column"
  },
  faIcon: {
    fontSize: theme.spacing(1.5),
    width: theme.spacing(2)
  }
});

const LocationComponent = ({ classes, role, address, zipcode, city }: any) => {
  return (
    <div className={classes.root}>
      <div>
        <FontAwesomeIcon
          className={classes.faIcon}
          icon={faMapMarkerAlt}
          style={{ margin: "5px 0 0 0" }}
        />
      </div>

      <div className={classes.contactLink}>
        <small>{role}</small>
        <small>{address},</small>
        <small>
          {zipcode} {city}
        </small>
      </div>
    </div>
  );
};

export const Location = withStyles(styles)(LocationComponent);
