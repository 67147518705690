import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";

export const bondigArticle3: ArticleData = {
  id: "502d03a5-6da1-43bd-a913-fb6247be1418",
  slug: "bondig-article-3",
  title: "Peertjes hersenspinsels",
  description: "",
  index: "4",
  image: "/article/images/peertje-hersenspinsels_thumb.png",
  backgroundImage: "/article/images/a3cover.png",
  content: "",
  pages: [page1, page2, page3, page4],
  type: "article",
  style: { backgroundColor: "#d6cab1" }
};
