import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";
import { Quote } from "../../../../../interface/partials/quote";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Ziekmelding
              </Typography>
              <Typography>
                Ziek zijn begint bij een ziekmelding van de werknemer aan
                zijn/haar leidinggevende. Er volgt dan snel een verzuimgesprek
                waarin wordt geprobeerd om te achterhalen wat de oorzaken zijn
                van het verzuim. De leidinggevende kan de melding accepteren en
                dan wordt de werknemer ziekgemeld. Als hij/zij de melding niet
                vertrouwt kan de werkgever de werknemer naar de bedrijfsarts
                (BA) sturen die hem/haar dan alsnog ziek kan verklaren, of niet.
                In de situatie dat je leidinggevende je melding niet vertrouwt
                en je naar de bedrijfsarts stuurt, kan er een conflict ontstaan,
                het is immers een vertrouwenskwestie.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography paragraph>
                Op een ziekmelding reageren leidinggevenden heel verschillend.
                De meesten tonen begrip en melden de medewerker gewoon ziek.
                ‘Maar we zijn in de praktijk ook gevallen tegengekomen waarin de
                leidinggevende eiste dat de ziekgemelde binnen afzienbare tijd
                op kantoor zou komen voor een gesprek’, begint Piet te
                vertellen.
              </Typography>
              <Typography>
                ‘De menselijke maat omtrent ziek zijn is soms zoek. Overigens
                hoef je bij je ziekmelding niet te vertellen wat je mankeert,
                ook niet als je leidinggevende daar om vraagt. Bij de BA moet
                dit wel, maar deze heeft een geheimhoudingsplicht.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Quote gutterBottom dark>
                Je wordt alleen ziekgemeld als je echt geen werk meer kunt
                uitoefenen.
              </Quote>
              <Typography>
                De BA kan vervolgens alleen aan de leidinggevende doorgeven of
                een ziekmelding al dan niet terecht is.’ Het komt in de praktijk
                ook voor dat een ziekmelding voortkomt uit een conflict van een
                werknemer met zijn/haar leidinggevende. ‘Wij raden het aan dit
                nooit te doen’, vervolgt Piet. ‘Het lost het conflict niet op en
                als werknemer kan je dan in de problemen komen.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
