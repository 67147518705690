import * as React from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { Typography, Theme, Hidden } from "@material-ui/core";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Media } from "../../../../../interface/partials/media";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";

interface OwnProps
  extends PageContentProps,
    WithStyles<
      "root" | "background" | "content" | "montserrat" | "image" | "mobile"
    > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#4a4a4a",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    background: "#FFE882",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    alignItems: "flex-start !important"
  },
  mobile: {
    paddingBottom: theme.spacing(15) + "px !important"
  },
  background: {},
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  content: {
    maxWidth: 1150
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(79%, -12%)"
  }
});

export const bondigWinnaars = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.content,
          mobile: classes.mobile
        }}
      >
        <ContentLayoutCentered
          size="small"
          animation="trickle"
          title={
            <Typography variant="h2" style={{ fontWeight: 500, marginTop: 0 }}>
              …en de winnaar, Chris?
            </Typography>
          }
          subtitle={
            <Typography gutterBottom variant="body1">
              In Bondig 50 hebben we een jubileumwoordzoeker geplaatst. Uit de
              inzendingen met de juiste oplossing hebben we vijf winnaars
              getrokken die een mooi koffertje winnen. Benieuwd naar de
              oplossing van de woordzoeker en de winnaars? Bekijk het filmpje!
            </Typography>
          }
          media={
            <Media
              embed={"https://www.youtube.com/embed/VcyJG3cZ5ak"}
              style={{ position: "relative" }}
              caption="Chris Regtop, hoofdredacteur Bondig"
              maxWidth="630px"
            >
              <Hidden mdDown>
                <div style={{ width: 0 }}>
                  <img
                    className={classes.image}
                    src="/article/images/koffer.png"
                    alt=""
                    style={{ zIndex: -1 }}
                  />
                  <img
                    className={classes.image}
                    src="/article/images/confetti.svg"
                    alt=""
                  />
                </div>
              </Hidden>
            </Media>
          }
        />
      </LayoutDefault>
    );
  }
);
