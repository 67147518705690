import * as React from "react";
import { Typography, Theme, WithStyles, Link } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography paragraph variant="h6">
                Vrijwel iedereen krijgt vroeg of laat te maken met ziek zijn.
                Dit kan iets vrij onschuldigs zijn, zoals een griepje, maar het
                kan ook veel ernstiger vormen aannemen zoals een hernia,
                burn-out of een ernstige ziekte.
              </Typography>
              <Typography>
                In deze gevallen hebben zowel de werkgever als de werknemer te
                maken met wet- en regelgeving, rechten en plichten. Wat houden
                de juridische aspecten in, hoe wordt daarmee omgegaan in de
                praktijk en hoe zit het met de menselijke kant van ziek zijn? Ik
                spreek hierover met Piet van Andel, Individueel
                Belangenbehartiger (IB’er) van de NCF.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography variant="h5" gutterBottom className={classes.header}>
                Vervelende situatie
              </Typography>
              <Typography paragraph>
                Ziek zijn is voor zowel de werknemer als de werkgever een
                vervelende situatie. Voor de werknemer kan dit betekenen dat
                deze ziek thuis zit, voor een aantal dagen of voor een langere
                periode, misschien wel een operatie moet ondergaan of therapie
                moet volgen. Bovendien kan het zijn dat de ziekte zware gevolgen
                heeft voor de rest van zijn/haar leven. Daar kunnen veel emoties
                een rol bij gaan spelen. Voor de werkgever betekent dit dat deze
                een medewerker op de vloer (tijdelijk) moet missen en dat
                zijn/haar ziekteverzuimcijfer beïnvloed wordt. Hier spelen
                vooral zakelijke aspecten een rol.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography>
                Aan ziek zijn zitten zowel juridische/zakelijke als menselijke
                aspecten. Er zijn veel regels, wetten, rechten en plichten voor
                zowel de werkgever als de werknemer vastgelegd in de brochure
                ‘Informatie over de rechten en plichten van de werkgevers en
                werknemers Rijk bij ziekte, arbeidsongeschiktheid en
                re-integratie’. Voor de menselijke kant zijn er ook een heel
                aantal zaken geregeld zoals een vertrouwenspersoon,
                bedrijfsmaatschappelijk werker (BMW’er) en Individuele
                Belangenbehartigers (IB’ers) bij de vakbonden. Op intranet,
                Rijksportaal BD - Personeel, staat onder het kopje ‘Werk en
                gezondheid’ meer over ziek zijn, zoals{" "}
                <Link underline="always" href="http://portal.rp.rijksweb.nl/irj/portal/?NavigationTarget=HLPFS://cisrijksportaal/cispersoneel/ciswerk_en_gezondheid/cisziek_en_beter_melden_1/cisziek_en_beter_melden_2&NavigationContext=HLPFS://cisrijksportaal/cispersoneel/ciswerk_en_gezondheid/cisziek_en_beter_melden_1">
                  Ziek en beter melden
                </Link>
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
