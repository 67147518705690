import { computed, observable } from "mobx";

export type RecordMapFunction<TData, T> = (data?: TData) => T;
export type RecordSerializeFunction<T, TData> = (instance: T) => TData;
export type RecordDefaultsFunction<T> = (overrides: Partial<T>) => T;

export interface Store<TData> {
  allRecords: TData[];
  deleteRecord: (id: string) => TData | undefined;
  getRecord: (id: string) => TData | undefined;
  setRecord: (id: string, data: TData) => TData;
  updateRecord: (id: string, data: Partial<TData>) => TData;
  emptyStorage(): void;
}

export type RecordSerializers<T> = Record<string, Serializer<T>>;

export interface Serializer<T> {
  serialize: (data: T) => string;
  deserialize: (str: string) => T;
}

export const JSONSerializer: Serializer<any> = {
  serialize: (data: any) => JSON.stringify(data),
  deserialize: (data: string) => (typeof data === "string" ? JSON.parse(data || "{}") : data)
};

export interface StorageRecord {
  id: string;
}

export class DefaultStore<TData extends StorageRecord> implements Store<TData> {
  @observable
  private _records: { [path: string]: TData } = {};

  @computed get allRecords() {
    return Object.keys(this._records).map(key => this._records[key]);
  }

  public emptyStorage = () => {
    this._records = {};
  };

  //=======================================
  // Core functions
  //=======================================

  public getRecord(id: string) {
    return this._records[id];
  }

  public setRecord(id: string, data: TData) {
    this._records[id] = data;
    return this._records[id];
  }

  public updateRecord = (id: string, record: Partial<TData>) => {
    return this.setRecord(id, { ...this.getRecord(id), ...record });
  };

  public deleteRecord = (id: string) => {
    const item = this.getRecord(id);
    if (item) {
      delete this._records[id];
    }
    return item;
  };

  // protected _serializeRecord: RecordSerializeFunction<T, TData> = instance => {
  //   const data: any = toJS(instance.data);

  //   console.log(data);
  //   Object.keys(this.serializers).forEach(key => {
  //     if (data[key]) {
  //       data[key] = this.serializers[key].serialize(data[key]);
  //     }
  //   });
  //   return { ...data, id: instance.id };
  // };

  //=======================================
  // Function mapping
  //=======================================
}
