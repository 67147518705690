import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { Quote } from "../../../../../interface/partials/quote";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#73788C",
    display: "flex",
    justifyContent: "center",
    color: "#fff"
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const Transitievergoeding2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.contentWrapper }}
      >
        <ContentLayoutColumns size="small" animation="trickle">
          <ContentColumn>
            <Typography paragraph style={{ fontFamily: "Montserrat" }}>
              Regelmatig krijg ik van NCF-leden de vraag of ze misschien recht
              hebben op een transitievergoeding als zij de dienst gaan verlaten.
              Nu is het antwoord nog nee, maar vanaf 1 januari 2020 kan dat
              anders zijn.
            </Typography>
            <Quote>
              Jij hebt ook recht op een transitievergoeding als je zelf ontslag
              neemt en dat doet omdat jouw werkgever ernstig verwijtbaar
              handelde of ernstig verwijtbaar nalatig was. Daarvan is niet zo
              snel sprake.
            </Quote>
          </ContentColumn>
          <ContentColumn>
            <Typography gutterBottom variant="h5" className={classes.header}>
              Wanneer heb je recht op een transitievergoeding?
            </Typography>
            <Typography paragraph>
              In de basis heb je bij ontslag op initiatief van de werkgever
              recht op een transitievergoeding. Dus ook als je tijdelijke
              arbeidsovereenkomst niet wordt verlengd. Vanaf 1 januari 2020
              maakt het daarbij niet meer uit hoe lang je in dienst bent
              geweest. Ook als je arbeidsongeschikt de dienst verlaat heb je
              recht op een transitievergoeding.
            </Typography>
            <Typography>
              Jij hebt ook recht op een transitievergoeding als je zelf ontslag
              neemt en dat doet omdat jouw werkgever ernstig verwijtbaar
              handelde of ernstig verwijtbaar nalatig was. Daarvan is niet zo
              snel sprake.
            </Typography>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
