import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";

export const bondigArticle2: ArticleData = {
  id: "d2e38643-765a-4b6d-bcbd-ce42a0c8216b",
  slug: "bondig-article-2",
  index: "7",
  title: "Ontslag na 1 januari",
  description: "Bondig Article 2 description",
  image: "/article/images/ontslag-na-januari_thumb.png",
  backgroundImage: "/article/images/finger.jpg",
  content: "",
  pages: [page1, page2, page3, page4],
  type: "article",
  style: {
    backgroundColor: "rgba(15,34,44,1)"
  }
};
