import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";

import { data } from "../../network/decorators/graphql-data";

export interface ClientData {
  id: string;
  slug: string;
  name: string;
}

export interface Client extends GraphQLModel<ClientData>, ClientData {}

export interface ClientValues {}

export class ClientModel extends GraphQLBase<ClientData> implements Client {
  typename = "Client";

  @data public name = "";
  @data public slug = "";
}
