export const page3 = {
  id: "9b11f56e-0b35-4253-8ccc-5a807b0d313c",
  slug: "page-3",
  title: "Page 3",
  description: "Some page description",
  content: "Article 2, page 3",
  type: "article",
  filter: "blur(10px)",
  background: "#786679",
  color: "#4a4a4a"
};
