import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

interface OwnProps
  extends PageContentProps,
    WithStyles<"root" | "background" | "wrapper"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  background: {
    background:
      "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    zIndex: 2
  }
});

export const A2P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper
      }}
    >
      <ContentLayoutColumns size="small" animation="closeIn">
        <ContentColumn>
          <Typography paragraph>
            In speciale gevallen — zoals bijvoorbeeld bij detentie is er sprake
            van omstandigheden die zodanig zijn dat van een werkgever niet
            verwacht kan worden dat hij de arbeidsovereenkomst laat voortduren —
            kan ook ontslag worden verleend. Vanaf 1 januari 2020 komt daar nog
            een ontslaggrond bij. Als er sprake is van een cumulatie van
            ontslaggronden waarbij net niet aan alle vereisten voor een ontslag
            op die gronden wordt voldaan, bijvoorbeeld verwijtbaar handelen
            gecombineerd met disfunctioneren en een verstoorde arbeidsrelatie,
            dan kan de arbeidsovereenkomst toch ontbonden worden.
          </Typography>
          <div>
            <Typography
              variant="h5"
              style={{ fontFamily: "Montserrat", fontWeight: 500 }}
              gutterBottom
            >
              Transitie&shy;vergoeding
            </Typography>
            <Typography paragraph>
              Onder het huidige recht heb je bij een ontslag geen recht op een
              ontslagvergoeding. Ook dit gaat veranderen.
            </Typography>
          </div>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Zowel bij een tijdelijke als een vaste arbeidsovereenkomst heb je
            vanaf 1 januari 2020 recht op een transitievergoeding als de
            arbeidsovereenkomst is geëindigd of niet is voortgezet op initiatief
            van de werkgever. Hierop bestaan een paar uitzonderingen waarvan de
            belangrijkste zijn dat je geen recht hebt op een transitievergoeding
            bij een ontslag wegens het bereiken van de pensioengerechtigde
            leeftijd of bij een ontslag als gevolg van ernstig verwijtbaar
            handelen of nalaten van de werknemer.
            <EndOfArticle />
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
