import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD"
  },
  image: {
    maxWidth: "100%",
    paddingTop: "0.25em",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    }
  }
});

export const A7P7 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="closeIn">
        <ContentColumn style={{ maxWidth: 600, display: "flex" }}>
          <img
            className={classes.image}
            src="/article/images/dutje.svg"
            alt=""
          />
        </ContentColumn>
        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography paragraph>
            Daarbovenop wordt de huidige TOD betaald. Voor een uur overwerken in
            het weekend, in de nacht, ontvang je dan een vergoeding van 25% +
            70% = 95%. De zaal reageerde op dit onderwerp en er volgden flinke
            discussies. De jaarurensystematiek kwam als laatste aan de orde. Je
            kan er voor kiezen om in een bepaalde periode meer te werken en in
            een andere periode juist minder. Aan het einde van het jaar blijkt
            hoeveel uren je in totaal hebt gewerkt. Als je aan het einde van het
            jaar tot 18 uur te weinig hebt gewerkt dan moet je die het volgende
            jaar meer werken. De rest vervalt. Heb je meer gewerkt dan hoef je
            het jaar daarop tot maximaal 18 uur minder te werken. De rest wordt
            uitbetaald tegen 125%.
          </Typography>
          <Typography>
            Om 19.30 uur sloot Marianne de vergadering. De broodjes die er nog
            lagen werden genuttigd en er werd nog nagepraat.
            <EndOfArticle />
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
