export const page3 = {
  id: "c7650420-9aac-4bcf-8fc4-9cbca23aa31e",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/a4blurredbackground.png") center`,
  color: "#4a4a4a"
};
