import * as React from "react";
import { Theme, Hidden } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { SlideFade } from "../../content/components/slide-fade";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps
  extends WithStyles<
    | "root"
    | "image"
    | "title"
    | "body"
    | "montserrat"
    | "subtitleWrapper"
    | "subtitle"
    | "header"
  > {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  color?: string;
  titleColor?: string;
  image?: string;
}

const styles = (theme: Theme): StyleRules => {
  const maxWidth = 460;

  return {
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      transition: "height 0.1s, width 0.1s"
    },
    header: {
      display: "flex",
      position: "relative",
      alignItems: "flex-end",
      width: "100%",
      padding: "0 20px"
    },
    image: {
      maxWidth: "100%",
      marginBottom: "-20%",
      marginLeft: "-20px",
      padding: "0 20px"
    },
    montserrat: {
      fontWeight: "bold",
      fontFamily: "Montserrat"
    },
    title: {
      maxWidth: "max-content",
      maxHeight: "fit-content",
      padding: theme.spacing(1, 2),
      clipPath: "polygon(0% 10%, 100% 0%, 98% 100%, 2% 100%)",
      margin: "0 0 -25px 0",
      zIndex: 2,
      flexShrink: 0,
      "& *": {
        fontWeight: 500,
        margin: "0 !important"
      },
      transition: "height 0.1s, width 0.1s"
    },
    body: {
      color: "inherit",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: maxWidth,
      margin: "0 auto",
      transition: "height 0.1s, width 0.1s"
    },
    subtitleWrapper: {
      height: "auto",
      width: "100%",
      backgroundColor: "white",
      padding: theme.spacing(3, 2, 2, 2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3, 2, 2, 2)
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      clipPath: "polygon(0% 3%, 100% 0%, 96% 100%, 2% 100%)",
      color: "#4a4a4a",
      marginBottom: theme.spacing(2),
      transition: "height 0.1s, width 0.1s"
    },
    subtitle: {
      maxWidth: maxWidth,
      margin: "0 auto"
    }
  };
};

export const TextBoard = withStyles(styles)(
  ({
    classes,
    title,
    children,
    color,
    subtitle,
    titleColor,
    image
  }: OwnProps) => {
    return (
      <>
        <RenderWhile mobile desktop>
          <div className={classes.root}>
            <header className={classes.header}>
              <div
                className={classes.title}
                style={{ backgroundColor: color, color: titleColor }}
              >
                <SlideFade direction="up" timeout={500}>
                  {title}
                </SlideFade>
              </div>
              <SlideFade direction="down" timeout={700}>
                <div style={{ overflowY: "hidden" }}>
                  <Hidden smDown>
                    <img className={classes.image} src={image} alt="" />
                  </Hidden>
                </div>
              </SlideFade>
            </header>
            <div className={classes.subtitleWrapper}>
              <SlideFade direction="up" timeout={900}>
                <div className={classes.subtitle}>{subtitle}</div>
              </SlideFade>
            </div>
            <div className={classes.body}>
              <SlideFade direction="up" timeout={1100}>
                {children}
              </SlideFade>
            </div>
          </div>
        </RenderWhile>
        <RenderWhile print>
          {title}
          {subtitle}
          {children}
        </RenderWhile>
      </>
    );
  }
);
