import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "wrapper"
    | "background"
    | "title"
    | "smallTitle"
    | "gridItem"
    | "maxWidth"
    | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#429FCD",
    display: "flex",
    color: "#FFf",
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      backgroundImage: "url('/article/images/roosteren_cover.svg')",
      backgroundPosition: "90% 50%",
      backgroundSize: "45%"
    }
  },
  mobile: {
    backgroundImage: "none"
  },
  background: {
    opacity: 0.5
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {
    maxWidth: 1800,
    width: "100%"
  }
});

export const A7P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCover
        category="Sfeerverslag"
        readTime="5,5 min"
        title={
          <Typography variant="h1" className={classes.title}>
            Het nieuwe <BR />
            roosteren
          </Typography>
        }
        subtitle={
          <Typography variant="h4" gutterBottom>
            Ledenbijeenkomsten AC <BR />
            Rijksvakbonden
          </Typography>
        }
        authors={{
          image: "/article/images/peter.png",
          credits: [{ credit: "Tekst", name: "Peter van Diepen" }]
        }}
        description={
          <Typography>
            Op dinsdag 4 september vonden er twee bijeenkomsten plaats <BR />
            voor leden van AC Rijksvakbonden georganiseerd door AC <BR />
            Rijksvakbonden. Het thema was: de onderhandelingsresultaten <BR />
            over ‘het nieuwe roosteren’.
          </Typography>
        }
      />
    </LayoutDefault>
  );
});
