import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

interface OwnProps
  extends PageContentProps,
    WithStyles<"background" | "root" | "wrapper"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  background: {
    background:
      "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    zIndex: 2
  }
});

export const A2P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper
      }}
    >
      <ContentLayoutColumns size="small" animation="closeIn">
        <ContentColumn>
          <Typography paragraph>
            De eerste is het vervallen van arbeidsplaatsen wegens
            bedrijfseconomische omstandigheden. Hiervoor moet de werkgever
            toestemming vragen aan het UWV. Dat geldt ook voor het verlenen van
            ontslag wegens ziekte of gebreken van de werknemer waardoor hij niet
            meer in staat is de bedongen arbeid te verrichten. Ontslag wegens
            ziekte kan alleen als de ziekte ten minste twee jaar heeft geduurd
            en aannemelijk is dat binnen 26 weken geen herstel zal optreden en
            dat binnen die periode de bedongen arbeid niet in aangepaste vorm
            kan worden verricht.
          </Typography>
          <Typography>
            De werkgever kan aan de kantonrechter vragen je arbeidsovereenkomst
            te ontbinden als je regelmatig je bedongen arbeid niet kunt
            verrichten als gevolg van ziekte of gebreken van de werknemer en dit
            leidt tot voor de bedrijfsvoering onaanvaardbare gevolgen.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography>
            Ook bij aantoonbaar disfunctioneren anders dan ten gevolge van
            ziekte of gebreken van de werknemer kan de kantonrechter jouw
            arbeidsovereenkomst ontbinden. Indien jij je dusdanig verwijtbaar
            gedraagt of dingen nalaat te doen waardoor jouw werkgever in
            redelijkheid niet gevraagd kan worden jou in dienst te houden, kan
            ook ontslag volgen. Het komt niet vaak voor, maar als jij weigert
            jouw werkzaamheden te verrichten wegens een ernstig gewetensbezwaar
            en jouw werk niet in aangepaste vorm kan worden gedaan, kan dit ook
            reden zijn om tot ontslag te komen. Vaker komt voor dat sprake is
            van een dusdanig verstoorde arbeidsverhouding dat de
            arbeidsovereenkomst in redelijkheid niet kan blijven bestaan. In zo
            een geval zal de rechter een ontbindingsverzoek toewijzen.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
