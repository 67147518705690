import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { LayoutAnimator } from "../../../../layout/layout-animator";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  inner: {
    maxWidth: "calc(630px + 5vw)",
  },
});

export const NoaHolleman5 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile,
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography variant="h5" className={classes.montserrat} gutterBottom>
                Waar moet de NCF zich met de hoogste prioriteit hard voor maken?
              </Typography>
              <Typography paragraph>
                ‘De NCF moet zich hard maken voor loopbaanbegeleiding, zodat
                voor alle medewerkers een fijne werkplek blijft bestaan.’
              </Typography>
              <Typography variant="h5" className={classes.montserrat} gutterBottom>
                Wat zou je in je leven weleens over willen doen?
              </Typography>
              <Typography paragraph>
                ‘In je leven maak je elke dag keuzes. Soms blijkt achteraf dat
                je niet de beste keuze hebt gemaakt. Het is echter wel zo dat je
                door die verkeerde keuzes tegenslagen leert incasseren en
                accepteren. Je leert dat je dit kunt overwinnen door wilskracht
                en doorzettingsvermogen. Hierdoor sta je uiteindelijk veel
                sterker en dit vormt je als persoon. Wat ik overigens wel graag
                over zou willen doen zijn mijn vakanties van de afgelopen jaren.
                Die waren namelijk erg gezellig!’
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
