import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { ClientModel, Client, ClientData } from "./client";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";

import { subscribe } from "../../network/decorators/graphql-subscribe";
import { ClientCreated, ClientUpdated, ClientDeleted } from "./graphql/subscriptions";
import { CreateClient, UpdateClient, DeleteClient } from "./graphql/mutations";
import { QueryClient } from "./graphql/queries";
import { defaultClient } from "./__mock__/clients";

export interface ClientProvider extends GraphQLProviderProps<Client, ClientData> {
  fetchAll(): Promise<void>;
}

export interface ClientValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultClientProvider extends GraphQLProvider<Client, ClientData> implements ClientProvider {
  public model = ClientModel;

  protected serializers: RecordSerializers<ClientData> = {
    values: JSONSerializer
  };

  constructor() {
    super();
    this.setRecord(defaultClient.id, defaultClient);
  }

  public async fetchAll() {}

  @subscribe(ClientCreated) templateCreated = (template: Client) => {
    return template;
  };

  @subscribe(ClientUpdated) templateUpdated = (template: Client) => {
    return template;
  };

  @subscribe(ClientDeleted) templateDeleted = (template: Client) => {
    return template;
  };

  public createOperation(template: ClientData) {
    return graphqlOperation(CreateClient, { input: template });
  }

  public fetchOperation(template: ClientData) {
    return graphqlOperation(QueryClient, { id: template.id });
  }

  public updateOperation(template: ClientData) {
    return graphqlOperation(UpdateClient, { input: template });
  }

  public deleteOperation(template: ClientData) {
    return graphqlOperation(DeleteClient, { id: template.id });
  }
}

// export function templateUsersOptions(template: any) {
//   return {
//     model: UserModel,
//     operation: graphqlOperation(QueryMagazineUsers, { id: template.id })
//   };
// }
