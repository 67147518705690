import * as React from "react";
import { WithStyles, Theme, Typography } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { BR } from "../../../../../content/components/linebreak";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContactOption } from "../../../../../interface/partials/contact-option";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#B8FDE7",
    display: "flex",
    justifyContent: "center",
    color: "#4a4a4a",
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center",
    },
    "& $ctaWrapper": {
      textAlign: "left",
      height: "unset",
      paddingBottom: 0
    }
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  wrapper: {
    maxWidth: "calc(1000px + 5vw)",
  },
  ctaWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%",
    paddingBottom: 100
  },
});

export const WistJeDat5 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile,
        }}
      >
        <ContentLayoutColumns>
          <ContentColumn wide>
            <TextBoard
              image="/article/images/vent-turquoise.png"
              color="#528CB2"
              titleColor="#fff"
              title={
                <>
                  <Typography variant="h5">4/4 Wist je dat...?</Typography>
                </>
              }
              subtitle={
                <Typography variant="h5" style={{ fontStyle: "italic" }}>
                  ...Helder Overzicht & Inzicht (oftewel <BR />
                  HOI) nieuw is in MijnABP?
                </Typography>
              }
            >
              <Typography paragraph>
                Met Helder Overzicht & Inzicht krijg je eenvoudig inzicht in
                jouw financiële toekomst als je met pensioen gaat. Op basis van
                wat ABP al van je weet, berekent ABP wat je verwachte inkomsten
                en uitgaven zullen zijn op AOW-leeftijd.  Ga naar abp.nl/hoi,
                log in en bekijk het even.
              </Typography>
              <Typography>
                Wil je samen met een pensioenbegeleider kijken wat e.e.a. voor
                jou betekent, neem dan contact op met de NCF. Een van onze
                NCF-pensioen-belangenbehartigers kijkt graag met je mee.{" "}
                <EndOfArticle dark />
              </Typography>
            </TextBoard>
          </ContentColumn>
          <ContentColumn>
            <div className={classes.ctaWrapper}>
              <Typography
                gutterBottom
                variant="h6"
                style={{ borderBottom: "5px solid", paddingBottom: 10 }}
                className={classes.montserrat}
              >
                Links | Jouw finiciële toekomst
              </Typography>
              <ContactOption
                style={{ fontFamily: "Montserrat" }}
                header={<small>Helder Overzicht & Inzicht (HOI)</small>}
                footer={
                  <small>
                    Bereken wat je verwachte inkomsten en uitgaven zullen zijn
                    op AOW-leeftijd.
                  </small>
                }
              />
              <ContactOption
                style={{ fontFamily: "Montserrat" }}
                header={<small>Contact met de NCF</small>}
                footer={
                  <small>
                    samen met een pensioenbegeleider kijken wat e.e.a. voor jou
                    betekent.
                  </small>
                }
              />
              <ContactOption
                style={{ fontFamily: "Montserrat", borderBottom: "none" }}
                header={<small>Meer achtergrondinfo</small>}
                footer={
                  <small>
                    Lees ook hier nog eens het artikel (pag. 5) van Jan Mennen
                    (een van onze drie NCF pensioenbelangenbehartigers) in ons
                    Bondig Magazine #50
                  </small>
                }
              />
            </div>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
