import { IPresenter } from "../../helpers/with-presenter";
import { RouteComponentProps } from "react-router";
import { History } from "history";
import { observable } from "mobx";
import { PossibleRouterParams } from "../../config/routes";
import Application from "../../application/application";

export class BasicPagePresenter implements IPresenter {
  @observable public loading: boolean = false;
  protected history: History;

  constructor(protected _router: RouteComponentProps<PossibleRouterParams>) {
    this.history = this._router.history;
    Application.router = this._router;
  }

  public mount = () => {};
  public unmount = () => {};

  public back = (e: any) => {
    e.preventDefault();
    this._router.history.goBack();
  };
}
