import * as React from "react";
import { Theme, Typography, Link } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
// import { RenderWhile } from "../../../../../content/components/render-while";
import { QandA } from "../../../../../interface/partials/qanda";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { BR } from "../../../../../content/components/linebreak";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "wrapper" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D32949",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  mobile: {
    "& $wrapper": {
      maxWidth: "unset"
    },
    "& h5": {
      fontSize: "calc(1.4rem + 0.5em)"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {}
});

export const BestuursverkiezingP2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile
        }}
      >
        <QandA
          question={
            <>
              <RenderWhile desktop>
                <Typography paragraph variant="h6" align="center">
                  Een van de dingen die we nu alvast met jou willen delen, is
                  dat er bij het <BR />
                  agendapunt Bestuursverkiezingen drie vacatures zijn. Dit zijn
                  de in functie te <BR />
                  benoemen secretaris en penningmeester en een algemeen
                  bestuurslid.
                </Typography>
              </RenderWhile>
              <RenderWhile mobile>
                <Typography paragraph variant="h6">
                  Een van de dingen die we nu alvast met jou willen delen, is
                  dat er bij het <BR />
                  agendapunt Bestuursverkiezingen drie vacatures zijn. Dit zijn
                  de in functie te <BR />
                  benoemen secretaris en penningmeester en een algemeen
                  bestuurslid.
                </Typography>
              </RenderWhile>
              <RenderWhile print>
                <Typography paragraph variant="h6">
                  Een van de dingen die we nu alvast met jou willen delen, is
                  dat er bij het <BR />
                  agendapunt Bestuursverkiezingen drie vacatures zijn. Dit zijn
                  de in functie te <BR />
                  benoemen secretaris en penningmeester en een algemeen
                  bestuurslid.
                </Typography>
              </RenderWhile>
            </>
          }
          answer={
            <ContentLayoutColumns>
              <ContentColumn>
                <Typography
                  gutterBottom
                  variant="h5"
                  className={classes.montserrat}
                >
                  Kandidaat stellen?
                </Typography>
                <Typography paragraph>
                  Als je interesse hebt maar nog wat meer info wil krijgen,
                  vraag deze dan bij de voorzitter (
                  <Link href="mailto:voorzitter@ncf.nl">voorzitter@ncf.nl</Link>
                  ).
                </Typography>
                <Typography>
                  Als je concrete belangstelling hebt en je kandidatuur wil
                  indienen voor een van de drie functies, geef dit dan door via{" "}
                  <Link href="mailto:secretaris@ncf.nl">secretaris@ncf.nl</Link>
                  .
                </Typography>
              </ContentColumn>
              <ContentColumn>
                <Typography
                  gutterBottom
                  variant="h5"
                  className={classes.montserrat}
                >
                  Spelregels
                </Typography>
                <Typography paragraph>
                  De spelregels hiervoor zijn simpel: ten minste 2 maanden voor
                  de AV (dus uiterlijk 22 februari 2020) moet je kandidatuur bij
                  het bestuur bekend zijn. Er moet dan gelijktijdig een door jou
                  ondertekende bereidverklaring bijgevoegd worden. Je moet al 2
                  jaar betalend lid van de NCF zijn en ondertekende
                  steunbetuigingen van ten minste 10 NCF-collega’s aanleveren.
                </Typography>
              </ContentColumn>
            </ContentLayoutColumns>
          }
        />
      </LayoutDefault>
    );
  }
);
