import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";

export const bondigVerruimingBedrijfsfitness: ArticleData = {
  id: "b20c48bb-dba1-4084-b16e-8573fa6914fc",
  slug: "bondig-verruiming-bedrijfsfitness",
  title: "Verruiming bedrijfsfitness",
  description: "",
  index: "5",
  image: "/article/images/verruiming-bedrijfsfitness_thumb.png",
  backgroundImage: "/article/images/verruiming-bedrijfsfitness_cover.png",
  content: "",
  pages: [page1, page2, page3, page4],
  type: "article",
  color: "white",
  style: {
    backgroundColor: "#2E3878"
  }
};
