import * as React from "react";
import { Theme, Typography, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#f4f5f5",
    color: "#fff",
    display: "flex",
    backgroundImage: "url('/article/images/transitievergoeding-cover.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundPosition: "center",
  },
  mobile: {
    backgroundPosition: "10% center",
    backgroundSize: "cover",
  },
  desktop: {
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
  },
  background: {
    // opacity: 0.5,
    // backgroundImage:
    //   "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    width: "100%",
    zIndex: 2,
    maxWidth: 1400,
  },
  title: {
    fontWeight: 500,
  },
});

export const TransitievergoedingCover = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
          desktop: classes.desktop,
        }}
      >
        <ContentLayoutCover
          category="Uit de praktijk"
          readTime="2,5 min"
          title={
            <Typography variant="h2" className={classes.title}>
              Vanaf 1 januari 2020 recht <BR />
              op transitie&shy;vergoeding!
            </Typography>
          }
          subtitle={
            <Typography variant="h4">
              In de vorige Bondig kondigde ik al <BR />
              aan ook de komende edities <BR />
              aandacht te zullen besteden aan het <BR />
              vanaf 1 januari 2020 voor jullie <BR />
              geldende civiele arbeidsrecht.
            </Typography>
          }
          authors={{
            image: "/article/images/jaq.png",
            credits: [
              { credit: "Tekst", name: "Jacqueline Choufoer-van der Wel" },
            ],
          }}
          description={<></>}
          size="big"
        />
      </LayoutDefault>
    );
  }
);
