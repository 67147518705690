export const page1 = {
  id: "96f3a405-a638-4a70-bd1f-1854d8e348f2",
  slug: "winnaars",
  title: "Winnaars",
  description: "Some page description",
  content: "",
  type: "article",
  overlay: "linear-gradient(to right, rgba(51,38,20,0.85) 0%,rgba(201,160,81,0.4) 100%)",
  color: "#4a4a4a"
};
