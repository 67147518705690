import * as React from "react";
import { withStyles, Theme, Typography } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import classnames from "classnames";
import { LayoutAnimator } from "../../pages/layout/layout-animator";
import { AnimationWrapper } from "./animation-wrapper";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps
  extends WithStyles<"root" | "montserrat" | "answer" | "question"> {
  children?: any;
  className?: any;
  style?: any;
  question: any;
  answer: any;
  topHeader?: string;
  bottomHeader?: string;
  size?: "small" | "big";
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    maxWidth: "calc(820px + 5vw)",
    "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
    },
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  question: {
    marginBottom: theme.spacing(4)
  },
  answer: {
    // columnCount: 2,
    // columnWidth: 350
  },
  big: {
    maxWidth: "calc(1300px + 5vw)"
  }
});

export const QandA = withStyles(styles)(
  ({
    children,
    classes,
    className,
    style,
    question,
    answer,
    topHeader,
    bottomHeader,
    size
  }: OwnProps) => (
    <>
      <RenderWhile desktop mobile>
        <div
          className={classnames(classes.root, size && classes[size], className)}
          style={{ ...style }}
        >
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <div className={classes.question}>
                {topHeader && (
                  <Typography
                    align="center"
                    paragraph
                    variant="h5"
                    className={classes.montserrat}
                  >
                    {topHeader}
                  </Typography>
                )}

                {question}
              </div>
              <div>
                {bottomHeader && (
                  <Typography
                    align="center"
                    paragraph
                    variant="h5"
                    className={classes.montserrat}
                  >
                    {bottomHeader}
                  </Typography>
                )}
              </div>
              <div className={classes.answer}>{answer}</div>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </RenderWhile>
      <RenderWhile print>
        {topHeader && (
          <Typography className={classes.montserrat}>{topHeader}</Typography>
        )}
        <Typography>{question}</Typography>
        {bottomHeader && (
          <Typography className={classes.montserrat}>{bottomHeader}</Typography>
        )}
        {answer}
        {children}
      </RenderWhile>
    </>
  )
);
