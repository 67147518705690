import { ClientFragment } from "./fragments";

export const CreateClient = `
mutation CreateClient($input:inputClient!) {
createClient(input:$input){
  ${ClientFragment}
}
}
`;

export const UpdateClient = `
mutation updateClient($input:inputClient!) {
  updateClient(input:$input) {
    ${ClientFragment}
  }
}
`;

export const DeleteClient = `
mutation DeleteClient($id:ID!) {
  deleteClient(id:$id){
    ${ClientFragment}
  }
}
`;
