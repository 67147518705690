import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";
import { page6 } from "./pages/page-6";
import { page7 } from "./pages/page-7";
import { page8 } from "./pages/page-8";
import { page9 } from "./pages/page-9";
import { page10 } from "./pages/page-10";

export const ziekZijn: ArticleData = {
  id: "e9ca22ec-c1fc-42b9-9130-cf20190a4067",
  slug: "ziek-zijn",
  index: "5",
  title: "Ziek zijn - wat nu?",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  style: {
    backgroundColor: "#FFE288"
  },
  image: "/article/images/ziek_zijn-thumb.png",
  content: "",
  pages: [page1, page2, page3, page4, page5, page6, page7, page8, page9, page10],
  type: "article"
};
