import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "background" | "title" | "wrapper" | "gridItem" | "maxWidth" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    color: "#4A4A4A",
    backgroundImage: "url('/article/images/marianne_cover.png')",
    backgroundColor: "#FFE288",
    backgroundSize: "auto 90vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right center`,
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "auto 100vh",
      backgroundPosition: "calc(50% + 15vw) 0"
    },
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`
  },
  mobile: {
    backgroundImage: "none",
  },
  background: {
    // background: "linear-gradient(to right, #FFE288 40%, #00000000 100%)",
    // opacity: 0.7
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {
    maxWidth: 1000,
    width: "100%",
    margin: "auto"
  }
});

export const A6P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCover
        category="Uit de praktijk"
        readTime="3,5 min"
        title={
          <Typography variant="h1" className={classes.title}>
            Vraag het <BR />
            Marianne <BR />
            Wendt
          </Typography>
        }
        subtitle=""
        authors={{
          image: "/article/images/marianne.png",
          credits: [{ credit: "Tekst", name: "Marianne Wendt" }]
        }}
        description=""
        size="small"
      />
    </LayoutDefault>
  );
});
