import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";
import { page6 } from "./pages/page-6";
import { page7 } from "./pages/page-7";

export const bondigArticle7: ArticleData = {
  id: "799782b5-ee7f-41c3-9ad8-ab5fcb8c4499",
  slug: "bondig-article-7",
  title: "Het nieuwe roosteren",
  description: "",
  index: "11",
  image: "/article/images/Het nieuwe roosteren.png",
  backgroundImage: "/article/images/a7thumb.png",
  content: "",
  pages: [page1, page2, page3, page4, page5, page6, page7],
  type: "article",
  style: {
    backgroundColor: "#429FCD"
  }
};
