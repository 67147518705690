import GraphQLProvider, { GraphQLProviderProps } from "../graphql-provider";
// import { graphqlOperation, API } from "aws-amplify";

export function subscribe(query: any) {
  return function(target: any, key: string) {
    delete target[key];
    // const privkey = "_" + key;
    // const func = arguments[2].initializer();

    target._subscriptions = target._subscriptions || [];
    target._subscriptions.push((provider: GraphQLProvider<any, any>) => {
      // const options = subscriptionOptions(provider as any);
      // return API.graphql(graphqlOperation(query)).subscribe({
      //   next: (response: any) => {
      //     const data = response.value.data[key];
      //     delete data.__typename;
      //     const formatted = func(options.formatter(data));
      //     if (provider.subscribers[key]) {
      //       provider.subscribers[key].forEach(listener => {
      //         listener(formatted);
      //       });
      //     }
      //   }
      // });
    });

    return Object.defineProperty(target, key, {
      get: function(this: any) {
        return () => this._subscriptions[key].bind(this);
      }
    });
  };
}

export function subscriptionOptions(provider: GraphQLProviderProps<any, any>) {
  return {
    formatter: (data: any) => {
      provider.updateRecord(data.id, data);
      return provider.model!.get(data.id);
    }
  };
}
