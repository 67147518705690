export const page1 = {
  id: "ca823f72-64af-401d-a362-005967ceadc7",
  slug: "page-1",
  title: "Article 2, Page 1",
  description: "Some page description",
  content: {
    category: "Interview",
    readTime: "4,5 min",
    title: {
      variant: "h1",
      style: { fontFamily: "Montserrat", fontWeight: 500 },
      content: "Kennis maken met..."
    },
    subtitle: {
      variant: "h6",
      content: "Interview met Sjoerd Draaisma"
      // props: {
      //   paragraph: true,
      // },
    },
    layout: "left",
    authors: {
      image: "/article/images/donk.png",
      credits: [
        { credit: "Tekst", name: "Ad van der Donk" },
        { credit: "Beeld", name: "Paul Heijne" }
      ]
    },
    description: {
      content:
        "Op de website van de NCF staat vermeld: ‘We zijn een bond \ndie staat! Al meer dan 125 jaar. Duizenden collega's \nbij het ministerie van Financiën hebben zich al bij de \nNCF aangesloten. “Voor leden, door leden” is het motto. \nJouw belang is ons belang. We doen dit met volle energie en \nmet gemotiveerde mensen.’"
    }
  },
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  layout: "ContentLayoutCover"
};
