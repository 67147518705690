import * as React from "react";
import { Typography , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Quote } from "../../../../../interface/partials/quote";

type OwnProps = PageContentProps &
  WithStyles<"root" | "bold" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD",

  },
  bold: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  }
});

export const A7P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn style={{ maxWidth: 500 }}>
          <Typography paragraph>
            Thema’s zoals: meeroosteren, het versterken van de positie van de
            medezeggenschap, TOD 55+, verlofbonus, vergoedingen/toeslagen en
            de jaarurensystematiek. Sommige van deze thema’s waren al besproken
            tijdens eerdere bijeenkomsten van AC Rijksvakbonden over de
            cao-onderhandelingen. De thema’s uit de nieuwsbrief vormden een
            leidraad voor deze bijeenkomsten. Eén voor één kwamen ze aan bod en
            werden ze besproken met de zaal. In dit verslag komen wat punten uit
            de nieuwsbrief en vragen die uit de zaal kwamen aan de orde.
          </Typography>

          <Quote variant="h5">
            Het eerste thema brengt meteen al wat los in de zaal.
          </Quote>
        </ContentColumn>

        <ContentColumn style={{ maxWidth: 500 }}>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Meeroosteren
          </Typography>
          <Typography>
            Om 17.30 uur opende Marianne de tweede bijeenkomst van die dag en
            werd iedereen welkom geheten. Het eerste thema, meeroosteren, bracht
            meteen al wat los in de zaal. Meeroosteren betekent dat je meer
            invloed krijgt op je rooster dan bij collectief roosteren, wat
            bepaald wordt door je leidinggevende of planner. Bij meeroosteren
            zijn er minder beschermingsmaatregelen voor werk- en rusttijden, je
            kan immers zelf je rust inplannen. Volgens de zaal zou meeroosteren
            weleens voor problemen kunnen zorgen voor de rusttijden.
            Meeroosteren wordt namelijk bepaald in drie rondes. In de eerste
            twee rondes heb je zelf veel invloed op je rooster maar in de derde
            ronde heeft de leidinggevende dit. Die kan, in de derde ronde,
            beslissen om je op een dienst te zetten als nog niemand zich in de
            eerste twee rondes hierop heeft ingeschreven.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
