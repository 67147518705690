import * as React from "react";
import { Grid, Theme } from "@material-ui/core";
import { StyleRules, WithStyles, withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { LayoutAnimator, animationSets } from "./layout-animator";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps extends WithStyles<"root" | "big" | "small" | "gridItem"> {
  size?: "big" | "small";
  children: React.ReactNode;
  spacing?: number;
  animation?: animationSets;
  className?: any;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
      
    }
  },
  big: { maxWidth: "calc(1300px + 5vw)" },
  small: { maxWidth: "calc(960px + 5vw)" },
  gridItem: {
    padding: "1vw",
  },
  [theme.breakpoints.down("lg")]: {
    maxWidth: "none",
  },
});

export const ContentLayoutColumns = withStyles(styles)(
  ({ classes, size, spacing, children, animation, className }: OwnProps) => {
    return (
      <div style={{flexDirection: "column"}}>
        <RenderWhile desktop>
          <Grid
            container
            alignContent="center"
            justify="center"
            spacing={2}
            className={classnames(
              classes.root,
              size && classes[size],
              className
            )}
          >
            <LayoutAnimator animation={animation}>{children}</LayoutAnimator>
          </Grid>
        </RenderWhile>

        <RenderWhile mobile>{children}</RenderWhile>
        <RenderWhile print>{children}</RenderWhile>
      </div>
    );
  }
);
