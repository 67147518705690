import * as React from "react";
import { Theme, Typography, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    backgroundImage: "url('/article/images/china-cover.png')",
    backgroundPosition: "left center",
    justifyContent: "center",
  },
  mobile: {
    backgroundPosition: "10% center",
    backgroundSize: "cover",
  },
  desktop: {
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
  },
  background: {
    // opacity: 0.5,
    // backgroundImage:
    //   "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    maxWidth: "fit-content",
    width: "100%",
    zIndex: 2,
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const ChinaCover = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
          desktop: classes.desktop,
        }}
      >
        <ContentLayoutCover
          category="Ook NCF"
          readTime="2 min"
          title={
            <Typography variant="h2" className={classes.title}>
              ‘Als ik bel, <BR />
              kan ik aan het <BR />
              geruis op de <BR />
              lijn horen <BR />
              dat ik word <BR />
              afge&shy;luisterd.’
            </Typography>
          }
          subtitle=""
          authors={{
            image: "/article/images/guido.png",
            credits: [{ credit: "Tekst", name: "Guido de Greef" }],
          }}
          description={
            <Typography variant="h4">
              Esther Mol is sinds 2013 werkzaam <BR />
              bij de Belastingdienst.
            </Typography>
          }
          size="big"
          supportImage="/article/images/china_cover_image.png"
        />
      </LayoutDefault>
    );
  }
);
