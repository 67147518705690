import * as React from "react";
import { Typography, Hidden, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";
import { QandA } from "../../../../../interface/partials/qanda";
import classnames from "classnames";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "montserrat" | "bold">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center"
  },
  montserrat: {
    fontFamily: "montserrat",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: "bold"
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end"
  }
});

export const A6P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <RenderWhile desktop>
        <ContentLayoutColumns animation="trickle">
          <ContentColumn style={{ height: "fit-content" }}>
            <Typography variant="h5" className={classes.montserrat}>
              Vraag
            </Typography>
            <Typography
              style={{ fontWeight: 700 }}
              className={classes.montserrat}
            >
              Ik heb een vraag met betrekking tot het IKB-budget. Er wordt
              aangegeven dat de uitkering eenmalig in mei 2020 wordt uitbetaald:
              vakantietoeslag en de eindejaarsuitkering. Houdt dit in dat de
              eindejaarsuitkering in december 2019 hierdoor vervalt? En wat
              gebeurt er met het vakantiegeld in 2020 dat al staat? Wordt het
              vakantiegeld vermeerderd met de eindejaarsuitkering, of krijg je
              dubbel vakantiegeld?
            </Typography>
          </ContentColumn>

          <ContentColumn>
            <Typography variant="h5" className={classes.montserrat}>
              Antwoord
            </Typography>
            <Typography paragraph>
              In november 2019 ontvang je nog jouw eindejaarsuitkering. In mei
              2020 ontvang je het vakantiegeld van juni tot en met december 2019
              plus de eindejaarsuitkering over de maand december 2019.
            </Typography>
            <Typography>
              In november 2020 ontvang je geen eindejaarsuitkering. Deze is dan
              vervangen door het IKB-budget. Hetzelfde geldt voor het
              vakantiegeld in mei 2020. Dit ontvang je ook niet, aangezien deze
              is vervangen door het IKB-budget.
            </Typography>
          </ContentColumn>
          <div>
            <Hidden mdDown>
              <ContentColumn className={classes.image}>
                <img src="/article/images/UITROEPTEKEN.png" alt="" />
              </ContentColumn>
            </Hidden>
          </div>
        </ContentLayoutColumns>
      </RenderWhile>

      <RenderWhile mobile>
        <QandA
        topHeader="Vraag"
          question={
            <Typography
              className={classnames(classes.montserrat, classes.bold)}
            >
              Ik heb een vraag met betrekking tot het IKB-budget. Er wordt
              aangegeven dat de uitkering eenmalig in mei 2020 wordt uitbetaald:
              vakantietoeslag en de eindejaarsuitkering. Houdt dit in dat de
              eindejaarsuitkering in december 2019 hierdoor vervalt? En wat
              gebeurt er met het vakantiegeld in 2020 dat al staat? Wordt het
              vakantiegeld vermeerderd met de eindejaarsuitkering, of krijg je
              dubbel vakantiegeld?
            </Typography>
          }
          bottomHeader="Antwoord"
          answer={
            <>
              <Typography paragraph>
                In november 2019 ontvang je nog jouw eindejaarsuitkering. In mei
                2020 ontvang je het vakantiegeld van juni tot en met december
                2019 plus de eindejaarsuitkering over de maand december 2019.
              </Typography>
              <Typography>
                In november 2020 ontvang je geen eindejaarsuitkering. Deze is
                dan vervangen door het IKB-budget. Hetzelfde geldt voor het
                vakantiegeld in mei 2020. Dit ontvang je ook niet, aangezien
                deze is vervangen door het IKB-budget.
              </Typography>
            </>
          }
        />
      </RenderWhile>
    </LayoutDefault>
  );
});
