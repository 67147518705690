import * as React from "react";
import { Typography, Button, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";
import { AnimationWrapper } from "./animation-wrapper";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { mapEvent } from "../../../helpers/formatters";
import classnames from "classnames";

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    marginBottom: 40
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  barRoot: {
    backgroundColor: "#fff5",
    height: 6
  },
  bar: {
    backgroundColor: "white"
  },
  question: {
    maxWidth: 550
  },
  answer: {
    marginBottom: 20
  },
  selected: {
    backgroundColor: "#fff",
    color: "#005555",
    borderColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#005555",
      borderColor: "#fff"
    }
  },
  radio: {
    display: "flex",
    position: "absolute",
    left: theme.spacing(1),
    width: 17,
    height: 17,
    color: "white",
    border: "1px solid #fff",
    borderRadius: "100%",
    padding: 3,
    "& > span": {
      display: "block",
      borderRadius: "100%",
      flex: 1,
      background: "#fff"
    }
  }
});

interface OwnProps
  extends WithStyles<
    | "root"
    | "montserrat"
    | "barRoot"
    | "bar"
    | "question"
    | "answer"
    | "radio"
    | "selected"
  > {
  options: Array<string | number | boolean>;
  answers: Array<string | number | boolean>;
  multiselect?: boolean;
  onChange?(value: Array<string | number | boolean>): void;
}

@observer
class MultipleChoiceComponent extends React.Component<OwnProps> {
  @observable public value: string[] = [];

  private _onSelectHandler = (answer: string) => {
    const { multiselect, onChange, answers } = this.props;
    const index = answers.indexOf(answer);

    if (multiselect) {
      if (index >= 0) {
        answers.splice(index, 1);
      } else {
        answers.push(answer);
      }

      if (onChange) {
        onChange(answers);
      }
    } else {
      if (onChange) {
        onChange([answer]);
      }
    }
  };

  render() {
    const { classes, options, multiselect, answers } = this.props;

    return (
      <div className={classes.root}>
        <AnimationWrapper baseTimeout={500} animation="fadeUp">
          <div className={classes.question}>
            {multiselect && (
              <Typography
                className={classes.montserrat}
                variant="subtitle2"
                align="center"
                paragraph
              >
                Meerdere opties mogelijk
              </Typography>
            )}
          </div>
        </AnimationWrapper>

        <div
          style={{
            columnCount: Math.ceil(options.length / 4),
            columnWidth: 250
          }}
        >
          <AnimationWrapper baseTimeout={500} animation="fadeUp">
            {options.map((answer: string | number | boolean, index: number) => {
              const selected = answers.indexOf(answer.toString()) > -1;
              console.log(selected);

              return (
                <Button
                  key={index}
                  disableRipple
                  className={classnames(
                    classes.answer,
                    !multiselect && selected && classes.selected
                  )}
                  color="inherit"
                  variant="outlined"
                  fullWidth
                  onClick={mapEvent(this._onSelectHandler, answer.toString())}
                >
                  {multiselect && (
                    <span className={classes.radio}>
                      {selected && <span />}
                    </span>
                  )}
                  {answer}
                </Button>
              );
            })}
          </AnimationWrapper>
        </div>
      </div>
    );
  }
}

export const MultipleChoice = withStyles(styles)(MultipleChoiceComponent);
