import { computed } from "mobx";
import { isset } from "../helpers";

export function data(target: any, key: string) {
  delete target[key];
  const defaultValue = arguments[2].initializer();
  return computed(
    Object.defineProperty(target, key, {
      get: function(this: any) {
        if (this.updated && isset(this.updated[key])) {
          return this.updated[key];
        }

        if (this.provider) {
          const record = this.provider.getRecord(this.id);
          if (record && isset(record[key])) {
            return record[key] || defaultValue;
          }
        }

        return defaultValue;
      },
      set: function(this: any, val: any) {
        this.updated = this.updated || {};
        this.updated[key] = val;
      },
      enumerable: true,
      configurable: true
    })
  );
}
