import * as React from "react";
import { withStyles, Typography, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { RenderWhile } from "../../content/components/render-while";

interface OwnProps extends WithStyles<"root" | "icon"> {
  time?: string;
  category: string;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5em",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(2)
    }
  },
  icon: {
    margin: "0.5em 10px 1em 10px",
    [theme.breakpoints.up("md")]: {
      margin: "0 10px"
    }
  }
});

export const ReadTime = withStyles(styles)(
  ({ classes, time, category }: OwnProps) => (
    <>
      <RenderWhile desktop mobile>
        <div className={classes.root}>
          <Typography variant="body2">{category}</Typography>
          {time && (
            <>
              <FontAwesomeIcon
                className={classes.icon}
                icon={faClock}
              ></FontAwesomeIcon>
              <Typography variant="body2">Leestijd: {time}</Typography>
            </>
          )}
        </div>
      </RenderWhile>
      <RenderWhile print>
        <Typography variant="body2">
          {category} {time && ` - Leestijd: ${time}`}
        </Typography>
      </RenderWhile>
    </>
  )
);
