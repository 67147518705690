import * as React from "react";
import { WithStyles, Theme, Typography } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { BR } from "../../../../../content/components/linebreak";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContactOption } from "../../../../../interface/partials/contact-option";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#528CB2",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center",
    },
    "& $ctaWrapper": {
      textAlign: "left",
      height: "unset",
      paddingBottom: 0
    }
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  wrapper: {
    maxWidth: "calc(1000px + 5vw)",
  },
  ctaWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    paddingBottom: 150
  },
});

export const WistJeDat4 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile,
        }}
      >
        <ContentLayoutColumns>
          <ContentColumn wide>
            <TextBoard
              image="/article/images/vent-turquoise.png"
              color="#B8FEE7"
              titleColor="#4a4a4a"
              title={
                <>
                  <Typography variant="h5">3/4 Wist je dat...?</Typography>
                </>
              }
              subtitle={
                <Typography variant="h5" style={{ fontStyle: "italic" }}>
                  ...aan medewerkers die per 1 januari <BR />
                  de AOW-gerechtigde leeftijd <BR />
                  bereiken ontslag wordt verleend?
                </Typography>
              }
            >
              <Typography paragraph>
                Zijn er bijzondere persoonlijke of financiële omstandigheden,
                dan kan de werkgever een verzoek om langer door te werken,
                inwilligen. Meer info? Klik hier. Let op: indien je vóór 1
                januari 2020 al de AOW-gerechtigde leeftijd hebt bereikt dan
                geldt bovenstaande niet. Je huidige rechten blijven behouden.
                Pas bij het bereiken van de 70-jarige leeftijd wordt ontslag
                verleend. Ook daarna kan je nog langer doorwerken, maar dan moet
                je hiertoe een verzoek doen bij je werkgever en je dient elk
                jaar een arbeidsgezondheidskundig onderzoek te ondergaan.
              </Typography>
            </TextBoard>
          </ContentColumn>
          <ContentColumn>
            <div className={classes.ctaWrapper}>
              <Typography
                gutterBottom
                variant="h6"
                style={{ borderBottom: "5px solid", paddingBottom: 10 }}
                className={classes.montserrat}
              >
                Meer info?
              </Typography>
              <ContactOption
                style={{ fontFamily: "Montserrat" }}
                header={<small>Verzoek langer doorwerken</small>}
                footer={
                  <small>
                    Zijn er bijzondere persoonlijke of financiële
                    omstandigheden, dan kan de werkgever een verzoek om langer
                    door te werken, inwilligen.
                  </small>
                }
              />
            </div>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
