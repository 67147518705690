export const page5 = {
  id: "af9dd006-4a98-426a-aace-d6fa702c21d3",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/a4blurredbackground.png") center`,
  color: "#4a4a4a"
};
