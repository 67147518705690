import { BusinessModule, DefaultBusinessModule } from "./business/business";
import { BaseModule } from "./module";
import { DefaultNetworkModule, NetworkModule } from "./network/network";
import { RouteComponentProps } from "react-router";
import { PossibleRouterParams } from "../config/routes";

export interface IApplication {
  network: NetworkModule;
  business: BusinessModule;
  router: RouteComponentProps<PossibleRouterParams> | undefined;
}

class DefaultApplication
  extends BaseModule<
    {},
    {},
    {
      network: NetworkModule;
      business: BusinessModule;
    }
  >
  implements IApplication {
  public router: RouteComponentProps<PossibleRouterParams> | undefined = undefined;

  public get network() {
    return this.loadModule("network", DefaultNetworkModule);
  }

  public get business() {
    return this.loadModule("business", DefaultBusinessModule);
  }
}

const Application = new DefaultApplication();
export default Application;
