export const page3 = {
  id: "12b1518d-85e6-41f0-ba34-336f9158c287",
  slug: "page-3",
  title: "Page 3",
  description: "Some page description",
  content: "Article 2, page 3",
  type: "article",
  filter: "blur(10px)",
  background: "#786679"
};
