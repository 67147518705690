import { observable, computed, toJS } from "mobx";
import { API } from "aws-amplify";

export type PollQuestionType = "rating" | "multiselect" | "select" | "text";

export interface PollQuestion {
  id: string;
  type: PollQuestionType;
  question: string;
  answers: Array<string | number | boolean>;
  options?: Array<string | number | boolean>;
  validations?: string[];
}

export class PollInteractor {
  @observable public fetched: boolean = false;
  @observable public currentQuestionIndex: number = 0;
  @observable public questions: PollQuestion[] = [];

  @computed public get finished(): boolean {
    const answerCount = this.questions.reduce((count, { answers }) => {
      return (answers || []).length ? count + 1 : count;
    }, 0);
    return answerCount >= this.questions.length;
  }

  // {
  //   Poll(id:"poll-bondig-51"){
  //     submissions{
  //       pollID
  //       answers
  //       questionID
  //     }
  //   }
  // }

  public fetch = async (id: string) => {
    const response = await API.graphql({
      query: `{
        Poll(id:"poll-bondig-51"){
          id
          questions{
            id
            type
            question
            answers
            validations
            options
          }
        }
      }`,
      variables: {}
    });

    this.fetched = true;

    if (!response["error"] && response["data"] && response["data"].Poll) {
      this.questions = response["data"].Poll.questions;
    }
  };

  @computed public get currentQuestion(): PollQuestion | undefined {
    return this.questions[this.currentQuestionIndex || 0];
  }

  public submitAnswer = async (value: string | number | any[]) => {
    const question = this.currentQuestion;
    let formattedValue: string[] = [];

    if (question) {
      if (typeof value === "string") {
        formattedValue = [value];
      } else if (typeof value === "number") {
        formattedValue = [value.toString()];
      } else if (Array.isArray(value)) {
        formattedValue = value;
      } else {
      }

      question.answers = formattedValue;

      await API.graphql({
        query: `mutation submitPollAnswer($pollID:ID!, $input:inputPollQuestion){
      submitPollAnswer(pollID:$pollID, input:$input){
        id
      }
    }`,
        variables: {
          pollID: "poll-bondig-51",
          input: toJS(question)
        }
      });

      this._setPollState();
    }
  };

  public get state() {
    const state = this.questions.reduce((result, { id, answers }) => {
      result[id] = { answers };
      return result;
    }, {});

    return {
      state,
      finished: this.finished
    };
  }

  private _setPollState = () => {
    window.localStorage.setItem("poll-state", JSON.stringify(this.state));
  };

  private _getPollState() {
    const status = window.localStorage.getItem("poll-state");
    return (status && JSON.parse(status)) || {};
  }

  public initPoll = () => {
    const stored = this._getPollState();
    const state = stored.state;

    this.questions.forEach((question, index) => {
      const questionState = state && state[question.id];
      question.answers = (questionState && questionState.answers) || [];
    });

    this.nextQuestion();
  };

  public nextQuestion = () => {
    let nextQuestionIndex: number | undefined = undefined;

    this.questions.forEach((question, index) => {
      nextQuestionIndex = nextQuestionIndex === undefined && !question.answers.length ? index : nextQuestionIndex;
    });

    this.currentQuestionIndex = nextQuestionIndex === undefined ? this.questions.length : nextQuestionIndex;
  };
}
