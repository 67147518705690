import * as React from "react";
import classnames from "classnames";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { Hidden, Theme, Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { spacing } from "../../../theme/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTh,
  // faNewspaper,
  faBars,
  faPrint,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import { observer } from "mobx-react";
import { route } from "../../../config/routes";
import { ArticleNavigator } from "./article-navigator";
import { InterfacePresenter } from "../interface-presenter";

interface OwnProps
  extends WithStyles<
    "root" | "menuLeft" | "logo" | "top" | "bottom" | "left" | "right" | "link"
  > {
  location?: "top" | "bottom" | "left" | "right";
  presenter: InterfacePresenter;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    position: "fixed",
    bottom: 0,
    background: "#fff",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #E7E7E7",
    pointerEvents: "all",
    zIndex: 1400
  },
  menuLeft: {
    display: "inline-flex",
    position: "absolute",
    top: 0,
    right: "auto",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static"
    }
  },
  logo: {
    maxWidth: 90,
    marginTop: 2,
    filter: `invert(26%) sepia(17%) saturate(0%) hue-rotate(274deg) brightness(55%) contrast(79%)`,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 50
    }
  },
  top: { top: 0, left: 0, right: 0, height: theme.spacing(5) },
  bottom: { bottom: 0, left: 0, right: 0, height: theme.spacing(5) },
  left: { top: 0, bottom: 0, left: 0, width: theme.spacing(5) },
  right: { top: 0, bottom: 0, right: 0, width: theme.spacing(5) },
  link: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#4A4A4A",
    padding: spacing(2),
    height: "auto",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    borderRight: "1px solid #E7E7E7",
    borderLeft: "1px solid #E7E7E7",
    marginLeft: 0,
    [theme.breakpoints.down("sm")]: {
      border: "none"
    },
    "&:hover": {
      backgroundColor: "#eee"
    },
    "&.active": {
      // color: "#4A4A4A",
      // background: "#E7E7E7",
      // fontWeight: 300
    },
    "&:last-of-type": {
      borderLeft: "none"
    }
  }
});

const ButtonArticleIndex = ({ className, magazine, edition }: any) => (
  <NavLink
    className={className}
    to={route("index.articles", {
      magazine: magazine || "",
      edition: edition || ""
    })}
  >
    <FontAwesomeIcon icon={faTh} size="2x" />
    <Hidden mdDown>
      <p style={{ marginLeft: "14px" }}>inhoudsopgave</p>
    </Hidden>
  </NavLink>
);

export const Navigator = withStyles(styles)(
  observer(({ presenter, classes, location }: OwnProps) => {
    const {
      magazine,
      edition,
      article,
      articles,
      toNextArticle,
      toPreviousArticle,
      // isSingleMagazine,
      isPrint
    } = presenter;

    return (
      <div className={classnames(classes.root, classes[location || "bottom"])}>
        <div className={classes.menuLeft}>
          {/* {!isSingleMagazine && magazine && (
            // <Hidden mdDown>
            //   <NavLink
            //     className={classes.link}
            //     to={route("index.magazines")}
            //     style={{ width: 60, padding: 15 }}
            //   >
            //     <FontAwesomeIcon icon={faNewspaper} size="2x"></FontAwesomeIcon>
            //   </NavLink>
            // </Hidden>
          )} */}

          {magazine && (
            <NavLink
              to={route("index.editions", {
                magazine: magazine.id || ""
              })}
              className={classes.link}
            >
              <img
                alt={magazine.title}
                src={magazine.logo}
                className={classes.logo}
              />
            </NavLink>
          )}

          {edition && magazine && magazine.editions.length > 1 && (
            <NavLink
              className={classes.link}
              to={route("index.editions", {
                magazine: magazine.id || ""
              })}
            >
              <Hidden mdDown>
                <p>Andere edities</p>
              </Hidden>

              <Hidden lgUp>
                <FontAwesomeIcon icon={faBars} size="2x"></FontAwesomeIcon>
              </Hidden>
            </NavLink>
          )}

          {magazine && edition && article && (
            <Hidden smDown>
              <ButtonArticleIndex
                className={classes.link}
                magazine={magazine.id}
                edition={edition.id}
              />
            </Hidden>
          )}
        </div>
        {article && (
          <ArticleNavigator
            list={(articles || []).map(article => article.id)}
            current={article.id}
            onNext={toNextArticle}
            onPrevious={toPreviousArticle}
          />
        )}
        {magazine && edition && article && (
          <Hidden mdUp>
            <ButtonArticleIndex
              className={classes.link}
              magazine={magazine.id}
              edition={edition.id}
            />
          </Hidden>
        )}
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            right: 0,
            padding: 0,
            height: "100%",
            overflowY: "hidden"
          }}
        >
          {/* <Button className={classes.link} onClick={e => presenter.sharePage}>
            <FontAwesomeIcon size="2x" icon={faShareAlt} />
          </Button> */}
          <Hidden mdDown>
            <Button className={classes.link} onClick={presenter.printArticle}>
              <FontAwesomeIcon size="2x" icon={isPrint ? faTimes : faPrint} />
            </Button>
          </Hidden>
        </div>
      </div>
    );
  })
);
