import * as React from "react";
import { Typography , Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";

type OwnProps = PageContentProps &
  WithStyles<"root" | "smallTitle" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#161D51",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  }
});

export const A5P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography paragraph>
            Vanwege de onzekere werksituatie bij de Douane besloot ik in 1991 de
            overstap te maken naar “blauw”, naar de basisadministratie van
            Ondernemingen Heerenveen. Daarna ging ik administratief werk doen in
            team 5 bij de Loonbelasting. Omdat de overstap van mbo naar twintig
            jaar later SPD groot was, heb ik alleen de vier echte leervakken
            gehaald. Hierdoor mocht ik later gelukkig wel meesolliciteren voor
            de E-functies. In 1996 werd ik bij het loopbaanonderzoek geschikt
            bevonden voor de groepsfuncties E en F. Maar bij sollicitaties kwam
            ik er maar niet doorheen, omdat ik voor de commissie steeds
            dichtklapte. Raar eigenlijk, omdat ik – zoals je merkt – eigenlijk
            toch een makkelijke prater ben. Maar in 2006 mocht ik dan eindelijk
            toch naar de tweejarige E-opleiding in Groningen. Daarna begon ik
            met deelonderzoeken en startersbezoeken. In die periode werd ik ook
            gevraagd voor de OR-Noord. In de tweede zittingsperiode was ik ook
            2e secretaris, waardoor het primaire proces wat buiten beeld raakte.
            Eind 2012 sloot kantoor Heerenveen de deuren en verkaste ik “terug”
            naar Leeuwarden. Na de periode in de OR-Noord mocht ik de cursus
            “Inleiding controletechnieken” in Utrecht gaan volgen.
          </Typography>
        </ContentColumn>

        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography paragraph>
            Dat kostte me veel energie vanwege de thuissituatie. Na een val van
            de trap van mijn vrouw had ik namelijk lange tijd ook veel
            huishoudelijke taken. Anderhalf jaar deed ik onderzoeken IH/OB en
            sinds september 2018 doe ik werkzaamheden rondom de G-rekening.’
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Wat doe je binnen de NCF? 
          </Typography>

          <Typography>
            ‘Ik ben sinds 1996 lid van de NCF. Voor die tijd hoorde ik niets van
            de NCF, van geen enkele vakbond trouwens. Ik werd voor het eerst
            actief voor de NCF binnen de OR-Noord. Eerder bezocht ik wel elk
            jaar de ledenraad. In 2011 werd ik ook IB’er voor de NCF in mijn
            regio. Via mail en telefoon ontving ik behoorlijk wat vragen van
            collega’s, meestal op personeelsgebied. Ik was ook coördinator
            binnen de IB-groep, maar stopte daarmee bij mijn verkiezing in de OR
            MKB eerder dit jaar. Over dit OR-werk kan ik nog niet veel zeggen,
            omdat ik nog maar net begonnen ben. We vergaderen twee keer per
            maand in Utrecht met alle OR MKB-leden en om de zes weken met de
            directeur MKB en zijn staf. Daarnaast zit ik in de OR voor de
            werkgroep Communicatie en de werkgroep Bedrijfsvoering.’
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
