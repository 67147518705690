import { createMuiTheme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { themeConfig, typography } from "./config";

// const defaultTheme = createMuiTheme();

function createTheme(options: ThemeOptions) {
  return createMuiTheme({
    spacing: themeConfig.spacing,
    typography: {
      fontFamily: "Domaine",
      allVariants: {
        "& strong": {
          color: "inherit"
        }
      },
      body1: {
        ...typography("body1")
        // [defaultTheme.breakpoints.down("md")]: {
        //   lineHeight: "1.5",
        //   fontSize: "3vw"
        // }
      },
      body2: {
        ...typography("body2")
      },
      h1: {
        ...typography("h1")
      },
      h2: {
        ...typography("h2")
      },
      h3: {
        ...typography("h3")
      },
      h4: {
        ...typography("h4")
      },
      h5: {
        ...typography("h5")
      },
      h6: {
        ...typography("h6")
      },
      button: { ...typography("button") },
      subtitle1: {
        ...typography("subtitle1")
      },
      subtitle2: {
        ...typography("subtitle2")
      },
      overline: {
        ...typography("overline")
      },
      caption: {
        ...typography("caption")
      }
    },
    palette: {
      primary: {
        main: "#E9550D"
      },
      secondary: {
        main: "#00FF00"
      },
      error: {
        main: "#B94A48"
      }
    },
    shape: {
      borderRadius: 0
    },
    props: {
      MuiCard: {
        elevation: 0
      },
      MuiExpansionPanel: {
        elevation: 0
      },
      MuiInputLabel: {
        shrink: true
      },
      MuiTypography: {
        variant: "body2"
      }
    },
    overrides: {
      MuiTypography: {
        paragraph: {
          marginBottom: "1.66em"
        },
        gutterBottom: {
          marginBottom: "0.5em"
        }
      },
      MuiButton: {
        outlined: {
          borderRadius: 50,
          paddingLeft: 36,
          paddingRight: 36
        }
      },
      MuiLink: {
        root: {
          color: "inherit"
        }
      }
    },
    ...options
  });
}

export const theme = createTheme({});
