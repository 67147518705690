export const page2 = {
  id: "07050537-7e9d-4237-bd2b-577034b2d39d",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  filter: "blur(10px)",
  overlay: "linear-gradient(to right, rgba(18,102,128,1) 0%,rgba(19,115,145,0) 61%,rgba(20,124,156,0) 100%)"
};
