import * as React from "react";
import { withStyles, Typography, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { RenderWhile } from "../../content/components/render-while";

export type Credit = { credit: string; name: string };

interface OwnProps
  extends WithStyles<
    "root" | "mugshot" | "credit" | "avatarBubble" | "textWrapper"
  > {
  avatar: any;
  credits: Credit[];
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    padding: theme.spacing(1, 0),
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-8)
    }
  },
  avatarBubble: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    overflow: "hidden",
    marginRight: theme.spacing(2),
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: "white",
    "@media print": {
      display: "none"
    }
  },
  mugshot: {
    backgroundSize: "cover",
    width: "100%",
    textAlign: "center",
    position: "absolute"
  },
  credit: {
    fontFamily: "Montserrat",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "14px",

    "& b": {
      fontWeight: "bold"
    }
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "start"
  }
});

export const Author = withStyles(styles)(
  ({ classes, avatar, credits }: OwnProps) => (
    <>
      <RenderWhile desktop>
        <div className={classes.root}>
          <div className={classes.avatarBubble}>
            <img className={classes.mugshot} alt="" src={avatar} />
          </div>
          <div className={classes.textWrapper}>
            <Typography variant={"body2"} className={classes.credit}>
              {credits.map((credit, index) => {
                return (
                  <span key={index} style={{ display: "block" }}>
                    <b>{credit.credit}: </b>
                    {credit.name}
                  </span>
                );
              })}
            </Typography>
          </div>
        </div>
      </RenderWhile>
      <RenderWhile mobile>
        <Typography variant={"body2"} className={classes.credit}>
          {credits.map((credit, index) => {
            return (
              <span key={index} style={{ display: "block" }}>
                <b>{credit.credit}: </b>
                {credit.name}
              </span>
            );
          })}
        </Typography>
      </RenderWhile>
    </>
  )
);
