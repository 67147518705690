import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#F1C9A4",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw",
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const China5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{ root: classes.root, inner: classes.contentWrapper }}
    >
      <ContentLayoutColumns size="big" animation="trickle">
        <ContentColumn>
          <Typography variant="h5" className={classes.header} gutterBottom>
            Ik zou dat niet zo’n leuk land vinden.
          </Typography>
          <Typography paragraph>
            ‘Het is een geweldig land. Het is een heel andere cultuur, je leeft
            in een fantasiewereld. Het is totaal anders dan de westerse wereld.
            Tijdens één van m’n reizen kreeg ik als waarschuwing mee: “de muren
            hebben oren.” Dat is echt zo. Als ik bel, kan ik aan de kliks en het
            geruis op de lijn horen dat ik word afgeluisterd.
          </Typography>
          <Typography>
            Toen m’n vriend voor het eerst mee naar China ging, heb ik vooraf
            gezegd: “Denk erom, China is geweldig, het hotel is fantastisch en
            het eten is heerlijk. Als je iets minder leuk vindt, dan zeg dat
            maar als we terug in Nederland zijn.”’
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography variant="h5" className={classes.header} gutterBottom>
            Zou je niet meer met je kennis van het Chinees willen doen?
          </Typography>
          <Typography>
            ‘Ik gebruik Chinees dagelijks in m’n vrije tijd. Ik ben ambassadeur
            van het Confucius Instituut in Maastricht. Bedrijven en
            particulieren kunnen daar terecht voor informatie over het land en
            de cultuur. Ik volg lessen en geef lezingen. Maar niet als beroep.
            Ik heb de keuze gehad voor Chinese media te werken, maar daar zit
            censuur op. Dat vind ik minder prettig’ <EndOfArticle dark />
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
