import * as React from "react";
import { WithStyles, StyleRules, withStyles } from "@material-ui/styles";

import { observer } from "mobx-react";
import { ArticlePresenter } from "../article-presenter";
import { Theme } from "@material-ui/core";
import { RenderWhile } from "../../content/components/render-while";
import classnames from "classnames";

interface OwnProps
  extends WithStyles<
    "root" | "inner" | "background" | "print" | "mobile" | "desktop"
  > {
  backgroundStyle?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];
  presenter?: ArticlePresenter;
}

const styles = (theme: Theme): StyleRules => {
  return {
    root: {
      fontSize: "calc(0.6rem + 1.5vw)",
      [theme.breakpoints.up("md")]: {
        fontSize: "calc(1rem + 0.1vw)",
        padding: theme.spacing(8, 9),
        paddingBottom: theme.spacing(13),
      }
    },
    print: {
      fontSize: "0.4rem",
      "& h1, & h2, & h3, & h4, & h5, & h6": { margin: "1em 0 0.5em 0" },
      "& p": { margin: "0.5em 0 1em 0", lineHeight: 1.5, fontSize: 13 },
      "& img": { display: "none" },
      color: "black",
    },
    mobile: {
      fontSize: "calc(0.6rem + 1.5vw)",
      "& h1": {
        fontSize: "calc(1rem + 1.8em)",
      },
      "& h1, & h2, & h3, & h4, & h5, & h6": {
        margin: "1em 0 0.5em 0",
      },
      "& p": {
        margin: "0.5em 0 1em 0",
      },
      "& $inner": {
        padding: theme.spacing(0.5, 2),
      },
    },
    desktop: {
      position: "relative",
      transition: "all 0.3s",
      backgroundColor: "#eee",
      padding: theme.spacing(10, 8),
      paddingBottom: theme.spacing(15),
      backgroundSize: "cover",
      alignItems: "center",
      minHeight: "100vh",
      // justifyContent: "center",

      [theme.breakpoints.up("lg")]: {
        fontSize: "calc(1.5rem + 0.1vw)",
      },

      [theme.breakpoints.up("xl")]: {
        fontSize: "calc(1rem + 0.5vw)",
      },

      "& $inner": {
        position: "relative",
        fontSize: "1em",
        display: "flex",
        justifyContent: "center",
      },
    },

    inner: {
      width: "100%",
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        
      },
    },

    background: {
      transition: "all 0.3s",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundPosition: "center",
      backgroundSize: "Cover",
      backgroundRepeat: "no-repeat",
    },
  };
};

export const LayoutDefault = withStyles(styles)(
  observer(({ classes, children, backgroundStyle, presenter }: OwnProps) => {
    return (
      <>
        <RenderWhile desktop>
          <section className={classnames(classes.root, classes.desktop)}>
            <div className={classes.background} style={backgroundStyle}></div>
            <div className={classes.inner}>{children}</div>
          </section>
        </RenderWhile>
        <RenderWhile mobile>
          <div className={classnames(classes.root, classes.mobile)}>
            <div className={classes.inner}>{children}</div>
          </div>
        </RenderWhile>
        <RenderWhile print>
          {/* {presenter &&
          presenter.currentPage &&
          presenter.currentPage.type === "cover" ? (
            <section className={classnames(classes.root, classes.desktop)}>
              <div className={classes.background} style={backgroundStyle}></div>
              <div className={classes.inner}>{children}</div>
            </section>
          ) : ( */}
          <div className={classes.print}>{children}</div>
          {/* )} */}
        </RenderWhile>
      </>
    );
  })
);
