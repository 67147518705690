import { observable, computed } from "mobx";

import { BaseModule } from "../../module";
import { Template, TemplateData } from "./template";
import DefaultTemplateProvider, { TemplateProvider } from "./template-provider";

export interface TemplateInteractor {
  created: Template | undefined;
  selected: Template | undefined;
  select(id: string): Template | undefined;
  create: (data?: Partial<TemplateData>, persist?: boolean) => Template;
  store(template: Template): Promise<void>;
  find(id: string): Template | undefined;
  all: Template[];
  provider: TemplateProvider;
}

export default class DefaultTemplateInteractor extends BaseModule<any, any, any> implements TemplateInteractor {
  @observable public created: Template | undefined = undefined;
  @observable public selected: Template | undefined = undefined;

  public find = this.provider.find;
  public store = this.provider.store;

  constructor() {
    super();
    this.provider.fetchAll();
  }

  public create = (data: Partial<TemplateData> = {}) => {
    const template = this.provider.create(data);
    return template;
  };

  public select(id: string): Template | undefined {
    const template = (this.selected = this.provider.find(id));
    if (template) {
      template.fetch();
    }
    return template;
  }

  @computed public get all() {
    return this.provider.allRecords.map(template => this.provider.find(template.slug)!);
  }

  public get provider(): TemplateProvider {
    return this.loadProvider("provider", DefaultTemplateProvider);
  }
}
