import { ArticleFragment } from "../../article/graphql/fragments";

export const MagazineFragment = `
  id
  image
  title
  description
  logo
  type
  slug
  editors {
    type
    users
  }
  editions {
    id
    index
    title
    image
    subtitle
    description {
      style {
        fontWeight
      }
      marginBottom
      content
      element
    }
    articles {
      ${ArticleFragment}
    }
}
`;
