import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

interface OwnProps
  extends PageContentProps,
    WithStyles<"root" | "smallTitle" | "gridItem" | "contentWrapper"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D6C9B1",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "center"
  },
  smallTitle: {
    fontFamily: "Domaine",
    color: "white",
    textTransform: "none",
    fontWeight: 500
  },
  gridItem: {
    padding: "1vw"
  },
  contentWrapper: {
    maxWidth: "50vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none"
    }
  }
});

export const A3P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="small" animation="trickle">
        <ContentColumn>
          <Typography paragraph>
            Voorzichtig manoeuvreren we door ons huis. En onze vaste gewoontes
            worden ter ziele geholpen door twee actieve peuters. Mijn moeizaam
            door de jaren heen verworven rechten, verdwijnen als sneeuw voor de
            zon. Op de hoek van de bank, normaal mijn vaste stek, ligt nu de
            bejaarde hond met naast hem de twee meisjes die hem liefdevol
            knuffelen.
          </Typography>
          <Typography paragraph>
            De ochtendkrant past niet meer op de keukentafel, daar liggen nu
            kleurpotloden, tekenpapier, barbiepoppen, lego en ondefinieerbare
            vormpjes van klei die een kunstzinnige toekomst voorspellen voor de
            makers. Uitslapen behoort ook tot het verleden. Tijdens het vroege
            ochtendgloren wordt ons bed getest en gebruikt als trampoline.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            De informatieve televisieprogramma’s hebben het veld geruimd voor
            tekenfilms en kinderprogramma’s en de code van de iPad is ontmanteld
            en nu in gebruik als spelletjesmonster en soms als educatief
            leermateriaal.
          </Typography>
          <Typography paragraph>
            Het mobieltje is ook geconfisqueerd en ik ben daarom niet altijd
            meer bereikbaar. ‘Kom je ons van school halen?’, vraagt de oudste
            kleindochter en vervolgt, ‘Wil je dan ook je portemonnee meenemen?’
            Ze zijn slim en weten bij wie ze moeten zijn.
          </Typography>
          <Typography paragraph>
            Tenslotte is de ijscowinkel om de hoek. ‘Dat is het lot van een
            jaknikker,’ zegt mijn vrouw onaangedaan.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
