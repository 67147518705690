import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "wrapper"
    | "background"
    | "title"
    | "smallTitle"
    | "gridItem"
    | "maxWidth"
    | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
    backgroundImage: "url('/article/images/wist-je-dat_cover.png')",
    backgroundColor: "#EDD0D2",
    backgroundSize: "50vw auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `5vw -5vh`,
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "auto 75vh",
      backgroundPosition: "calc(50% - 15vw) -5vh"
    },

  },
  mobile: {
    backgroundSize: "40vw auto",
    backgroundPosition: "right -80px"
    // backgroundImage: "none"
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  title: {
    fontFamily: "Domaine",
    fontWeight: 500
  },
  link: {
    color: "inherit"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {
    // width: `calc(50% - ${theme.spacing(7)}px)`
    width: "100%",
    maxWidth: "calc(1400px + 5vw)",
    padding: "0 8vw",
    [theme.breakpoints.down("sm")]: {
      padding: "60px 0 0 0"
    }
  }
});

export const A8P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCover
        category="Wist je dat..?"
        readTime="1 min"
        title={
          <Typography variant="h2" gutterBottom className={classes.title}>
            Wist je dat...?
          </Typography>
        }
        subtitle={
          <Typography paragraph variant="h5">
            ...dit de allereerste <BR />
            interactieve Bondig is?
          </Typography>
        }
        description={
          <Typography>
            Elke uitgave lees je hier de laatste wist-je-dat-jes.
          </Typography>
        }
        align="right"
      />
    </LayoutDefault>
  );
});
