import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#F1C9A4",
    color: "#4a4a4a",
    display: "flex",
  },
  montserrat: {
    fontFamily: "montserrat",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 600,
  },
  mobile: {
    "& $paper": {
      width: "calc(100% - 20px)",
      margin: "0 10px 0 10px",
    },
    "& $inner": {
      paddingBottom: theme.spacing(1),
    },
    "& $imageWrapper": {
      margin: 0,
      padding: 0,
    },
  },
  inner: {
    position: "relative",
    zIndex: 1,
  },
  imageWrapper: {
    display: "flex",
    width: "100%",
    marginLeft: "20px",
  },
  textWrapper: {
    position: "relative",
    padding: "30px 0",
  },
  paper: {
    backgroundColor: "#fff",
    position: "absolute",
    left: 0,
    top: 0,
    width: "75%",
    height: "100%",
    boxShadow: "-30px 30px 35px -10px #0001",
    zIndex: -1,
    "& img": {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
});

export const China2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        mobile: classes.mobile,
        inner: classes.inner,
      }}
    >
      <ContentLayoutColumns
        animation="trickle"
        size="small"
        className={classes.textWrapper}
      >
        <RenderWhile desktop mobile>
          <div className={classes.paper}>
            {/* <img src="/article/images/sq-pink.svg" />
            <img src="/article/images/sq-yellow.svg" /> */}
          </div>
        </RenderWhile>
        <ContentColumn style={{ paddingLeft: "20px", position: "relative" }} >
          <Typography
            variant="h5"
            style={{ marginBottom: "2em" }}
            className={classes.montserrat}
          >
            Paspoort
          </Typography>
          <Typography>
            <b>Geboortedatum: </b>30-11-1987
          </Typography>
          <Typography>
            <b>Opleiding: </b>Oriëntaalse Talen en Communicatie
          </Typography>
          <Typography>
            <b>Loopbaan: </b>
          </Typography>
          <Typography paragraph>
            Voordat Esther bij de Belastingdienst begon, heeft zij ook in China
            gewerkt voor een korte periode. Uiteindelijk is ze in 2013 begonnen
            bij de BelastingTelefoon. In 2014 begon ze bij Douane Eindhoven als
            junior communicatieadviseur interne en internationale communicatie.
          </Typography>
          <Typography paragraph>
            Begin 2016 is Esther bij CKC Belastingdienst begonnen als
            communicatiemedewerker. Dat werd in 2017 CDC en toen werd ze
            webredacteur voor het intranet. Begin 2019 is ze begonnen bij het
            Forateam MKB als redacteur en nu is ze eindredacteur. Ook sinds 2019
            is ze ambassadeur voor het Confucius Instituut in Maastricht.
          </Typography>
          <Typography>
            <b>Burgerlijke staat: </b>Relatie
          </Typography>
          <Typography>
            <b>Woonplaats: </b>Eindhoven
          </Typography>
        </ContentColumn>
        <ContentColumn style={{ display: "flex" }}>
          <div className={classes.imageWrapper}>
            <img
              src="/article/images/china-2.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
