import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const bondigVoorwoord: ArticleData = {
  id: "83648da2-b82a-4101-9965-a22ea4f1b272",
  slug: "bondig-article-3",
  title: "Voorwoord",
  index: "2",
  description: "",
  image: "/article/images/voorwoord_thumb.png",
  backgroundImage: "/article/images/orange_gradient.png",
  content: "",
  pages: [page1],
  type: "article"
};
