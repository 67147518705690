import * as React from "react";
import { Grid, Theme, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import { SlideFade } from "../../content/components/slide-fade";
import { Author, Credit } from "../../interface/partials/author";
import { ReadTime } from "../../interface/partials/readtime";
import classnames from "classnames";
import { RenderWhile } from "../../content/components/render-while";
// import { FormattedText } from "../../interface/partials/formatted-text";

type AuthorProps = {
  image: string;
  credits: Credit[];
};

interface OwnProps extends WithStyles<any> {
  title: any;
  subtitle: any;
  description?: any;
  readTime?: string;
  category: string;
  authors?: AuthorProps;
  layout?: string;
  supportImage?: string;
  size?: "big" | "small";
  align?: "left" | "center" | "right";
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      justifyContent: "center",
      paddingBottom: theme.spacing(5),
    },
  },
  title: {
    "& > *": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "calc(1rem + 1.5em)",
        margin: "0 0 0.5em 0 !important",
      },
    },
  },
  left: {
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      textAlign: "left",
    },
  },
  centered: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      alignItems: "center",
    },
  },
  right: {
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-end",
      textAlign: "right",
    },
  },
  alignLeft: {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  alignRight: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  alignCenter: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  mobileWrapper: {
    marginTop: "auto",
  },
  big: { maxWidth: "calc(1300px + 5vw)" },
  small: { maxWidth: "calc(960px + 5vw)" },
  supportImage: {
    maxHeight: "fit-content",
    maxWidth: "50%",
    alignSelf: "center",
  },
});

export const ContentLayoutCover = withStyles(styles)(
  ({
    classes,
    title,
    subtitle,
    description,
    category,
    readTime,
    authors,
    layout,
    align,
    size,
    supportImage,
  }: OwnProps) => {
    const alignment = align ? align : "left";
    const contentLayout = layout ? layout : "left";

    return (
      <>
        <RenderWhile desktop>
          <Grid
            container
            direction="column"
            className={classnames(
              classes.root,
              classes[contentLayout],
              classes[alignment],
              size && classes[size]
            )}
          >
            <Grid item>
              <SlideFade direction="up" timeout={400}>
                <div>
                  <ReadTime category={category} time={readTime} />
                </div>
              </SlideFade>
            </Grid>
            <Grid item>
              <SlideFade direction="up" timeout={500}>
                {/* <FormattedText input={title} /> */}
                <div className={classes.title}>{title}</div>
              </SlideFade>
            </Grid>
            <Grid item>
              {authors && (
                <SlideFade direction="up" timeout={600}>
                  <div>
                    <Author avatar={authors.image} credits={authors.credits} />
                  </div>
                </SlideFade>
              )}
            </Grid>
            <Grid item>
              {subtitle && (
                <SlideFade direction="up" timeout={700}>
                  <div>
                    {/* <FormattedText paragraph input={subtitle} /> */}
                    {subtitle}
                  </div>
                </SlideFade>
              )}
              {description && (
                <SlideFade direction="up" timeout={800}>
                  {/* <FormattedText input={description} /> */}
                  <div>{description}</div>
                </SlideFade>
              )}
            </Grid>
          </Grid>
          {supportImage && (
            <img className={classes.supportImage} src={supportImage} alt="" />
          )}
        </RenderWhile>

        <RenderWhile mobile>
          <div className={classes.mobileWrapper}>
            <ReadTime category={category} time={readTime} />
            <div className={classes.title}>{title}</div>
            {authors && (
              <div>
                <Author avatar={authors.image} credits={authors.credits} />
              </div>
            )}
            <div>{subtitle}</div>
            <div>{description}</div>
          </div>
        </RenderWhile>

        <RenderWhile print>
          <ReadTime category={category} time={readTime} />
          {title}
          {authors && (
            <Author avatar={authors.image} credits={authors.credits} />
          )}
          {subtitle}
          {description}
        </RenderWhile>
      </>
    );
  }
);
