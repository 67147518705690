import { IPresenter } from "../../../helpers/with-presenter";
import { PrintInteractor } from "../../../application/business/interactor/print-interactor";
import { computed } from "mobx";

export class RenderWhilePresenter implements IPresenter {
  @computed public get isPrint() {
    return this._printInteractor.printActive;
  }

  constructor(private _printInteractor: PrintInteractor) {
    //
  }

  public mount() {
    //
  }

  public unmount() {
    //
  }
}
