export const page2 = {
  id: "788901a2-ee15-4ed2-aea0-30294e79b213",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  overlay: "#C5B390",
  background: "#D6C9B1",
  color: "#4a4a4a"
};
