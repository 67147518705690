import { GraphQLOptions } from "@aws-amplify/api/lib/types";
import { observable } from "mobx";

import { StorageRecord } from "../storage/store";
import { GraphQLProviderProps } from "./graphql-provider";
import Application from "../application";

export interface Model<TData> extends StorageRecord {
  updated: Partial<TData>;
  loading: boolean;
}

export interface GraphQLModel<TData extends StorageRecord> extends Model<TData> {
  id: string;
  typename: string;
  fetch(): Promise<void>;
  save(): Promise<void>;
  delete(): Promise<void>;
  serialize(data?: TData): TData;
  provider: GraphQLProviderProps<GraphQLModel<TData>, TData>;
  updateProperty(key: keyof TData, value: any): void;
  loading: boolean;
  loaded: boolean;
  updated: Partial<TData>;
}

export class GraphQLBase<TData extends StorageRecord> implements GraphQLModel<TData> {
  @observable public updated: Partial<TData> = {};
  @observable public loaded: boolean = false;
  @observable public loading: boolean = false;

  public static get(id: string) {
    return new (this as any)(id);
  }

  public typename: string = "";

  constructor(public id: string, public provider: GraphQLProviderProps<GraphQLModel<TData>, TData>) {}

  public async query(operation: GraphQLOptions, queryName: string, propName?: string) {
    const response = await Application.network.fetch(operation.query as string, operation.variables);

    const result = response.data && response.data[queryName];
    this.provider.updateRecord(this.id, { ...result });

    if (response.errors) {
      console.error(response.errors);
    }

    return response.data && propName ? response.data[queryName][propName] : response.data && response.data[queryName];
  }

  public async fetch() {
    this.loading = true;
    const operation = this.provider.fetchOperation(this.provider.serialize(this));
    if (operation) {
      await this.query(operation, this.typename);
    }
    this.loaded = true;
    this.loading = false;
  }

  public async save() {
    this.loading = true;
    this.provider.updateRecord(this.id, this.provider.serialize(this));
    const operation = this.provider.updateOperation(this.provider.serialize(this));

    if (operation) {
      await this.query(operation, this.typename);
    }
    this.loading = false;
  }

  public async delete() {
    this.loading = true;
    const operation = this.provider.deleteOperation(this.provider.serialize(this));
    if (operation) {
      await this.query(operation, this.typename);
      this.provider.deleteRecord(this.id);
    }
    this.loading = false;
  }

  public serialize(data?: TData): TData {
    return data || this.provider.serialize(this);
  }

  public updateProperty = (key: keyof TData, value: any): void => {
    (this as any)[key] = value;
  };
}
