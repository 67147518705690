import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { BR } from "../../../../../content/components/linebreak";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";

type OwnProps = PageContentProps &
  WithStyles<"root" | "wrapper" | "background" | "mobile" | "desktop">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#000",
    color: "#fff",
    display: "flex",
    backgroundImage: "url('/article/images/finger.jpg')",
    backgroundPosition: "left center",
    justifyContent: "center",
  },
  mobile: {
    backgroundPosition: "10% center",
    backgroundSize: "cover",
  },
  desktop: {
    paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
    paddingRight: `calc(${theme.spacing(8)}px + 5vw)`,
  },
  background: {
    // opacity: 0.5,
    // backgroundImage:
    //   "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  wrapper: {
    marginTop: "5vw",
    maxWidth: 1800,
    width: "100%",
    zIndex: 2,
  },
});

export const A2P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  // const Layout = presenter.layout;
  // const content = presenter.contentData;
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.wrapper,
        mobile: classes.mobile,
        desktop: classes.desktop,
      }}
    >
      <ContentLayoutCover
        // {...content}
        category="Uit de praktijk"
        readTime="2,5 min"
        title={
          <Typography variant="h1">
            Ontslag na <BR />1 januari 2020
          </Typography>
        }
        subtitle=""
        authors={{
          image: "/article/images/jaq.png",
          credits: [
            { credit: "Tekst", name: "Jacqueline Choufoer-van der Wel" },
          ],
        }}
        description={
          <Typography variant="h4">
            Het is je vast niet ontgaan dat vanaf <BR />
            1 januari 2020 de Wet <BR />
            normalisering rechtspositie <BR />
            ambtenaren (Wnra) wordt <BR />
            ingevoerd.
          </Typography>
        }
        size="big"
      />
    </LayoutDefault>
  );
});
