import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { LayoutAnimator } from "../../../../layout/layout-animator";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  inner: {
    maxWidth: "calc(630px + 5vw)",
  },
});

export const NoaHolleman7 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile,
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography variant="h5" className={classes.montserrat} paragraph>
                Op een manshoog posterboard bij de personeelsingang van jouw
                kantoor staat geschreven: ‘De Belastingdienst maken we samen.
                Wat draag jij bij?’ Kan jij vertellen wat jouw
                (samenwerkings)bijdrage is?
              </Typography>
              <Typography paragraph>
                ‘Omdat veel collega’s met pensioen gaan, is het belangrijk dat
                hier nieuwe medewerkers voor in de plaats komen. Door starters
                op de arbeidsmarkt aan te nemen en op te leiden wordt de
                stabiliteit van de Belastingdienst gewaarborgd. Ik ben blij dat
                ik de Belastingdienst hierbij kan ondersteunen.’
              </Typography>
              <Typography variant="h5" className={classes.montserrat} gutterBottom>
                Tot slot
              </Typography>
              <Typography paragraph>
                De jeugd heeft de toekomst. Dat geldt ook voor onze NCF. Het
                bestuur is heel blij met de constante toestroom van nieuwe jonge
                leden. Een evenwichtige leeftijdsopbouw van het ledenbestand
                zorgt er namelijk voor dat ieder lid zich vertegenwoordigd én
                gehoord kan voelen. Samen - jong én oud - staan we sterk! <EndOfArticle/>
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
