export const page6 = {
  id: "c1e403e9-50bf-4680-8567-12b5dfa94b05",
  slug: "page-2",
  title: "Page 2",
  description: "Some page description",
  content: "Article 2, page 2",
  type: "article",
  overlay: "linear-gradient(to right, rgba(120,102,121,1) 0%,rgba(43,36,43,0.1) 100%)",
  background: `url("/media/images/a4blurredbackground.png") center`
};
