import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "wrapper"
    | "background"
    | "title"
    | "smallTitle"
    | "gridItem"
    | "maxWidth"
    | "mobile"
    | "montserrat"
    | "desktop"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundImage:
      "url('/article/images/verruiming-bedrijfsfitness_cover.png')",
    backgroundRepeat: "no-repeat",
    backgrouneSize: "cover",
    backgroundPosition: "75% 75%",

    color: "white"
  },
  mobile: {},
  desktop: {
    backgroundPosition: "75% 100%",
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "0 50%"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  title: {
    fontFamily: "Domaine",
    fontWeight: 500
  },
  link: {
    color: "inherit"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  wrapper: {
    // width: `calc(50% - ${theme.spacing(7)}px)`
    width: "100%",
    maxWidth: "calc(1400px + 5vw)",
    padding: theme.spacing(0, 2)
  }
});

export const VerruimingBedrijfsfitnessP1 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
          desktop: classes.desktop
        }}
      >
        <ContentLayoutCover
          category="Vakbondsinfo"
          readTime="3 min"
          title={
            <Typography
              variant="h2"
              gutterBottom
              className={classes.montserrat}
            >
              Verruiming <BR />
              bedrijfs&shy;fitness
            </Typography>
          }
          authors={{
            image: "/article/images/angelique.png",
            credits: [{ credit: "Tekst", name: "Angelique Kansouh" }]
          }}
          subtitle={
            <Typography paragraph variant="h5">
              Het uithoudingsvermogen van de <BR />
              vakbond wordt getest.
            </Typography>
          }
          description={
            <Typography>
              Wat heeft het bevorderen van jouw gezondheid te maken met het{" "}
              <BR />
              mogelijk afschaffen van jouw niet-werkbare dag na Hemelvaart?
            </Typography>
          }
        />
      </LayoutDefault>
    );
  }
);
