import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "wrapper" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#EDD0D2",
    display: "flex",
    justifyContent: "center",
    color: "#4a4a4a"
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  wrapper: {
    maxWidth: "calc(500px + 5vw)"
  }
});

export const A8P5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCentered
        textAlign="left"
        media={
          <TextBoard
            image="/article/images/vent.png"
            color="#528CB2"
            titleColor="white"
            title={
              <>
                <Typography variant="h5">4/6 Wist je dat...?</Typography>
              </>
            }
            subtitle={
              <Typography variant="h5" style={{ fontStyle: "italic" }}>
                ...2019 het laatste jaar is dat je <BR />
                met 57 jaar met de PAS kan <BR />
                gaan?
              </Typography>
            }
          >
            <Typography paragraph>
              Vanaf 2020 is de minimumleeftijd 58 jaar.
            </Typography>
          </TextBoard>
        }
        size="small"
      />
    </LayoutDefault>
  );
});
