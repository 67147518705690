import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { TemplateModel, Template, TemplateData } from "./template";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";

import { subscribe } from "../../network/decorators/graphql-subscribe";
import { TemplateCreated, TemplateUpdated, TemplateDeleted } from "./graphql/subscriptions";
import { CreateTemplate, UpdateTemplate, DeleteTemplate } from "./graphql/mutations";
import { QueryTemplate } from "./graphql/queries";

export interface TemplateProvider extends GraphQLProviderProps<Template, TemplateData> {
  fetchAll(): Promise<void>;
}

export interface TemplateValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultTemplateProvider extends GraphQLProvider<Template, TemplateData>
  implements TemplateProvider {
  public model = TemplateModel;

  protected serializers: RecordSerializers<TemplateData> = {
    values: JSONSerializer
  };

  public async fetchAll() {}

  @subscribe(TemplateCreated) templateCreated = (template: Template) => {
    return template;
  };

  @subscribe(TemplateUpdated) templateUpdated = (template: Template) => {
    return template;
  };

  @subscribe(TemplateDeleted) templateDeleted = (template: Template) => {
    return template;
  };

  public createOperation(template: TemplateData) {
    return graphqlOperation(CreateTemplate, { input: template });
  }

  public fetchOperation(template: TemplateData) {
    return graphqlOperation(QueryTemplate, { id: template.id });
  }

  public updateOperation(template: TemplateData) {
    return graphqlOperation(UpdateTemplate, { input: template });
  }

  public deleteOperation(template: TemplateData) {
    return graphqlOperation(DeleteTemplate, { id: template.id });
  }
}

// export function templateUsersOptions(template: any) {
//   return {
//     model: UserModel,
//     operation: graphqlOperation(QueryMagazineUsers, { id: template.id })
//   };
// }
