import { ArticleFragment } from "./fragments";

export const QueryArticle = `
  query Article($id:ID!) {
    Article(id:$id){
      ${ArticleFragment}
    }
  }
`;

export const QueryArticleCollection = `
  query ArticleCollection {
    ArticleCollection{
      ${ArticleFragment}
    }
  }
`;
