import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { LayoutAnimator } from "../../../../layout/layout-animator";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    backgroundImage: "url('/article/images/noa_holleman-4.png')",
    backgroundSize: "120%",
    display: "flex",
    color: "#fff",
    backgroundPosition: "100% 40%",
  },
  mobile: {
    backgroundImage: "none",
    "& $inner": {
      maxWidth: "unset",
      marginLeft: "initial",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  inner: {
    maxWidth: "calc(360px + 5vw)",
    marginLeft: "10vw",
  },
});

export const NoaHolleman4 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile,
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography
                variant="h5"
                className={classes.montserrat}
                gutterBottom
              >
                Welke functie binnen de Belastingdienst spreekt jou het meest
                aan?
              </Typography>
              <Typography paragraph>
                ‘Binnen de Belastingdienst zijn er ontzettend veel mogelijkheden
                en kansen. Ik ga eerst ervaring opdoen met het uitvoeren van
                controles op de afdeling Grote Ondernemingen. Een aantal
                collega’s sprak vol enthousiasme over de afdeling waar zij
                werken. Daarbij gaven zij aan dat zij mij later graag op die
                afdeling als collega zouden zien. Wellicht gebeurt dat ooit.’
              </Typography>
              <Typography
                variant="h5"
                className={classes.montserrat}
                gutterBottom
              >
                Wat maakte dat je lid werd van de NCF?
              </Typography>
              <Typography paragraph>
                ‘Ongeveer anderhalf jaar geleden ben ik lid van de NCF geworden.
                Het is van belang dat je op iemand terug kan vallen als er
                rechtspositionele conflicten zijn. Ik zie het als een
                brandverzekering voor een woonhuis. Je hoopt dat je nooit
                aanspraak hoeft te maken op deze verzekering, maar het is een
                fijn gevoel dat je ergens op terug kunt vallen in geval van
                calamiteiten.’
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
