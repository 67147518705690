import { MagazineFragment } from "./fragments";

export const MagazineCreated = `
subscription {
  magazineCreated {
    ${MagazineFragment}
  }
}
`;

export const MagazineUpdated = `
subscription {
  magazineUpdated {
    ${MagazineFragment}
  }
}
`;

export const MagazineDeleted = `
subscription {
  magazineDeleted {
    ${MagazineFragment}
  }
}
`;
