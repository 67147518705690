import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCover } from "../../../../layout/content-layout-cover";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "wrapper"
    | "background"
    | "montserrat"
    | "domaine"
    | "mobile"
    | "desktop"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#a8a7d0",
    backgroundImage: "url('/article/images/a5cover-gradient.png')",
    backgroundSize: "cover",
    backgroundPosition: "20% center",
    backgroundRepeat: "no-repeat",
    color: "#fff",
    display: "flex",
  },
  desktop: {
    justifyContent: "flex-end",
    [theme.breakpoints.up("lg")]: {
      backgroundPosition: "left center",
    },
  },
  background: {
    // background: "linear-gradient(271deg, #161D51 0%, #141E5383 100%) 50% 50%",

    opacity: 0.76,
    mixBlendMode: "multiply",
  },
  mobile: {
    // padding: theme.spacing(2),
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundPosition: "left center",
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  domaine: {
    fontFamily: "Domaine",
  },
  wrapper: {
    [theme.breakpoints.up("md")]: {
      width: `calc(50% - ${theme.spacing(7)}px)`,
    },
  },
});

export const A5P1 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  // const Layout = presenter.layout;
  // const content = presenter.contentData;
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        desktop: classes.desktop,
        inner: classes.wrapper,
        mobile: classes.mobile,
      }}
    >
      {/* <Layout {...content} /> */}
      <ContentLayoutCover
        category="Interview"
        readTime="4,5 min"
        title={
          <Typography
            variant="h1"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Kennis maken met...
          </Typography>
        }
        subtitle={
          <Typography paragraph variant="h6">
            Interview met Sjoerd Draaisma
          </Typography>
        }
        layout="left"
        authors={{
          image: "/article/images/donk.png",
          credits: [
            { credit: "Tekst", name: "Ad van der Donk" },
            { credit: "Beeld", name: "Paul Heijne" },
          ],
        }}
        description={
          <Typography>
            Op de website van de NCF staat vermeld: ‘We zijn een bond <BR />
            die staat! Al meer dan 125 jaar. Duizenden collega's <BR />
            bij het ministerie van Financiën hebben zich al bij de <BR />
            NCF aangesloten. “Voor leden, door leden” is het motto. <BR />
            Jouw belang is ons belang. We doen dit met volle energie en <BR />
            met gemotiveerde mensen.’
          </Typography>
        }
      />
    </LayoutDefault>
  );
});
