export const page1 = {
  id: "31a2c0c3-4d71-416b-b3f4-9c1fcf5f9ab4",
  slug: "voorwoord",
  title: "Voorwoord",
  description: "Some page description",
  content: {
    size: "small",
    animation: "trickle",
    title: {
      variant: "h2",
      style: { fontWeight: 500, marginTop: 0 },
      content: "Voorwoord"
    },
    subtitle: {
      variant: "body1",
      content:
        "Albert van der Smissen, voorzitter van de NCF, vertelt over de overgang \nvan een magazine op papier naar een interactief magazine."
    },
    media: {
      maxWidth: "630px",
      embed: "https://www.youtube.com/embed/ZPBqdOb0nEo",
      caption: "Albert van der Smissen, voorzitter van de NCF"
    }
  },
  type: "article",
  overlay: "linear-gradient(to right, rgba(51,38,20,0.85) 0%,rgba(201,160,81,0.4) 100%)",
  layout: "ContentLayoutCentered"
};
