export const page3 = {
  id: "8c6871d7-c20d-4399-9734-da60d558ab94z",
  slug: "page-3",
  title: "Page 3",
  description: "Some page description",
  content: "Article 2, page 3",
  type: "article",
  filter: "blur(10px)",
  overlay: "linear-gradient(to right, rgba(18,102,128,1) 0%,rgba(19,115,145,0) 61%,rgba(20,124,156,0) 100%)"
};
