import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { LayoutAnimator } from "../../../../layout/layout-animator";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset",
    },
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  inner: {
    maxWidth: "calc(900px + 5vw)",
  },
});

export const NoaHolleman3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile,
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography variant="h5" className={classes.montserrat} gutterBottom>
                Welke impact heeft Switch op jouw werk? Veel oudere, zeer
                ervaren collega’s verlaten de Belastingdienst.
              </Typography>
              <Typography paragraph>
                ‘Op kantoor merk ik dat veel ervaren collega’s zijn vertrokken
                of de komende tijd nog gaan vertrekken via de Switch-regeling.
                Relatief nieuwe medewerkers, zoals ik, hadden nog veel kunnen
                leren van deze ervaren collega’s. Verder zijn er gelukkig genoeg
                andere collega’s waar ik van kan leren. Het is van belang dat de
                dienstleiding de benodigde kennis en capaciteit herstelt door
                interne opleidingen aan te bieden en extra medewerkers aan te
                nemen.’
              </Typography>
              <Typography variant="h5" className={classes.montserrat} gutterBottom>
                Wanneer is een werkdag voor jou geslaagd?
              </Typography>
              <Typography>
                ‘Of een werkdag voor mij geslaagd is hangt af van verschillende
                aspecten. Ik wil iedere dag graag een stapje vooruitgaan met
                mijn werkzaamheden. Hierbij vind ik het belangrijk dat ik elke
                dag iets nieuws leer of ervaar. Daarnaast vind ik het fijn om
                erkenning te krijgen voor mijn werkzaamheden en om goed contact
                met collega’s te hebben. Gelukkig heb ik tot op heden meestal
                een geslaagde werkdag.’
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
