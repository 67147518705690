export const page4 = {
  id: "a413ca2f-f4b5-4ef5-95bd-2ef043d243d7",
  slug: "page-4",
  title: "Page 4",
  description: "Some page description",
  content: "Article 2, page 4",
  type: "article",
  background: "#786679",
  color: "#4a4a4a"
};
