import { IPresenter } from "../../helpers/with-presenter";
import { MagazineInteractor } from "../../application/data/magazine/magazine-interactor";
import { observable } from "mobx";
import { ArticleInteractor } from "../../application/data/article/article-interactor";
import { routes, RouteInfo } from "../../config/routes";
import { RouteComponentProps } from "react-router";
import { Article, ArticlePage } from "../../application/data/article/article";
import { Magazine, EditionData } from "../../application/data/magazine/magazine";
import { BasicPagePresenter } from "./_page-basic-presenter";
import { createRef } from "react";

export class PagePresenter extends BasicPagePresenter implements IPresenter {
  @observable public page: RouteInfo;
  @observable public loading: boolean = true;
  @observable public article: Article | undefined = undefined;
  @observable public edition: EditionData | undefined = undefined;
  @observable public magazine: Magazine | undefined = undefined;
  @observable public currentPage: ArticlePage | undefined = undefined;
  @observable public currentPageRef: React.RefObject<HTMLDivElement>;

  constructor(
    protected _magazineInteractor: MagazineInteractor,
    protected _articleInteractor: ArticleInteractor,
    _router: RouteComponentProps
  ) {
    super(_router);
    this.page = routes[this._router.match.path];
    this.setup();
    this.currentPageRef = createRef();
  }

  public setup = async () => {
    this.loading = true;
    const params = this._router.match.params;

    await this._magazineInteractor.select("39178448-2935-45c9-be79-d2f8cbd6e37a", false);

    if (params.edition) {
      await this._magazineInteractor.selectEdition(params.edition, false);
    } else {
      this._magazineInteractor.selectedEdition = undefined;
    }

    if (params.article) {
      await this._articleInteractor.selectArticle(params.article, false);
      this.article = this._articleInteractor.selectedArticle;
    } else {
      this._articleInteractor.selectedArticle = undefined;
    }

    if (params.page) {
      await this._articleInteractor.selectPage(params.page, false);
    } else {
      if (this.article && this.article.pages.length) {
        await this._articleInteractor.selectPage(this.article.pages[0].id);
      }
    }

    this.currentPage = this._articleInteractor.selectedPage;
    this.magazine = this._magazineInteractor.selected;
    this.edition = this._magazineInteractor.selectedEdition;

    this.loading = false;
  };
}
