import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";

import { data } from "../../network/decorators/graphql-data";
import { CSSProperties } from "@material-ui/styles";

export interface ArticlePage {
  id: string;
  title: string;
  content: any;
  background?: string;
  type: string;
  filter?: string;
  overlay?: string;
  color?: string;
  layout?: string;
}

export interface ArticleData {
  id: string;
  index: string;
  title: string;
  description: string;
  content: string;
  slug: string;
  image: string;
  backgroundImage: string;
  pages: ArticlePage[];
  type: string;
  color?: string;
  featuredImage?: string;
  style?: CSSProperties;
}

export interface Article extends GraphQLModel<ArticleData>, ArticleData {}

export interface ArticleValues {}

export class ArticleModel extends GraphQLBase<ArticleData> implements Article {
  typename = "Article";

  @data public title = "";
  @data public content = "";
  @data public index = "";
  @data public slug = "";
  @data public image = "";
  @data public backgroundImage = "";
  @data public description = "";
  @data public config = "";
  @data public pages = [];
  @data public type = "";
  @data public color = "";
  @data public featuredImage = "";
  @data public style = {};
}
