import * as React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { WithStyles, Theme, withStyles, Typography } from "@material-ui/core";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyleRules } from "@material-ui/styles";
import { mapEvent } from "../../../helpers/formatters";
import { ArticlePresenter } from "../../pages/article-presenter";
// import { faCircle } from "@fortawesome/free-solid-svg-icons"

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    WebkitTapHighlightColor: "#0000"
  },
  bulletWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  bullet: {
    marginTop: "0.75em"
  },
  contentWrapper: {
    display: "flex"
  },
  title: {
    color: "#FFC583",
    textTransform: "uppercase",
    marginBottom: theme.spacing(0.5) + "px !important",
    marginLeft: "2em !important",
    marginTop: theme.spacing(0) + "px !important",
    fontFamily: "Montserrat",
    fontWeight: 600
  },
  content: {
    paddingLeft: theme.spacing(1),
    lineHeight: 1.25,
    cursor: "pointer",
    "&:hover": {
      "& *": {
        color: "#f9c583"
      }
    },
    "& *": {
      transition: "color 0.2s ease",
      color: "#fff"
    }
  }
});

type CarouselItem = {
  title: string;
  content: any;
  link?: any;
};

interface OwnProps
  extends WithStyles<
    "root" | "bulletWrapper" | "bullet" | "title" | "content" | "contentWrapper"
  > {
  data: CarouselItem[];
  presenter?: ArticlePresenter;
}

@observer
class CarouselComponent extends React.Component<OwnProps> {
  private interval = 3000;
  @observable private index: number = 0;

  private next() {
    const { data } = this.props;
    this.index + 1 < data.length ? this.index++ : (this.index = 0);
  }

  componentDidMount() {
    setInterval(() => this.next(), this.interval);
  }

  render() {
    const { data, classes, presenter } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title}>
          {data[this.index].title}
        </Typography>
        <div className={classes.contentWrapper}>
          <div className={classes.bulletWrapper}>
            {data.map((item, index) => {
              return (
                <FontAwesomeIcon
                  key={index}
                  color="#ffc583"
                  icon={this.index === index ? faDotCircle : faCircle}
                  className={classes.bullet}
                />
              );
            })}
          </div>
          <div
            onClick={
              presenter &&
              data[this.index].link &&
              mapEvent(presenter.selectArticle, data[this.index].link)
            }
            className={classes.content}
          >
            <Typography variant={"body2"} style={{ margin: 0 }}>
              {data[this.index].content}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export const Carousel = withStyles(styles)(CarouselComponent);
