import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";
import { page6 } from "./pages/page-6";
import { page7 } from "./pages/page-7";

export const noaHolleman: ArticleData = {
  id: "ac5d198e-c405-422a-bff4-4848f7d5d882",
  slug: "noa-holleman",
  index: "2",
  title: "Noa Holleman",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  style: {
    backgroundColor: "#597889"
  },
  image: "/article/images/noa_holleman-thumb.png",
  content: "",
  pages: [page1, page2, page3, page4, page5, page6, page7],
  type: "article"
};
