import * as React from "react";
import { Theme, Typography } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { AnimationWrapper } from "../../../../../interface/partials/animation-wrapper";
import { LayoutAnimator } from "../../../../layout/layout-animator";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "inner" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#2E3878",
    display: "flex",
    justifyContent: "center",
    color: "#fff"
  },
  mobile: {
    "& $inner": {
      maxWidth: "unset",
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  inner: {
    maxWidth: "calc(550px + 5vw)"
  }
});

export const VerruimingBedrijfsfitnessP2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.inner,
          mobile: classes.mobile
        }}
      >
        <div>
          <LayoutAnimator animation="trickle">
            <AnimationWrapper>
              <Typography variant="body1" paragraph>
                Zo begon het Bondig-artikel van juni 2017 (pag. 16). Niets, zou
                je denken. Toch denkt de dienst daar anders over. Dit geldt aan
                de vooravond van 2020 nog steeds.
              </Typography>
              <Typography variant="h5" className={classes.montserrat} paragraph>
                Wat is er aan de hand?
              </Typography>
              <Typography paragraph>
                Velen van jullie hebben ons benaderd over een uitbreiding van
                het aantal sportscholen waarvoor via IKAP (en per 1 januari 2020
                via het Individueel Keuzebudget) een belastingvrije vergoeding
                aangevraagd kan worden.
              </Typography>
              <Typography>
                In het begin waren er fiscale beperkingen, maar deze beperkingen
                voor een ruime keuzelijst bedrijfsfitnesscentra en sportscholen
                zijn enkele jaren geleden vervallen. Andere departementen hebben
                al een uitgebreide sportscholenlijst, maar de Belastingdienst
                wil hier niet zonder meer aan meewerken. Gelukkig heeft de
                dienst het uithoudingsvermogen van de vakbonden goed getraind.
                Opnieuw dus het verzoek aan de dienst om de ‘schrale’ sportlijst
                uit te breiden. Klinkt niet zo moeilijk: een appel-eitje geval.
                Of toch niet?
              </Typography>
            </AnimationWrapper>
          </LayoutAnimator>
        </div>
      </LayoutDefault>
    );
  }
);
