import { GraphQLResolveInfo } from "graphql";
import { bondigArticle1 } from "../../../data/article/__mock__/bondig-51/bondig-article-1/article";
import { bondigArticle2 } from "../../../data/article/__mock__/bondig-51/bondig-article-2/article";
import { bondigArticle3 } from "../../../data/article/__mock__/bondig-51/bondig-article-3/article";
import { bondigArticle4 } from "../../../data/article/__mock__/bondig-51/bondig-article-4/article";
import { bondigArticle5 } from "../../../data/article/__mock__/bondig-51/bondig-article-5/article";
import { bondigArticle6 } from "../../../data/article/__mock__/bondig-51/bondig-article-6/article";
import { bondigArticle7 } from "../../../data/article/__mock__/bondig-51/bondig-article-7/article";
import { bondigArticle8 } from "../../../data/article/__mock__/bondig-51/bondig-article-8/article";
import { bondigVoorwoord } from "../../../data/article/__mock__/bondig-51/bondig-voorwoord/article";
import { bondigWinnaars } from "../../../data/article/__mock__/bondig-51/bondig-winnaars/article";
import { bondigPoll } from "../../../data/article/__mock__/bondig-51/bondig-poll/article";
import { bondigPensioenpraat } from "../../../data/article/__mock__/bondig-51/bondig-pensioenpraat/article";
import { bondigArticleFotograafGezocht } from "../../../data/article/__mock__/bondig-51/fotograaf-gezocht/article";
import { bondigBestuursverkiezing } from "../../../data/article/__mock__/bondig-51/bondig-bestuursverkiezing/article";
import { bondigVerruimingBedrijfsfitness } from "../../../data/article/__mock__/bondig-51/bondig-verruiming-bedrijfsfitness/article";
import { cover } from "../../../data/article/__mock__/bondig-52/cover/article";
import { noaHolleman } from "../../../data/article/__mock__/bondig-52/noa_holleman/article";
import { china } from "../../../data/article/__mock__/bondig-52/china/article";
import { rechtzaak } from "../../../data/article/__mock__/bondig-52/rechtzaak/article";
import { ziekZijn } from "../../../data/article/__mock__/bondig-52/ziek_zijn/article";
import { transitievergoeding } from "../../../data/article/__mock__/bondig-52/transitievergoeding/article";
import { voorwoord } from "../../../data/article/__mock__/bondig-52/voorwoord/article";
import { wistJeDat } from "../../../data/article/__mock__/bondig-52/wist_je_dat/article";

export const mockArticles = () => {
  return [
    bondigArticle1,
    bondigVoorwoord,
    bondigPensioenpraat,
    bondigArticle2,
    bondigArticle3,
    bondigArticle4,
    bondigArticle5,
    bondigArticle6,
    bondigArticleFotograafGezocht,
    bondigBestuursverkiezing,
    bondigVerruimingBedrijfsfitness,
    bondigArticle7,
    bondigWinnaars,
    bondigArticle8,
    bondigPoll,
    cover,
    noaHolleman,
    china,
    rechtzaak,
    ziekZijn,
    transitievergoeding,
    voorwoord,
    wistJeDat
  ];
};

export const QueryArticleResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  const result = mockArticles().filter((article: any) => {
    return article.id === args.id;
  });

  return result[0];
};

export const QueryArticleCollectionResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  const result = mockArticles().filter((article: any) => {
    return article.id;
  });

  return result;
};
