import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { Media } from "../../../../../interface/partials/media";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
  },
  gridItem: {
    padding: "1vw"
  },
  contentWrapper: {
    width: "100%",
    maxWidth: 1500,
  },
  image: {
    marginTop: "auto",
    width: "100%",
  },
  header: {
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
});

export const NoaHolleman6 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.contentWrapper }}
      >
        <ContentLayoutColumns size="big" animation="trickle">
          <ContentColumn>
            <Typography variant="h5" className={classes.header} gutterBottom>
              Welk werk binnen de Belastingdienst heeft op dit moment je volle
              aandacht?
            </Typography>
            <Typography paragraph>
              ‘Op dit moment ben ik bezig met mijn eerste controles, deze voer
              ik steeds meer zelfstandig uit. Het is een bijzonder leerzaam
              traject. Ik vind het leuk om te zien dat ik veel kennis die ik in
              de interne opleiding heb opgedaan, kan toepassen in de praktijk.
              Het is fijn dat mijn collega’s altijd bereid zijn om mij te helpen
              en te ondersteunen, zodat ik het opleidingstraject goed kan
              afronden.’
            </Typography>
            <Typography gutterBottom variant="h5" className={classes.header}>
              Is het boekenonderzoek als handhavingsinstrument nog van deze
              tijd?
            </Typography>
            <Typography>
              ‘Het boekenonderzoek neemt een belangrijke plaats in binnen het
              geheel van handhavingsactiviteiten en handhavingsinstrumenten. In
              de toekomst zal het boekenonderzoek ook zeker belangrijk blijven.
            </Typography>
          </ContentColumn>
          <ContentColumn style={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              Er gaan namelijk nog veel zaken bewust én onbewust mis in
              de aangiften van onze klanten. We moeten onze aandacht aan de
              repressieve kant onverminderd op de belastingplichtigen blijven
              richten. Ik vind het belangrijk dat de Belastingdienst wordt
              gezien door de maatschappij. Dat bevordert de compliance, waardoor
              de belastingplichtigen de belastingaangiften zorgvuldiger
              invullen. We dienen de controlecapaciteit hiervoor naar mijn
              mening uit te breiden.’
            </Typography>
            <Media
              className={classes.image}
              image={"/article/images/noa_holleman-6.png"}
              caption="Noa Holleman, ons jongste NCF lid"
            ></Media>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
