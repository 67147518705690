import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn8 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography paragraph>
                Door deze verzekering wordt, afhankelijk van de dekking, de
                salaristerugval beperkt tot 80-85%. Het hele tweede jaar ontvang
                je dezelfde inkomsten. Voor alle in het tweede ziektejaar
                gewerkte uren moet het salaris tot 100% aangevuld worden.
              </Typography>
              <Typography>
                90 weken na de eerste ziekmelding, verandert er weer iets
                belangrijks. De keuring bij het UWV wordt aangevraagd door de
                werknemer. Het UWV gaat vaststellen of de
                re-integratieverplichtingen voldoende waren en of het dossier
                voldoet aan de normen van de Wet Poortwachter. Daarna wordt door
                de keuringsarts onderzocht of de werknemer al dan niet duurzaam
                arbeidsongeschikt is. Het inkomen over de uren ontvang je dan
                niet meer van je werkgever maar van de uitkeringsinstantie UWV.
                Je inkomen blijft overigens wel ongeveer hetzelfde als je voor
                80 tot 100% wordt afgekeurd.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography paragraph>
                90 weken na de eerste ziekmelding, verandert er weer iets
                belangrijks. De keuring bij het UWV wordt aangevraagd door de
                werknemer. Het UWV gaat vaststellen of de
                re-integratieverplichtingen voldoende waren en of het dossier
                voldoet aan de normen van de Wet Poortwachter. Daarna wordt door
                de keuringsarts onderzocht of de werknemer al dan niet duurzaam
                arbeidsongeschikt is. Het inkomen over de uren ontvang je dan
                niet meer van je werkgever maar van de uitkeringsinstantie UWV.
                Je inkomen blijft overigens wel ongeveer hetzelfde als je voor
                80 tot 100% wordt afgekeurd.
              </Typography>
              <Typography variant="h5" gutterBottom className={classes.header}>
                1%-regeling
              </Typography>
              <Typography>
                ‘Een situatie die wij regelmatig in de praktijk tegenkomen is de
                zogenaamde 1%-regeling’,
              </Typography>
            </ContentColumn>
            <ContentColumn>
              <Typography paragraph>
                legt Piet uit. ‘Deze komt ter sprake als de voorheen
                arbeidsongeschikte medewerker in de praktijk weer volledig aan
                het werk is, voor 36 uur per week. Zijn werkgever meldt hem niet
                voor 100% hersteld, maar voor 99%. De medewerker blijft dan 1%
                arbeidsongeschikt.
              </Typography>
              <Typography paragraph>
                Vaak krijgt de medewerker dan te horen dat zijn werkgever dit
                doet om zo nog een vinger aan de pols te houden. Op deze manier,
                zo legt de werkgever dit uit, kunnen we de voorheen
                arbeidsongeschikte medewerker blijven monitoren, want deze zou
                wel eens opnieuw arbeidsongeschikt kunnen worden.
              </Typography>
              <Typography>
                Maar met die 1%-regeling ontneem je de medewerker de
                mogelijkheid om zijn compensatie-uren op te bouwen. Hij is
                immers op papier niet volledig aan de slag, terwijl dat in de
                praktijk wel zo is, een voorwaarde om compensatie-uren op te
                bouwen.’
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
