import * as React from "react";
import { Theme, Typography, Link } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    "root" | "image" | "title" | "link" | "montserrat" | "wrapper" | "mobile"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#EDD0D2",
    display: "flex",
    justifyContent: "center",
    color: "#4a4a4a"
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center"
    }
  },
  image: {
    margin: "auto",
    width: "100%"
  },
  montserrat: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  wrapper: {
    maxWidth: "calc(500px + 5vw)"
  }
});

export const A8P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        inner: classes.wrapper,
        mobile: classes.mobile
      }}
    >
      <ContentLayoutCentered
        textAlign="left"
        media={
          <TextBoard
            image="/article/images/vent.png"
            color="#528CB2"
            titleColor="#fff"
            title={
              <>
                <Typography variant="h5">2/6 Wist je dat...?</Typography>
              </>
            }
            subtitle={
              <Typography variant="h5" style={{ fontStyle: "italic" }}>
                ...de NCF ‘Werken aan jouw <BR />
                toekomst’ belangrijk vindt?
              </Typography>
            }
          >
            <Typography paragraph>
              De NCF biedt je daarom via het A&amp;O Fonds Rijk allerlei korte
              trainingen aan om je te sterken in je gesprek met je
              leidinggevende bijvoorbeeld. Maar de NCF wil je ook actief laten
              nadenken over je eigen mogelijkheden, wensen en behoeften in je
              werk. Meer info? Kijk{" "}
              <Link target="_blank" href="https://werkenaanjouwtoekomst.nl/">
                hier
              </Link>
              .
            </Typography>
          </TextBoard>
        }
        size="small"
      />
    </LayoutDefault>
  );
});
