import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles  , StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps & WithStyles<"root" | "image">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#141853",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    maxWidth: "100%",
    display: "flex",
    margin: "20px auto",
    [theme.breakpoints.up("md")]: {
      margin: "0 50px",
      marginTop: "-100px"
    }
  }
});

export const A5P7 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="closeIn">
        <ContentColumn style={{ maxWidth: 500 }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Wat wens je de NCF toe?  
          </Typography>

          <Typography paragraph>
            ‘Het mooie van de NCF vind ik dat alles van, door, voor en met
            belasting- en douaneambtenaren is. Wel vind ik dat we weer actiever
            moeten zijn in de voorlichting en ledenwerving. Bijvoorbeeld met een
            nóg zichtbaarder aanspreekpunt per kantoor. De actie voor 1 cent
            onder de 30 jaar vind ik persoonlijk niet zo geslaagd. Een mooie
            kortingsregeling voor élk nieuw lid zou beter passen.’
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            En ten slotte, een advies aan de dienstleiding?
          </Typography>

          <Typography paragraph>
            ‘Dat zul je al vaker gehoord hebben, denk ik: luister naar de
            werkvloer en dóe iets met de adviezen. En geef B’ers en C’ers een
            reële kans op een toekomst bij de Belastingdienst!’ <EndOfArticle />
          </Typography>
        </ContentColumn>
        <ContentColumn style={{ maxWidth: 650 }}>
          <img
            className={classes.image}
            src="/article/images/tnvs.png"
            alt=""
          />
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
