import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArticlePresenter } from "../../article-presenter";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { Button, Theme } from "@material-ui/core";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { withStyles, WithStyles, StyleRules } from "@material-ui/styles";
import { Error404Content } from "../../error-404";

const styles = (theme: Theme): StyleRules => ({
  root: {
    "@media screen": {
      minHeight: "100vh",
      padding: theme.spacing(4, 2, 8, 2),
      backgroundColor: "#eee",
    },
  },
  container: {
    "@media screen": {
      position: "relative",
      margin: "auto",
      maxWidth: 900,
    },
  },
  button: {
    margin: theme.spacing(0, 0, 0, 1),

    "@media print": {
      display: "none",
    },
  },
  content: {
    fontSize: "0.4rem",
    "& h1, & h2, & h3, & h4, & h5, & h6": { margin: "1em 0 0.5em 0" },
    "& p": { margin: "0.5em 0 1.2em 0", lineHeight: 1.5, fontSize: 13 },
    "& br": { display: "none" },
    "& img": { display: "none" },
    color: "black",

    "@media screen": {
      padding: 48,
      backgroundColor: "#fff",
      boxShadow: theme.shadows[3],
    },
  },
  nav: {
    position: "fixed",
    padding: theme.spacing(2),
    right: 0,
    top: 0,
  },
});

interface PrintContentProps
  extends WithStyles<"root" | "container" | "button" | "content" | "nav"> {
  presenter: ArticlePresenter;
}

export const ArticlePrintContent = withStyles(styles)(
  observer(({ classes, presenter }: PrintContentProps) => {
    if (!presenter.article || !presenter.article.pages.length) {
      return <Error404Content />;
    }

    return (
      <div className={classnames(classes.root)}>
        <div className={classes.container}>
          <div className={classes.nav}>
            <Button
              className={classes.button}
              onClick={() => {
                window.print();
              }}
              variant="contained"
              color="primary"
            >
              Afdrukken
              <FontAwesomeIcon style={{ marginLeft: "10px" }} icon={faPrint} />
            </Button>
          </div>
          <div className={classes.content}>
            {presenter.article.pages.map((page, index) => {
              const content = presenter.content_by_id(page.id);
              return content ? (
                <content.component presenter={presenter} key={index} />
              ) : (
                <></>
              );
            })}
          </div>
        </div>
      </div>
    );
  })
);
