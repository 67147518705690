import createSpacing from "@material-ui/core/styles/createSpacing";
import { ThemeStyle, TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

export const themeConfig = {
  spacing: 12,
  hyphens: "manual",
  typography: {
    body1: {
      fontSize: "calc(1rem + 0.1em + 0.15vw)",
      lineHeight: 1.66
    },
    body2: {
      fontSize: "calc(0.825rem + 0.1em + 0.15vw)",
      lineHeight: 1.66
    },
    h1: {
      fontSize: "calc(1rem + 3em)",
      lineHeight: 1,
      letterSpacing: "0.02em"
    },
    h2: {
      fontSize: "calc(1rem + 2.5em)",
      lineHeight: 1,
      letterSpacing: "0.02em"
    },
    h3: {
      fontSize: "calc(1rem + 1.25em)",
      lineHeight: 1.1,
      letterSpacing: "0.02em"
    },
    h4: {
      fontSize: "calc(1rem + 0.66em)",
      lineHeight: 1.2,
      letterSpacing: "0.02em"
    },
    h5: {
      fontSize: "calc(1rem + 0.5em)",
      lineHeight: 1.2,
      letterSpacing: "0.02em"
    },
    h6: {
      fontSize: "calc(1.1rem + 0.2em)",
      lineHeight: 1.2,
      letterSpacing: "0.02em",
      fontWeight: 400
    },
    subtitle1: {},
    subtitle2: {},
    caption: {
      fontSize: "calc(0.5rem + 0.1em + 0.15vw)",
      lineHeight: 1.33
    },
    overline: {},
    button: {
      textTransform: "none",
      fontSize: "calc(0.875rem + 0.1em + 0.05vw)"
    }
  }
};

export const spacing = createSpacing(themeConfig.spacing);
export const typography = (key: ThemeStyle) => {
  return themeConfig.typography[key] as TypographyStyleOptions;
};
