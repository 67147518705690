import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  removeFlex: {
    display: "inline"
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    display: "flex",
    margin: "0 auto",
  },
  inner: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "calc(600px + 5vw)",
      marginTop: "75px",
    },
  },
});

export const ZiekZijn10 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{ root: classes.root, inner: classes.inner }}
      >
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle" className={classes.removeFlex}>
            <img
              className={classes.image}
              alt=""
              src="/article/images/ziek_zijn-10.png"
            />
            <Typography>
              Als arbeidsongeschikte medewerker krijg je dan te maken met
              allerlei mensen die hun zakelijke maar ook hun menselijke kant
              laten zien. Mensen zoals de bedrijfsarts, de leidinggevende en de
              re-integratiemanager kunnen een belangrijke stempel drukken op het
              herstel en het re-integreren. Er zal altijd een spanningsveld zijn
              tussen het zo snel mogelijk aan het werk krijgen van de
              arbeidsongeschikte medewerker en zijn/haar herstel. In dit soort
              situaties is niet alleen het werk leidend maar ook de mens. En het
              is voor alle betrokken personen een uitdaging om hier zo goed
              mogelijk mee om te gaan. <EndOfArticle dark />
            </Typography>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
