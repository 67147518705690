import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { QandA } from "../../../../../interface/partials/qanda";
import classnames from "classnames";
import { RenderWhile } from "../../../../../content/components/render-while";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";

type OwnProps = PageContentProps &
  WithStyles<"root" | "bold" | "gridItem" | "montserrat">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center"
  },
  bold: {
    fontWeight: "bold"
  },
  montserrat: {
    fontFamily: "Montserrat"
  }
});

export const A6P3 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <QandA
        topHeader="Vraag"
        question={
          <Typography className={classnames(classes.montserrat, classes.bold)}>
            Voor het komend jaar staat in P-Direkt nog een (groot) aantal
            verlofuren dat ik - volgens het overzicht - moet opmaken in 2020.
            Vervalt ook deze verplichting en kan ik die uren ‘meenemen’ naar
            2020?
          </Typography>
        }
        bottomHeader="Antwoord"
        answer={
          <>
            <RenderWhile desktop mobile>
              <ContentLayoutColumns size="big" animation="trickle">
                <ContentColumn>
                  <Typography>
                    Jouw restant vakantie-uren 2015 en de bovenwettelijke
                    vakantie-uren die je over hebt uit 2016 tot en met 2019
                    worden op 1 januari 2020 omgezet in IKB-uren. Deze IKB-uren
                    worden automatisch toegevoegd aan jouw IKB-spaarverlof. Hier
                    hoef je dus niets voor te doen.
                  </Typography>
                </ContentColumn>
                <ContentColumn>
                  <Typography>
                    Let op: jouw compensatie-uren moeten wel opgenomen zijn voor
                    1 januari 2020. Compensatie-uren die je niet opneemt
                    vervallen wel.
                  </Typography>
                </ContentColumn>
              </ContentLayoutColumns>
            </RenderWhile>
            <RenderWhile print>
              <Typography>
                Jouw restant vakantie-uren 2015 en de bovenwettelijke
                vakantie-uren die je over hebt uit 2016 tot en met 2019 worden
                op 1 januari 2020 omgezet in IKB-uren. Deze IKB-uren worden
                automatisch toegevoegd aan jouw IKB-spaarverlof. Hier hoef je
                dus niets voor te doen. Let op: jouw compensatie-uren moeten wel
                opgenomen zijn voor 1 januari 2020. Compensatie-uren die je niet
                opneemt vervallen wel.
              </Typography>
            </RenderWhile>
          </>
        }
      />
    </LayoutDefault>
  );
});
