import * as React from "react";
import { Typography, LinearProgress, Theme } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";
import { AnimationWrapper } from "./animation-wrapper";

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 80
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  barRoot: {
    backgroundColor: "#fff5",
    height: 6
  },
  bar: {
    backgroundColor: "white"
  }
});

export const ProgressBar = withStyles(styles)(
  ({ classes, subject, total, current }: any) => {
    return (
      <div className={classes.root}>
        <AnimationWrapper animation="fadeUp" baseTimeout={500}>
          <Typography
            gutterBottom
            className={classes.montserrat}
            align="center"
          >
            {subject} {current}/{total}
          </Typography>

          <LinearProgress
            variant="determinate"
            value={(current / total) * 100}
            classes={{ bar: classes.bar, root: classes.barRoot }}
          />
        </AnimationWrapper>
      </div>
    );
  }
);
