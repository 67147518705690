import * as React from "react";
import { WithStyles, Theme, Typography } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { TextBoard } from "../../../../../interface/partials/text-board";
import { BR } from "../../../../../content/components/linebreak";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContactOption } from "../../../../../interface/partials/contact-option";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#B8FDE7",
    display: "flex",
    justifyContent: "center",
    color: "#4a4a4a",
  },
  mobile: {
    padding: theme.spacing(8, 0),
    "& $wrapper": {
      maxWidth: "unset",
      textAlign: "center",
    },
    "& $ctaWrapper": {
      textAlign: "left",
      height: "unset",
      paddingBottom: 0
    }
  },
  image: {
    margin: "auto",
    width: "100%",
  },
  montserrat: {
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
  wrapper: {
    maxWidth: "calc(1000px + 5vw)",
  },
  ctaWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
});

export const WistJeDat3 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          inner: classes.wrapper,
          mobile: classes.mobile,
        }}
      >
        <ContentLayoutColumns>
          <ContentColumn wide>
            <TextBoard
              image="/article/images/vent-turquoise.png"
              color="#528CB2"
              titleColor="#fff"
              title={
                <>
                  <Typography variant="h5">2/4 Wist je dat...?</Typography>
                </>
              }
              subtitle={
                <Typography variant="h5" style={{ fontStyle: "italic" }}>
                  ...er grote belangstelling is voor <BR />
                  de gratis ABP-bewustwordingstraining <BR />
                  ‘Financiën in de vingers’?
                </Typography>
              }
            >
              <Typography paragraph>
                Er geldt zelfs een wachtlijst voor deelname aan de training. Heb
                jij onvoldoende meegekregen hoe je goed met geld om kunt gaan?
                Of regelt je partner alles? En wil je dit graag anders zien en
                grip krijgen op je eigen geldzaken? Zet jezelf dan op de
                wachtlijst voor deze training.
              </Typography>
            </TextBoard>
          </ContentColumn>
          <ContentColumn>
            <div className={classes.ctaWrapper}>
              <Typography
                gutterBottom
                variant="h6"
                style={{ borderBottom: "5px solid", paddingBottom: 10 }}
                className={classes.montserrat}
              >
                Meer info?
              </Typography>
              <ContactOption
                style={{ fontFamily: "Montserrat" }}
                footer={<small>Meer informatie</small>}
                header={
                  <small>
                    Training bewustwordingstraining ‘Financiën in de vingers’?
                  </small>
                }
              />
              <ContactOption
                style={{ fontFamily: "Montserrat", borderBottom: "none" }}
                footer={<small>Direct aanmelden</small>}
                header={
                  <small>
                    Training bewustwordingstraining ‘Financiën in de vingers’?
                  </small>
                }
              />
            </div>
          </ContentColumn>
        </ContentLayoutColumns>
      </LayoutDefault>
    );
  }
);
