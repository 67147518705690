import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";
import { page4 } from "./pages/page-4";
import { page5 } from "./pages/page-5";

export const china: ArticleData = {
  id: "55b2d5c3-dc7e-46f1-853f-a4b4a476e050",
  slug: "groeten-uit-china",
  index: "3",
  title: "Groeten uit China",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  style: {
    backgroundColor: "#F1C9A4"
  },
  color: "#4a4a4a",
  image: "/article/images/china-thumb.png",
  content: "",
  pages: [page1, page2, page3, page4, page5],
  type: "article"
};
