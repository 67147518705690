import * as React from "react";
import { Typography, Hidden, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Media } from "../../../../../interface/partials/media";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "mobile" | "desktop">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#161D51C2",
    // backgroundBlendMode: "multiply",
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    margin: " 0 5vw 0 0",
    marginTop: "auto"
  },
  mobile: {},
  desktop: {
    backgroundImage: "url(/article/images/slijpsteen-gradient.png)"
  }
});

export const A5P5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        mobile: classes.mobile,
        desktop: classes.desktop
      }}
    >
      <ContentLayoutColumns size="big" animation="closeIn">
        <Hidden mdDown>
          <ContentColumn style={{ display: "flex", maxWidth: 500 }}>
            <Media
              className={classes.image}
              image={"/article/images/workshop.png"}
              caption="Het klusmotto van Sjoerd: 'Wat een ander kan, moet ik ook
                kunnen.'"
            ></Media>
          </ContentColumn>
        </Hidden>
        <ContentColumn style={{ maxWidth: 600 }}>
          <Typography
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
            gutterBottom
          >
            Wat doe je in je vrije tijd? 
          </Typography>

          <Typography paragraph>
            ‘Ik houd erg veel van wandelen. Een keer of vijf per week wandel ik
            een uurtje in de avond. Lange wandeltochten, zoals de
            Vierdaagse, zijn voor mij niet mogelijk. Dan protesteren de
            gewrichten. Misschien een gevolg van mijn voetbalcarrière; van mijn
            zesde tot mijn vijfenveertigste voetbalde ik zelf actief bij CAB,
            Combinatie Achilles Bolsward. Daarnaast was ik daar ook lange tijd
            trainer/leider van mijn beide kinderen. Verder kijk ik graag sport
            en houd ik van reizen; met name naar Egypte, waar mijn vrouw en ik
            elk jaar gaan snorkelen. Ik mag ook graag klussen.
          </Typography>
          <Hidden lgUp>
            <Media
              image={"/article/images/workshop.png"}
              caption={`Het klusmotto van Sjoerd: ‘Wat een ander kan, moet ik ook kunnen.’`}
            ></Media>
            <br />
          </Hidden>
          <Typography>
            De familie was wat meewarig en deed wat lacherig toen ik mijn eerste
            boormachine kreeg, maar intussen weten ze me aardig te vinden als er
            iets gedaan moet worden. Ik heb ook een metselcursus gedaan. Met de
            jaren heb ik al veel gedaan in en rond ons huis. Als laatste heb ik
            een overkapping in onze tuin gemaakt. Dat klussen geeft mij veel
            voldoening. En sinds een jaar of vijf golf ik, op een 9-holes-natuurgolfbaan in Gaasterland. In principe elke zondagochtend met
            drie vrienden.’
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
