import * as React from "react";
import { Typography, Hidden, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";

interface OwnProps
  extends PageContentProps,
    WithStyles<
      "root" | "smallTitle" | "gridItem" | "contentWrapper" | "image"
    > {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#D6C9B1",
    color: "#4A4A4A",
    display: "flex",
    justifyContent: "center"
  },
  smallTitle: {
    fontFamily: "Domaine",
    color: "white",
    textTransform: "none",
    fontWeight: 500
  },
  gridItem: {
    padding: "1vw"
  },
  contentWrapper: {
    maxWidth: "60vw",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none"
    }
  },
  image: {
    width: "150px",
    height: "150px",
    margin: "auto",
    marginBottom: 20
  }
});

export const A3P4 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="big" animation="trickle">
        <Hidden mdDown>
          <ContentColumn style={{ display: "flex" }}>
            <img
              src={"/article/images/foobal.png"}
              alt=""
              className={classes.image}
            />
          </ContentColumn>
        </Hidden>
        <ContentColumn>
          <Typography paragraph>
            We zijn een klein jaar verder en de inwoning heeft langer geduurd
            dan gepland. De nutsbedrijven hadden moeite met de aansluitingen en
            zonder gas, water en licht is het lastig bivakkeren in een huis, ook
            al is dat nieuw. Uiteindelijk zijn de inwoners klaar voor vertrek.
            Het demente hondje gaat niet meer mee. Die heeft op respectabele
            leeftijd het heden met het eeuwige verwisseld. Een dramatische
            gebeurtenis.
          </Typography>
          <Hidden lgUp>
            <img
              src={"/article/images/foobal.png"}
              alt=""
              className={classes.image}
              style={{ display: "flex", margin: "20px auto" }}
            />
          </Hidden>
          <Typography paragraph>
            Bij de buurtwinkel hing een bord met de tekst ‘Papieren zakdoekjes
            tijdelijk uitverkocht’. Gelukkig wordt iedereen afgeleid door de
            verhuizing en worden wij rondgeleid in het nieuwe huis. De meisjes
            krijgen ieder een eigen kamer met bureau.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Tenslotte belanden we op de vliering met het formaat van een
            voetbalveld. ‘Hier kunnen wij wel logeren, dan zet ik ons bed
            daar…’, stel ik voor aan de kleindochters. Dat voorstel wordt
            onmiddellijk unaniem weggestemd. ‘Opa’, zegt de jongste ernstig,
            ‘dat gaat niet want onze sportspullen komen daar te staan.’
          </Typography>
          <Typography paragraph>
            Ik werk in op haar gemoed maar dat helpt niet. ‘Nee is ook een
            antwoord,’ zegt ze standvastig. Mijn partner, die het gesprek heeft
            gevolgd, knikt goedkeurend en zegt: ‘Gelukkig is jaknikken niet
            erfelijk bepaald.’ En daar moet ik het mee doen.
          </Typography>
          <Typography paragraph>
            Peertje.
            <EndOfArticle dark />
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
