import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";
import { page2 } from "./pages/page-2";
import { page3 } from "./pages/page-3";

export const rechtzaak: ArticleData = {
  id: "af355069-bfcd-46e4-a20a-149190e9d28c",
  slug: "de-rechtzaak",
  index: "4",
  title: "Peertje - De rechtzaak",
  description: "Article description",
  backgroundImage: "/article/images/orange_gradient.png",
  style: {
    backgroundColor: "#D6C9B1"
  },
  image: "/article/images/rechtzaak-thumb.png",
  content: "",
  pages: [page1, page2, page3],
  type: "article",
  color: "#4a4a4a"
};
