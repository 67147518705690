import * as React from "react";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";
import { Typography, Theme } from "@material-ui/core";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { Quote } from "../../../../../interface/partials/quote";
import { ContentColumn } from "../../../../../interface/partials/content-column";

interface OwnProps
  extends PageContentProps,
    WithStyles<"root" | "background" | "content" | "montserrat"> {}

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center"
  },
  background: {
    background:
      "linear-gradient(to right, rgba(15,34,44,1) 0%,rgba(52,86,97,1) 100%)"
  },
  montserrat: {
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  content: {
    maxWidth: 1150,
    zIndex: 2

  }
});

export const A2P2 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.content
      }}
    >
      <ContentLayoutColumns size="small" animation="trickle">
        <ContentColumn>
          <Typography
            variant={"body2"}
            paragraph
            className={classes.montserrat}
          >
            Vanaf dat moment gaat ook voor ambtenaren het private ontslagrecht
            gelden. In deze en in mijn komende bijdrages wil ik jullie hier meer
            over vertellen.
          </Typography>
          <div>
            <Quote variant={"h4"}>
              Vanaf 1 januari 2020 kan de werkgever je, in de meeste gevallen,
              niet tegen jouw wil ontslaan zonder tussenkomst van een externe
              instantie zoals het UWV of de kantonrechter.
            </Quote>
          </div>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Op dit moment kun je tegen je wil worden ontslagen en wordt pas
            achteraf getoetst of dit terecht is. Na het maken van bezwaar kun je
            het ontslag voorleggen aan de rechter. Dit gaat veranderen. Vanaf 1
            januari 2020 kan de werkgever je, in de meeste gevallen, niet tegen
            jouw wil ontslaan zonder tussenkomst van een externe instantie zoals
            het UWV of de kantonrechter.
          </Typography>
          <div>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.montserrat}
            >
              Ontslaggronden
            </Typography>
            <Typography paragraph>
              Om je te kunnen ontslaan moet er een redelijke – in de wet
              geregelde – grond voor ontslag aanwezig zijn. Er mag geen
              mogelijkheid zijn om je binnen een redelijke termijn te
              herplaatsen al dan niet met behulp van scholing. Het private
              ontslagstelsel kent net als het ambtenarenontslagrecht een
              semi-gesloten stelsel van ontslaggronden. Welke zijn dat precies?
            </Typography>
          </div>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
