import { JSONSerializer, RecordSerializers } from "../../storage/store";
import { MagazineModel, Magazine, MagazineData } from "./magazine";
import { graphqlOperation } from "aws-amplify";

import GraphQLProvider, { GraphQLProviderProps } from "../../network/graphql-provider";

import { subscribe } from "../../network/decorators/graphql-subscribe";
import { MagazineCreated, MagazineUpdated, MagazineDeleted } from "./graphql/subscriptions";
import { CreateMagazine, UpdateMagazine, DeleteMagazine } from "./graphql/mutations";
import { QueryMagazine, QueryMagazineCollection } from "./graphql/queries";
import Application from "../../application";

export interface MagazineProvider extends GraphQLProviderProps<Magazine, MagazineData> {
  fetchAll(): Promise<void>;
}

export interface MagazineValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultMagazineProvider extends GraphQLProvider<Magazine, MagazineData>
  implements MagazineProvider {
  public model = MagazineModel;

  protected serializers: RecordSerializers<MagazineData> = {
    values: JSONSerializer
  };

  public async fetchAll() {
    const response = await Application.network.fetch(QueryMagazineCollection, {});
    response.data.MagazineCollection.forEach((d: MagazineData) => {
      this.setRecord(d.id, d);
    });
  }

  @subscribe(MagazineCreated) magazineCreated = (magazine: Magazine) => {
    return magazine;
  };

  @subscribe(MagazineUpdated) magazineUpdated = (magazine: Magazine) => {
    return magazine;
  };

  @subscribe(MagazineDeleted) magazineDeleted = (magazine: Magazine) => {
    return magazine;
  };

  public createOperation(magazine: MagazineData) {
    return graphqlOperation(CreateMagazine, { input: magazine });
  }

  public fetchOperation(magazine: MagazineData) {
    return graphqlOperation(QueryMagazine, { id: magazine.id });
  }

  public updateOperation(magazine: MagazineData) {
    return graphqlOperation(UpdateMagazine, { input: magazine });
  }

  public deleteOperation(magazine: MagazineData) {
    return graphqlOperation(DeleteMagazine, { id: magazine.id });
  }
}

// export function magazineUsersOptions(magazine: any) {
//   return {
//     model: UserModel,
//     operation: graphqlOperation(QueryMagazineUsers, { id: magazine.id })
//   };
// }
