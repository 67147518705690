import { MagazineFragment } from "./fragments";

export const CreateMagazine = `
mutation CreateMagazine($input:inputMagazine!) {
createMagazine(input:$input){
  ${MagazineFragment}
}
}
`;

export const UpdateMagazine = `
mutation updateMagazine($input:inputMagazine!) {
  updateMagazine(input:$input) {
    ${MagazineFragment}
  }
}
`;

export const DeleteMagazine = `
mutation DeleteMagazine($id:ID!) {
  deleteMagazine(id:$id){
    ${MagazineFragment}
  }
}
`;
