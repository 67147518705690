import { bondigMagazine } from "../../../data/magazine/__mock__/bondig-magazine";
import { coderehabMagazine } from "../../../data/magazine/__mock__/coderehab-magazine";
import { GraphQLResolveInfo } from "graphql";
import { mockArticles } from "./article";

export const mockMagazines = () => {
  return [bondigMagazine, coderehabMagazine];
};

export const magazineArticleResolver = () => {
  return mockArticles();
};

export const QueryMagazineResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  const result = mockMagazines().filter((magazine: any) => {
    return magazine.id === args.id;
  });

  const magazine = result[0];

  return magazine || null;
};

export const QueryMagazineCollectionResolver = (source: any, args: any, context: any, info: GraphQLResolveInfo) => {
  const result = mockMagazines().filter((magazine: any) => {
    return magazine.id;
  });

  return result || null;
};
