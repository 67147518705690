import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "gridItem" | "contentWrapper">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#429FCD",

  },
  image: {
    [theme.breakpoints.up("md")]: {
      margin: "-100px"
    }
  }
});

export const A7P6 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
      <ContentLayoutColumns size="small" animation="trickle">
        <ContentColumn>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Verlofbonus
          </Typography>
          <Typography>
            Daarna kwam de extra verlofbonus voor medewerkers jonger dan 46 jaar
            aan de orde. Uit onderzoek blijkt dat dat nachtdiensten voor
            iedereen schadelijk zijn. Daarom wordt er afgesproken dat er extra
            verlof komt per uur dat je werkt tussen 0.00 en 6.00 uur. Ieder uur
            dat je dan werkt ontvang je een extra bonus van 12% verlof. Ook is
            afgesproken dat je 10 jaar voor het bereiken van de AOW-leeftijd mag
            stoppen met nachtdiensten. Een nadeel van deze nieuwe regeling is
            dat je nu later mag stoppen met nachtdiensten. Dat was eerst 55 jaar
            en dat wordt nu 58 jaar. De discussies die volgden in de zaal gingen
            o.a. over waar je het opgespaarde verlof voor kunt gebruiken en hoe
            er wordt omgegaan met ongezonde keuzes die mensen maken in hun
            rooster.
          </Typography>
        </ContentColumn>

        <ContentColumn>
          <Typography paragraph>
            De sfeer in de zaal bleef gedurende de hele avond levendig. Veel van
            de aanwezigen mengden zich in discussies en regelmatig liepen de
            emoties hoog op.
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Vergoedingen, toeslagen en jaarurensystematiek
          </Typography>
          <Typography>
            Er komen wijzigingen in de vergoedingen voor overwerken, het maximum
            bedrag voor TOD wordt verhoogd en bij ziekte en verlof ontvang je de
            TOD conform het rooster. De zaal vroeg zich af hoe hoog in de nieuwe
            situatie de vergoeding is voor overwerk in de nacht in het weekend.
            Sommigen in de zaal kunnen namelijk onverwachts opgeroepen worden in
            het weekend. De vergoeding is nu 100%. Marianne legde uit dat het
            overwerkpercentage wijzigt naar een vast percentage van 25%.
          </Typography>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
