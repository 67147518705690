import { GraphQLModel, GraphQLBase } from "../../network/graphql-model";

import { data } from "../../network/decorators/graphql-data";

export interface TemplateData {
  id: string;
  title: string;
  description: string;
  content: string;
  type: string;
  slug: string;
  coverImage: string;
  magazineId: string;
}

export interface Template extends GraphQLModel<TemplateData>, TemplateData {}

export interface TemplateValues {}

export class TemplateModel extends GraphQLBase<TemplateData> implements Template {
  typename = "Template";

  @data public title = "";
  @data public content = "";
  @data public type = "";
  @data public slug = "";
  @data public coverImage = "";
  @data public description = "";
  @data public magazineId = "";
}
