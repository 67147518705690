import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { spacing } from "../../../theme/config";
import { mapEvent } from "../../../helpers/formatters";

interface OwnProps {
  id: string;
  index: number;
  title: string;
  image: string;
  onSelect(id: string): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: spacing(0),
    display: "flex",
    flexDirection: "column",
    textDecoration: "none",
    position: "relative",
    color: "#000",
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: theme.transitions.create(["padding", "background"], {
      duration: "0.3s"
    }),
    "&:before": {
      content: '""',
      position: "absolute",
      left: "-5%",
      top: "10%",
      width: "110%",
      height: "100%",
      display: "block",
      background: "#e4e4e400",
      transition: theme.transitions.create(["padding", "background"], {
        duration: "0.3s"
      })
    },
    "&:hover": {
      "&:before": {
        background: "#e4e4e4ff"
      }
    },
    "&:hover img": {
      transform: "translateY(-15px)"
    }
  },
  container: {
    position: "relative",
    height: "100%"
  },
  index: {
    borderRadius: "100%",
    backgroundColor: "#FFC583",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: theme.spacing(-1),
    bottom: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    fontFamily: "Montserrat"
  },
  image: {
    boxShadow: theme.shadows[10],
    objectFit: "cover",
    width: "100%",
    transition: "transform 0.3s ease-out"
  },
  title: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    paddingTop: theme.spacing(0),
    zIndex: 2,
    fontFamily: "Montserrat"
  },
  link: {}
}));

export const CoverButton = ({
  id,
  index,
  image,
  title,
  onSelect
}: OwnProps) => {
  const classes = useStyles();
  return (
    <div onClick={mapEvent(onSelect, id)} className={classes.root}>
      <div className={classes.container}>
        <img className={classes.image} src={image} alt={image} />
        <span className={classes.index}>{index}</span>
      </div>
      <Typography className={classes.title}>
        <small>{title}</small>
      </Typography>
    </div>
  );
};
