import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { QandA } from "../../../../../interface/partials/qanda";
import { RenderWhile } from "../../../../../content/components/render-while";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { BR } from "../../../../../content/components/linebreak";
import classnames from "classnames";

type OwnProps = PageContentProps &
  WithStyles<"root" | "image" | "bold" | "montserrat">;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#597889",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    margin: "auto 50px",
    maxWidth: "100%",
  },
  bold: {
    fontWeight: 500,
  },
  montserrat: {
    fontFamily: "Montserrat",
  },
});

export const NoaHolleman2 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <QandA
          question={
            <Typography align="center" variant="h6">
              Kijk bijvoorbeeld maar eens naar de steeds groter wordende
              aantallen <BR />
              jeugdige, fris en fruitig ogende collega’s die de kantoorruimten,{" "}
              <BR />
              vergaderzalen en bedrijfskantine dagelijks vol energie betreden.
              De 20 jaar <BR />
              jonge Noa Holleman uit het Gelderse dorpje Gaanderen (gemeente{" "}
              <BR />
              Doetinchem) is één van hen.
            </Typography>
          }
          answer={
            <>
              <RenderWhile desktop mobile>
                <ContentLayoutColumns size="big" animation="trickle">
                  <ContentColumn>
                    <Typography paragraph>
                      Na het behalen van haar vwo-diploma is Noa in augustus
                      2017 gestart met een duale opleiding Accountancy. Op het
                      belastingkantoor in Arnhem is ze momenteel werkzaam als
                      controlemedewerker op de afdeling Grote Ondernemingen. Noa
                      heeft na het lezen van een wervingsadvertentie op het
                      internet vol overtuiging gekozen voor een loopbaan bij de
                      Belastingdienst. Ze stelt zich graag aan u voor.
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classnames(classes.montserrat, classes.bold)}
                      gutterBottom
                    >
                      In IBM Notes staat onder jouw naam vermeld: ‘Accountancy
                      student fase 1’. Wat betekent die term?
                    </Typography>
                    <Typography paragraph>
                      Noa vertelt: ‘Ik heb een vwo-diploma met profiel Economie
                      en Maatschappij behaald.
                    </Typography>
                  </ContentColumn>
                  <ContentColumn>
                    <Typography paragraph>
                      Daarna ben ik gestart met de duale opleiding Bachelor of
                      Science in Accountancy aan Nyenrode Business Universiteit.
                      Iedere vrijdag volg ik colleges op Nyenrode. Het totale
                      opleidingstraject duurt omstreeks negen jaar met als
                      einddoel het behalen van de titel Register Accountant.
                    </Typography>
                    <Typography>
                      De eerste drie jaar van het totale opleidingstraject volg
                      ik, naast de colleges op Nyenrode, een interne studie
                      controletechnieken bij de BelastingdienstAcademie. De
                      interne studie bestaat uit colleges en werkperiodes.
                      Momenteel ben ik bezig met het derde jaar van deze studie.
                      Medio 2020 hoop ik de interne studie met goed gevolg af te
                      sluiten. Daarna zal ik fulltime werkzaam zijn op kantoor
                      Arnhem.’
                    </Typography>
                  </ContentColumn>
                </ContentLayoutColumns>
              </RenderWhile>

              <RenderWhile print>
                <Typography paragraph>
                  Na het behalen van haar vwo-diploma is Noa in augustus 2017
                  gestart met een duale opleiding Accountancy. Op het
                  belastingkantoor in Arnhem is ze momenteel werkzaam als
                  controlemedewerker op de afdeling Grote Ondernemingen. Noa
                  heeft na het lezen van een wervingsadvertentie op het internet
                  vol overtuiging gekozen voor een loopbaan bij de
                  Belastingdienst. Ze stelt zich graag aan u voor.
                </Typography>
                <Typography
                  variant="h5"
                  className={classes.montserrat}
                  gutterBottom
                >
                  In IBM Notes staat onder jouw naam vermeld: ‘Accountancy
                  student fase 1’. Wat betekent die term?
                </Typography>
                <Typography paragraph>
                  Noa vertelt: ‘Ik heb een vwo-diploma met profiel Economie en
                  Maatschappij behaald.
                </Typography>
                <Typography paragraph>
                  Daarna ben ik gestart met de duale opleiding Bachelor of
                  Science in Accountancy aan Nyenrode Business Universiteit.
                  Iedere vrijdag volg ik colleges op Nyenrode. Het totale
                  opleidingstraject duurt omstreeks negen jaar met als einddoel
                  het behalen van de titel Register Accountant.
                </Typography>
                <Typography>
                  De eerste drie jaar van het totale opleidingstraject volg ik,
                  naast de colleges op Nyenrode, een interne studie
                  controletechnieken bij de BelastingdienstAcademie. De interne
                  studie bestaat uit colleges en werkperiodes. Momenteel ben ik
                  bezig met het derde jaar van deze studie. Medio 2020 hoop ik
                  de interne studie met goed gevolg af te sluiten. Daarna zal ik
                  fulltime werkzaam zijn op kantoor Arnhem.’
                </Typography>
              </RenderWhile>
            </>
          }
        />
      </LayoutDefault>
    );
  }
);
