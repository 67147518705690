import { TemplateFragment } from "./fragments";

export const TemplateCreated = `
subscription {
  magazineCreated {
    ${TemplateFragment}
  }
}
`;

export const TemplateUpdated = `
subscription {
  magazineUpdated {
    ${TemplateFragment}
  }
}
`;

export const TemplateDeleted = `
subscription {
  magazineDeleted {
    ${TemplateFragment}
  }
}
`;
