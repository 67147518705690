import { Error404 } from "../view/pages/error-404";
import { Cover as Cover51 } from "../view/pages/content/bondig/51/a1/cover";
import { Cover as Cover52 } from "../view/pages/content/bondig/52/cover/cover";
import { A2P1 } from "../view/pages/content/bondig/51/a2/a2p1";
import { A2P2 } from "../view/pages/content/bondig/51/a2/a2p2";
import { A2P3 } from "../view/pages/content/bondig/51/a2/a2p3";
import { A2P4 } from "../view/pages/content/bondig/51/a2/a2p4";
import { A3P1 } from "../view/pages/content/bondig/51/a3/a3p1";
import { A3P2 } from "../view/pages/content/bondig/51/a3/a3p2";
import { A3P3 } from "../view/pages/content/bondig/51/a3/a3p3";
import { A3P4 } from "../view/pages/content/bondig/51/a3/a3p4";
import { A4P1 } from "../view/pages/content/bondig/51/a4/a4p1";
import { A4P2 } from "../view/pages/content/bondig/51/a4/a4p2";
import { A4P3 } from "../view/pages/content/bondig/51/a4/a4p3";
import { A4P4 } from "../view/pages/content/bondig/51/a4/a4p4";
import { A4P5 } from "../view/pages/content/bondig/51/a4/a4p5";
import { A4P6 } from "../view/pages/content/bondig/51/a4/a4p6";
import { A4P7 } from "../view/pages/content/bondig/51/a4/a4p7";
import { A5P1 } from "../view/pages/content/bondig/51/a5/a5p1";
import { A5P2 } from "../view/pages/content/bondig/51/a5/a5p2";
import { A5P3 } from "../view/pages/content/bondig/51/a5/a5p3";
import { A5P4 } from "../view/pages/content/bondig/51/a5/a5p4";
import { A5P5 } from "../view/pages/content/bondig/51/a5/a5p5";
import { A5P6 } from "../view/pages/content/bondig/51/a5/a5p6";
import { A5P7 } from "../view/pages/content/bondig/51/a5/a5p7";
import { A6P1 } from "../view/pages/content/bondig/51/a6/a6p1";
import { A6P2 } from "../view/pages/content/bondig/51/a6/a6p2";
import { A6P3 } from "../view/pages/content/bondig/51/a6/a6p3";
import { A6P4 } from "../view/pages/content/bondig/51/a6/a6p4";
import { A6P5 } from "../view/pages/content/bondig/51/a6/a6p5";
import { A7P1 } from "../view/pages/content/bondig/51/a7/a7p1";
import { A7P2 } from "../view/pages/content/bondig/51/a7/a7p2";
import { A7P3 } from "../view/pages/content/bondig/51/a7/a7p3";
import { A7P4 } from "../view/pages/content/bondig/51/a7/a7p4";
import { A7P5 } from "../view/pages/content/bondig/51/a7/a7p5";
import { A7P6 } from "../view/pages/content/bondig/51/a7/a7p6";
import { A7P7 } from "../view/pages/content/bondig/51/a7/a7p7";
import { A8P1 } from "../view/pages/content/bondig/51/a8/a8p1";
import { A8P2 } from "../view/pages/content/bondig/51/a8/a8p2";
import { bondigVoorwoord } from "../view/pages/content/bondig/51/voorwoord/voorwoord";
import { bondigWinnaars } from "../view/pages/content/bondig/51/winnaars/winnaars";
import { PensioenPraat } from "../view/pages/content/bondig/51/pensioenpraat/pensioenpraat";
import { BondigPoll1 } from "../view/pages/content/bondig/51/poll/poll-bondig-51";
import { A8P3 } from "../view/pages/content/bondig/51/a8/a8p3";
import { A8P4 } from "../view/pages/content/bondig/51/a8/a8p4";
import { A8P5 } from "../view/pages/content/bondig/51/a8/a8p5";
import { A8P6 } from "../view/pages/content/bondig/51/a8/a8p6";
import { A8P7 } from "../view/pages/content/bondig/51/a8/a8p7";
import { FotograafGezochtP1 } from "../view/pages/content/bondig/51/fotograaf-gezocht/fotograaf-gezochtp1";
import { BestuursverkiezingP1 } from "../view/pages/content/bondig/51/bestuursverkiezing/bestuursverkiezingp1";
import { BestuursverkiezingP2 } from "../view/pages/content/bondig/51/bestuursverkiezing/bestuursverkiezingp2";
import { BestuursverkiezingP3 } from "../view/pages/content/bondig/51/bestuursverkiezing/bestuursverkiezingp3";
import { VerruimingBedrijfsfitnessP1 } from "../view/pages/content/bondig/51/verruiming-bedrijfsfitness/verruiming-bedrijfsfitnessp1";
import { VerruimingBedrijfsfitnessP2 } from "../view/pages/content/bondig/51/verruiming-bedrijfsfitness/verruiming-bedrijfsfitnessp2";
import { VerruimingBedrijfsfitnessP3 } from "../view/pages/content/bondig/51/verruiming-bedrijfsfitness/verruiming-bedrijfsfitnessp3";
import { VerruimingBedrijfsfitnessP4 } from "../view/pages/content/bondig/51/verruiming-bedrijfsfitness/verruiming-bedrijfsfitnessp4";
import { NoaHollemanCover } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-cover";
import { NoaHolleman2 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-2";
import { NoaHolleman3 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-3";
import { NoaHolleman4 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-4";
import { NoaHolleman5 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-5";
import { NoaHolleman6 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-6";
import { NoaHolleman7 } from "../view/pages/content/bondig/52/noa_holleman/noa_holleman-7";
import { ChinaCover } from "../view/pages/content/bondig/52/china/china-cover";
import { China2 } from "../view/pages/content/bondig/52/china/china-2";
import { China3 } from "../view/pages/content/bondig/52/china/china-3";
import { China4 } from "../view/pages/content/bondig/52/china/china-4";
import { China5 } from "../view/pages/content/bondig/52/china/china-5";
import { RechtzaakCover } from "../view/pages/content/bondig/52/rechtzaak/rechtzaak-cover";
import { Rechtzaak2 } from "../view/pages/content/bondig/52/rechtzaak/rechtzaak-2";
import { Rechtzaak3 } from "../view/pages/content/bondig/52/rechtzaak/rechtzaak-3";
import { ZiekZijnCover } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-cover";
import { ZiekZijn2 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-2";
import { ZiekZijn3 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-3";
import { ZiekZijn4 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-4";
import { ZiekZijn5 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-5";
import { ZiekZijn6 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-6";
import { ZiekZijn7 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-7";
import { ZiekZijn8 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-8";
import { ZiekZijn9 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-9";
import { ZiekZijn10 } from "../view/pages/content/bondig/52/ziek_zijn/ziek_zijn-10";
import { TransitievergoedingCover } from "../view/pages/content/bondig/52/transitievergoeding/transitievergoeding-cover";
import { Transitievergoeding2 } from "../view/pages/content/bondig/52/transitievergoeding/transitievergoeding-2";
import { Transitievergoeding3 } from "../view/pages/content/bondig/52/transitievergoeding/transitievergoeding-3";
import { Transitievergoeding4 } from "../view/pages/content/bondig/52/transitievergoeding/transitievergoeding-4";
import { Voorwoord } from "../view/pages/content/bondig/52/voorwoord/voorwoord";
import { WistJeDatCover } from "../view/pages/content/bondig/52/wist_je_dat/wist_je_dat-cover";
import { WistJeDat3 } from "../view/pages/content/bondig/52/wist_je_dat/wist_je_dat-3";
import { WistJeDat2 } from "../view/pages/content/bondig/52/wist_je_dat/wist_je_dat-2";
import { WistJeDat4 } from "../view/pages/content/bondig/52/wist_je_dat/wist_je_dat-4";
import { WistJeDat5 } from "../view/pages/content/bondig/52/wist_je_dat/wist_je_dat-5";

export const pageContent = {
  "38a27e24-9ebb-4c18-88d7-dee38943a9f4": {
    component: Cover51
  },
  "68a06a7c-8880-4083-84a4-f244b7cb6a8d": {
    component: bondigVoorwoord
  },
  "b982fa30-4ede-481a-a01d-b83fd78e3129": {
    component: A2P1
  },
  "07050537-7e9d-4237-bd2b-577034b2d39d": {
    component: A2P2
  },
  "8c6871d7-c20d-4399-9734-da60d558ab94z": {
    component: A2P3
  },
  "003ee8bd-a4d8-4ff1-a8a8-21ad55aabdef": {
    component: A2P4
  },
  "3e0e45d6-95c6-433e-9a97-428fd0e015cf": {
    component: A3P1
  },
  "788901a2-ee15-4ed2-aea0-30294e79b213": {
    component: A3P2
  },
  "6cf32d80-f662-4d17-a88a-b36026efddba": {
    component: A3P3
  },
  "f9c1d83d-f33b-4610-9465-32b302255956": {
    component: A3P4
  },
  "6a4bb5c2-0252-48e7-8941-56c4a13ff32a": {
    component: A4P1
  },
  "bdd57c89-11e5-40ef-8695-dcace484813f": {
    component: A4P2
  },

  "bb055376-5955-4b46-9c44-25b2fa5fdbdc": {
    component: A4P3
  },

  "dd5337ac-2234-4a93-a91f-ad8fbdd6c2dd": {
    component: A4P4
  },

  "7099d627-411b-4ed6-8b87-c33ba7d04e93": {
    component: A4P5
  },

  "73c9a023-9c81-4930-8e86-9a02a4fd6c79": {
    component: A4P6
  },

  "9783d08f-8d80-40ec-a9e4-5fb5b124889c": {
    component: A4P7
  },

  "ca823f72-64af-401d-a362-005967ceadc7": {
    component: A5P1
  },

  "bb4483f5-f53b-4abb-8717-005fb5ea788e": {
    component: A5P2
  },

  "d093feb7-218b-49a6-b2b7-c58515f28681": {
    component: A5P3
  },

  "7925ccee-d7b3-4163-8e7e-c0c710a5dae5": {
    component: A5P4
  },

  "da7427e6-9ef8-4862-95ea-255f065926c9": {
    component: A5P5
  },

  "ee7abddb-633e-4d1a-a0f9-a7ba7a83ea0f": {
    component: A5P6
  },

  "62c302e8-2473-4cd3-9380-f4e3d54bec0c": {
    component: A5P7
  },

  "f36aedc1-e9bd-441a-a648-fa694a381a57": {
    component: A6P1
  },

  "7ec794e1-97f0-4be0-a9ed-cc016fe96236": {
    component: PensioenPraat
  },
  "df311812-4f63-4004-a4a8-1594ffd97f2b": {
    component: FotograafGezochtP1
  },

  "49ed49b3-cb8f-4714-9ac7-5a5299e5296c": {
    component: BestuursverkiezingP1
  },

  "793561e8-9ff3-4966-8b24-389857a57434": {
    component: BestuursverkiezingP2
  },

  "08405db9-d967-44b4-9ad6-5004bf6288b0": {
    component: BestuursverkiezingP3
  },

  "9d84a5da-15ac-43a8-9d37-3ab8f4597df2": {
    component: VerruimingBedrijfsfitnessP1
  },

  "43f5741b-b7c2-44f4-8ca1-0db4b2d3b65b": {
    component: VerruimingBedrijfsfitnessP2
  },

  "e6338d96-216a-4d21-ac26-32ece81a4f08": {
    component: VerruimingBedrijfsfitnessP3
  },

  "07fe9943-d156-4fdb-bea3-d670fa5c832c": {
    component: VerruimingBedrijfsfitnessP4
  },
  "fd0fa68b-ebda-46af-8c39-f1462e7c711a": {
    component: A6P2
  },

  "9b11f56e-0b35-4253-8ccc-5a807b0d313c": {
    component: A6P3
  },

  "a413ca2f-f4b5-4ef5-95bd-2ef043d243d7": {
    component: A6P4
  },
  "142eaf22-aa27-4b9b-a9cd-224a7dd96c92": {
    component: A6P5
  },
  "b12471ae-bf3c-4b5e-97eb-9fb6e580ba45": {
    component: A7P1
  },

  "54fbc2d5-fd30-427b-9851-09fbe9365145": {
    component: A7P2
  },

  "12b1518d-85e6-41f0-ba34-336f9158c287": {
    component: A7P3
  },

  "83320ac9-739b-48cf-93c2-843382c8a558": {
    component: A7P4
  },

  "cc3ba76a-f084-4285-a97c-e6e05c299335": {
    component: A7P5
  },

  "18f74e03-888c-4d49-b374-6268f101ee60": {
    component: A7P6
  },

  "bfa2d489-2d74-4ce7-abd8-9bde538dd14f": {
    component: A7P7
  },
  "96f3a405-a638-4a70-bd1f-1854d8e348f2": {
    component: bondigWinnaars
  },
  "1832e730-89f7-4738-8e22-6d1264522c28": {
    component: A8P1
  },
  "efd9fd77-5d58-489a-9136-3e7aa3111628": {
    component: A8P2
  },
  "c7650420-9aac-4bcf-8fc4-9cbca23aa31e": {
    component: A8P3
  },

  "d07f529d-b453-4918-a18b-930870c9528a": {
    component: A8P4
  },

  "af9dd006-4a98-426a-aace-d6fa702c21d3": {
    component: A8P5
  },

  "c1e403e9-50bf-4680-8567-12b5dfa94b05": {
    component: A8P6
  },

  "9b045d11-d6ec-41dc-bfb2-b2559abd35f1": {
    component: A8P7
  },

  "poll-bondig-51": {
    component: BondigPoll1
  },
  "404": {
    component: Error404
  },

  "9a25ade0-b63f-40cb-9243-5a9c8ab61de9": {
    component: Cover52
  },

  "c23cb110-e344-470a-805b-69cda30d05a9": {
    component: NoaHollemanCover
  },

  "f178daa6-543c-4fe9-88c0-9aafb8182b82": {
    component: NoaHolleman2
  },

  "61c260ac-0fae-44f2-8259-102d158a8387": {
    component: NoaHolleman3
  },

  "2cf6e2fc-8cb1-42ea-88f7-8307c7d5f02c": {
    component: NoaHolleman4
  },

  "3f9fa0b4-a78f-4f50-9041-1356502a95f7": {
    component: NoaHolleman5
  },
  "19cb51b2-da0f-4493-826e-200a8b86bf7c": {
    component: NoaHolleman6
  },
  "96e819a8-a701-4719-a5e2-e5a377678874": {
    component: NoaHolleman7
  },
  "6780bd9d-391c-4050-a32f-0562dbf79299": {
    component: ChinaCover
  },
  "758748df-430c-415c-b244-e5c60c7818ab": {
    component: China2
  },
  "ed4fbaf4-b52e-43af-8640-03cd420ea8cd": {
    component: China3
  },
  "3b53e005-c4bb-43c4-8535-1805c8a7fe57": {
    component: China4
  },
  "74ddb1f1-e0b1-4bdb-b352-a73066f95181": {
    component: China5
  },
  "10a7001e-304e-469f-a98d-bed70ecf8973": {
    component: RechtzaakCover
  },
  "9126a64c-fcfa-4ee3-a303-76ff5f78a027": {
    component: Rechtzaak2
  },
  "d5b997b4-0344-49d1-b351-0e3e6088820a": {
    component: Rechtzaak3
  },
  "d1764f3e-ffb5-4e4d-89a9-54203945ba01": {
    component: ZiekZijnCover
  },
  "b5272b2d-d655-4283-92d3-1e929933555e": {
    component: ZiekZijn2
  },
  "fe517ef0-652b-418a-a598-9256f4b1b655": {
    component: ZiekZijn3
  },
  "876031cf-4997-4e50-9d34-0433d0d3af4f": {
    component: ZiekZijn4
  },
  "e47795d3-7cc7-405f-8ff0-a5bfc4b565a0": {
    component: ZiekZijn5
  },
  "9b29f981-db3a-46a8-a32d-e91bde215cce": {
    component: ZiekZijn6
  },
  "6a0c588c-fd65-4756-95e5-487c36dec79c": {
    component: ZiekZijn7
  },
  "c4738214-9d94-4181-bc81-abb1ee5b3a02": {
    component: ZiekZijn8
  },
  "e0844790-9ee6-4e49-9bb5-5d6d5011c763": {
    component: ZiekZijn9
  },
  "b215b848-3d77-4457-bb93-caea77db0b6c": {
    component: ZiekZijn10
  },
  "638412ca-3d58-4c4f-b3c6-b9e7b39c98b2": {
    component: TransitievergoedingCover
  },
  "0b71a9e2-5a13-4364-8048-3edbacf8865a": {
    component: Transitievergoeding2
  },
  "8c888724-19c6-43f3-9c37-3671d0985198": {
    component: Transitievergoeding3
  },
  "83977514-a69b-4489-895c-8458113bbf8b": {
    component: Transitievergoeding4
  },
  "31a2c0c3-4d71-416b-b3f4-9c1fcf5f9ab4": {
    component: Voorwoord
  },
  "44a77fae-32a8-4756-80fd-f34aa18beb1f": {
    component: WistJeDatCover
  },
  "0824bfa4-feb3-485a-9a0d-8a383b276d10": {
    component: WistJeDat2
  },
  "77ce065f-6981-4c3d-9786-f92d98cb21e4": {
    component: WistJeDat3
  },
  "9047c59d-40b8-44f1-8d7e-13ff5664b3ab": {
    component: WistJeDat4
  },
  "7291a95e-821c-48a4-8020-8ae21403c6a6": {
    component: WistJeDat5
  }
};
