import { ArticleData } from "../../../article";
import { page1 } from "./pages/page-1";

export const bondigPoll: ArticleData = {
  id: "poll",
  slug: "bondig-poll",
  title: "Wat vind jij?",
  description: "",
  index: "15",
  image: "/article/images/wat_vind_jij-thumb.png",
  backgroundImage: "/article/images/a8cover.png",
  content: "",
  pages: [page1],
  type: "poll"
};
