import * as React from "react";

import { WithStyles, withStyles } from "@material-ui/styles";
import { Typography, Grid, Button, Hidden, Theme } from "@material-ui/core";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { observer } from "mobx-react";
import { EditionBall } from "../../../../../interface/partials/edition-ball";
import { SlideFade } from "../../../../../content/components/slide-fade";
import { spacing } from "../../../../../../theme/config";
import { Carousel } from "../../../../../interface/partials/carousel";
import { mapEvent } from "../../../../../../helpers/formatters";
import { ZoomFade } from "../../../../../content/components/zoom-fade";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "background"
    | "content"
    | "title"
    | "subtitle"
    | "highlightedContent"
    | "highlight"
    | "highlightCategory"
    | "highlightTitle"
    | "buttonReadMore"
    | "header"
    | "edition"
    | "bondig"
    | "ncf"
    | "bgOverlay"
    | "bgHands"
    | "bgWarm"
    | "caption"
    | "desktop"
    | "mobile"
  >;

const highlightedContent = [
  {
    link: "#",
    title: "xxx",
    content: "xxx",
  },
  {
    link: "#",
    title: "xxx",
    content: "xxx",
  },
  {
    link: "#",
    title: "xxx",
    content: "xxx",
  },
];

export const Cover = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    display: "flex",
    background: "url('/article/images/e51-cover-bg.png')",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    // paddingBottom: spacing(13),
  },
  mobile: {
    alignItems: "center",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2.5) + "px !important",
    fontSize: "0.8em",
    height: "100vh",
    [theme.breakpoints.down("xs")]: {
      height: "unset",
    },
    "& $content": {
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
      },
    },

    "& $subtitle": {
      fontSize: "1.3em",
    },
    "& $buttonReadMore": {
      marginTop: theme.spacing(1),
    },
  },
  desktop: {
    height: "100vh",
    padding: "4vw 7vw",
    paddingBottom: "calc(2vw + " + theme.spacing(5) + "px) !important",
  },
  background: {},
  content: {
    flex: 1,
    margin: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between !important",
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  edition: { position: "absolute", left: "42%", top: 0 },
  bondig: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  caption: {
    lineHeight: 1,
    marginLeft: 12,
    [theme.breakpoints.down("md")]: {
      margin: "0 !important",
    },
  },
  ncf: {},
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "5em !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5.5em",
    },
    fontWeight: 500,
  },
  subtitle: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.1em",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "70vw",
    },
    margin: theme.spacing(0.5, 0, 2, 0),
  },
  highlightedContent: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
      justifyContent: "flex-start",
    },
  },
  highlight: {
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid #FFC583",
    },
    padding: theme.spacing(0, 1, 0, 1) + " !important",
    // margin: theme.spacing(2, 0, 0, 0),
  },
  highlightCategory: {
    color: "#FFC583",
    textTransform: "uppercase",
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
    fontFamily: "Montserrat",
    fontWeight: 600,
    // fontSize: "0.8em"
  },
  highlightTitle: {
    // fontSize: "1em",
    lineHeight: 1.25,
    cursor: "pointer",
    "&:hover": {
      "& a": {
        color: "#f9c583",
      },
    },

    "& > a": {
      transition: "color 0.2s ease",
      color: "#fff",
    },
  },
  buttonReadMore: {
    color: "#fff",
    borderColor: "#fff",
    padding: "3px 36px",
  },
  bgOverlay: {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
    },
  },
  bgHands: {
    position: "absolute",
    right: spacing(5),
    bottom: spacing(7),
    width: "45vw",
  },
  bgWarm: {
    position: "absolute",
    right: -60,
    bottom: -20,
    zIndex: 1,
    width: "30vw",
  },
}))(
  observer(({ classes, presenter }: OwnProps) => (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        background: classes.background,
        inner: classes.content,
        desktop: classes.desktop,
        mobile: classes.mobile,
      }}
    >
      <section className={classes.bgOverlay}>
        <Hidden xsDown>
          <ZoomFade timeout={2500}>
            <img
              src="/article/images/coverplaat.png"
              alt="Logo"
              className={classes.bgHands}
            />
          </ZoomFade>
        </Hidden>
        <Hidden xsUp>
          <SlideFade direction="up" timeout={700}>
            <img
              src="/article/images/stempel wit.svg"
              alt="Logo"
              className={classes.bgWarm}
            />
          </SlideFade>
        </Hidden>
      </section>

      <section className={classes.header}>
        <SlideFade direction="up" timeout={900}>
          <div className={classes.bondig}>
            <img
              src="/assets/images/bondig-logo.svg"
              style={{ filter: "brightness(1000)", maxWidth: "50%" }}
              alt=""
            />
            <div className={classes.edition}>
              <EditionBall>
                nr. {presenter.edition && presenter.edition.index}
              </EditionBall>
            </div>
            <Typography variant="body1" className={classes.caption}>
              digitaal magazine <br /> voor leden van de NCF
            </Typography>
          </div>
        </SlideFade>
        <Hidden smDown>
          <div>
            <SlideFade direction="up" timeout={1100}>
              <img
                src={"/article/images/LG NCF Wit.svg"}
                alt="Logo"
                width={120}
                className={classes.ncf}
              />
            </SlideFade>
          </div>
        </Hidden>
      </section>

      <section>
        <SlideFade direction="up" timeout={1300}>
          <Typography gutterBottom variant={"h1"} className={classes.title}>
            Wnra per <BR />1 januari 2020
          </Typography>
        </SlideFade>
        <SlideFade direction="up" timeout={1500}>
          <Typography variant={"h6"} className={classes.subtitle}>
            Vanaf 1 januari 2020 geldt de Wet <BR />
            normalisering rechtspositie Ambtenaren <BR />
            (Wnra). Wat verandert er niet?
          </Typography>
        </SlideFade>
        <SlideFade direction="up" timeout={1700}>
          <div>
            <Button
              onClick={presenter.nextArticle}
              variant="outlined"
              className={classes.buttonReadMore}
            >
              Bekijk artikel
            </Button>
          </div>
        </SlideFade>
      </section>
      <Hidden smUp>
        <img
          src="/article/images/coverplaat.png"
          alt=""
          style={{ width: "100%", margin: spacing(2, 0) }}
        />
      </Hidden>
      <section style={{ minHeight: "100px" }}>
        <Grid
          container
          spacing={2}
          justify="space-between"
          className={classes.highlightedContent}
          wrap="nowrap"
        >
          <Hidden smDown>
            {highlightedContent.map((content, index) => (
              <SlideFade
                key={index}
                direction="up"
                timeout={1900 + index * 100}
              >
                <Grid item xs={12} sm="auto" className={classes.highlight}>
                  <Typography className={classes.highlightCategory}>
                    {content.title}
                  </Typography>
                  <Typography
                    variant={"body2"}
                    className={classes.highlightTitle}
                  >
                    <div
                      onClick={mapEvent(presenter.selectArticle, content.link)}
                    >
                      {content.content}
                    </div>
                  </Typography>
                </Grid>
              </SlideFade>
            ))}
          </Hidden>

          <Hidden mdUp>
            <Grid item>
              <Carousel presenter={presenter} data={highlightedContent} />
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid item xs="auto" style={{ display: "flex" }}>
              <img
                src={"/article/images/LG NCF Wit.svg"}
                alt="Logo"
                width={80}
                style={{ marginTop: "auto" }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </section>
    </LayoutDefault>
  ))
);
