import { observer } from "mobx-react";
import * as React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { routes, checkRedirect } from "../config/routes";
import PageTransition from "react-router-page-transition";
import { TransitionItem } from "./transition-item";

import "./transitions/transitions.css";
import "./transitions/slide-left.css";
import "./transitions/slide-right.css";
import "./transitions/slide-up.css";
import "./transitions/slide-down.css";
import "./transitions/fade-in-out.css";
import "./transitions/cross-fade.css";
import "./transitions/circle-grow.css";

export const Routes = observer(() => {
  const redirection = checkRedirect();
  return redirection ? (
    <Redirect to={redirection.to} />
  ) : (
    <PageTransition timeout={700}>
      <Switch>
        {routes.map(route => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={TransitionItem(route)}
          />
        ))}
        )
      </Switch>
    </PageTransition>
  );
});

export default withRouter(Routes);
