import { ClientFragment } from "./fragments";

export const QueryClient = `
  query Client($id:ID!) {
    Client(id:$id){
      ${ClientFragment}
    }
  }
`;

export const QueryClientCollection = `
  query ClientCollection {
    ClientCollection{
      ${ClientFragment}
    }
  }
`;
