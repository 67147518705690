import * as React from "react";
import { Theme, Typography, Grid } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

// import Background from "../../../theme/images/Image_1.png";
import { LayoutDefault } from "../../../../layout/layout-default";
import { PageContentProps } from "../../../../article-page";
import { ContentLayoutCentered } from "../../../../layout/content-layout-centered";
import { Media } from "../../../../../interface/partials/media";
import { BR } from "../../../../../content/components/linebreak";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "background"
    | "title"
    | "wrapper"
    | "gridItem"
    | "maxWidth"
    | "mobile"
    | "desktop"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    background:
      "transparent linear-gradient(246deg, #2D6253 0%, #1AA781 100%) 0% 0% no-repeat padding-box",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "90% 0",
    display: "flex",
    justifyContent: "center",
    color: "white",
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${theme.spacing(8)}px + 5vw)`,
      paddingRight: `calc(${theme.spacing(8)}px + 5vw)`
    },
    textAlign: "center"
  },
  mobile: {
    paddingTop: theme.spacing(4) + "px !important",
    paddingBottom: theme.spacing(20) + "px !important"
  },
  desktop: {
    alignItems: "flex-start",
    paddingTop: 0,
    "& $wrapper": {
      marginTop: theme.spacing(8)
    }
  },
  background: {
    opacity: 0.5
  },
  title: {
    marginTop: "0px !important"
  },
  wrapper: {
    maxWidth: 1500,
    width: "100%"
  }
});

export const PensioenPraat = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault
        presenter={presenter}
        classes={{
          root: classes.root,
          background: classes.background,
          inner: classes.wrapper,
          mobile: classes.mobile,
          desktop: classes.desktop
        }}
      >
        <ContentLayoutCentered
          size="big"
          animation="trickle"
          title={
            <Typography className={classes.title} variant="h1">
              Pensioen&shy;Praat
            </Typography>
          }
          subtitle={
            <Typography paragraph variant="body1">
              Onze Marianne Wendt zit namens de NCF in de pensioenkamer. In deze
              drie <BR />
              korte filmpjes legt ze het een en ander uit over pensioen, AOW en
              het <BR />
              aanvullend pensioen. Nee, nou niet meteen wegklikken! Het is
              immers in jouw <BR />
              belang om hier kennis van te nemen en het kost je nog geen vijf
              minuten.
            </Typography>
          }
          media={
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Media
                  embed={"https://www.youtube.com/embed/PGoWjrRL9YA"}
                  caption="Pensioen; over AOW en aanvullend pensioen."
                ></Media>
              </Grid>
              <Grid item xs={12} md={4}>
                <Media
                  embed={"https://www.youtube.com/embed/0sEHkO8Yr3g"}
                  caption="Aanvullend pensioen. De hoogte ervan hangt af van wanneer jij met pensioen gaat.
                  Wat is wijsheid?"
                ></Media>
              </Grid>
              <Grid item xs={12} md={4}>
                <Media
                  embed={"https://www.youtube.com/embed/2GUlwhtUBq4"}
                  caption="Jouw AOW-leeftijd wordt ook jouw ontslagdatum. Welke gevolgen
                  heeft dat voor jouw pensioenuitkering?"
                ></Media>
              </Grid>
            </Grid>
          }
        />
      </LayoutDefault>
    );
  }
);
