import * as React from "react";

import {
  Typography,
  Theme,
  Button,
  Collapse,
  WithStyles,
} from "@material-ui/core";
import { EditionData } from "../../../application/data/magazine/magazine";
import { StyleRules, withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { RenderWhile } from "../components/render-while";

interface OwnProps extends WithStyles<any> {
  edition: EditionData;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: "auto",
  },
  drawerBackdrop: {},
  header: {},
  content: {},
  backdrop: {
    backgroundColor: "none",
  },
  collapseConainer: {
    marginBottom: theme.spacing(3),
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      boxShadow: "inset 0px -70px 40px -40px rgba(255,255,255,1)",
    },
    "&.MuiCollapse-entered": {
      "&:after": {
        boxShadow: "none",
      },
    },
  },
  contentPiece: {},
  marginBottom: {
    marginBottom: "1em",
  },
});

const Info = ({ edition, classes }: OwnProps) => {
  return (
    <>
      {console.log(edition)}
      {Array.isArray(edition.description) ? (
        edition.description.map(contentPiece => {
          return (
            <Typography
              style={{ ...contentPiece.style }}
              className={classnames(
                classes.contentPiece,
                contentPiece.marginBottom && classes.marginBottom
              )}
            >
              {contentPiece.element ? (
                <contentPiece.element style={{ ...contentPiece.style }}>
                  {contentPiece.content}
                </contentPiece.element>
              ) : (
                contentPiece.content
              )}
            </Typography>
          );
        })
      ) : (
        <Typography>{edition.description.content}</Typography>
      )}
    </>
  );
};

const EditionIndexInfoComponent = ({ edition, classes }: OwnProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div>
        <Typography
          className={classnames(classes.montserrat, classes.title)}
          variant="h4"
          color="primary"
          style={{ marginBottom: "1em", lineHeight: 1 }}
        >
          {edition.subtitle}
        </Typography>
        <RenderWhile desktop print>
          <Info edition={edition} classes={classes} />
          {/* <Typography className={classes.montserrat} paragraph>
          <small style={{ fontWeight: 500 }}>
            We hebben de afgelopen maanden hard gewerkt om ons vakbondsblad in
            een nieuw jasje te steken. Als redactie zijn we van mening dat dit
            goed is gelukt en met trots kunnen we nu de eerste interactieve
            Bondig aan jou presenteren.
          </small>
        </Typography>

          <Typography className={classes.montserrat} paragraph>
            <small>
              We hopen natuurlijk dat jij ook blij bent met deze vernieuwing.
              Laat ons via het laatste hoofdstuk dus vooral weten hoe jouw
              leeservaring van Bondig 51 was. De input die we van onze lezers
              krijgen zullen we zeker meenemen bij het maken van de volgende
              editie.
            </small>
          </Typography>
          <Typography className={classes.montserrat} paragraph>
            <small>
              We wensen je veel lees- en kijkplezier en tevens natuurlijk alvast
              fijne feestdagen!
            </small>
          </Typography>
          <Typography className={classes.montserrat} paragraph>
            <small>
              Chris Regtop
              <br /> Hoofdredacteur NCF
            </small>
          </Typography> */}
        </RenderWhile>

        <RenderWhile mobile>
          <Collapse
            in={open}
            collapsedHeight={120}
            classes={{ container: classes.collapseConainer }}
          >
            {/* <Typography className={classes.montserrat} paragraph>
            <small style={{ fontWeight: 500 }}>
              We hebben de afgelopen maanden hard gewerkt om ons vakbondsblad in
              een nieuw jasje te steken. Als redactie zijn we van mening dat dit
              goed is gelukt en met trots kunnen we nu de eerste interactieve
              Bondig aan jou presenteren.
            </small>
          </Typography>
            <Typography className={classes.montserrat} paragraph>
              <small>
                We hopen natuurlijk dat jij ook blij bent met deze vernieuwing.
                Laat ons via het laatste hoofdstuk dus vooral weten hoe jouw
                leeservaring van Bondig 51 was. De input die we van onze lezers
                krijgen zullen we zeker meenemen bij het maken van de volgende
                editie.
              </small>
            </Typography>
            <Typography className={classes.montserrat} paragraph>
              <small>
                We wensen je veel lees- en kijkplezier en tevens natuurlijk
                alvast fijne feestdagen!
              </small>
            </Typography>
            <Typography className={classes.montserrat} paragraph>
              <small>
                Chris Regtop
                <br /> Hoofdredacteur NCF
              </small>
            </Typography> */}
            <Info edition={edition} classes={classes} />
          </Collapse>
          <Button
            color="primary"
            variant="contained"
            style={{ display: "flex", marginLeft: "auto" }}
            onClick={() => setOpen(!open)}
          >
            {open ? "Verberg" : "Lees meer"}
          </Button>
        </RenderWhile>
      </div>
    </>
  );
};

export const EditionIndexInfo = withStyles(styles)(EditionIndexInfoComponent);
