import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { WithStyles, withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { Quote } from "../../../../../interface/partials/quote";
import { EndOfArticle } from "../../../../../interface/partials/end-of-article";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps &
  WithStyles<
    | "root"
    | "montserrat"
    | "bold"
    | "quoteWrapper"
    | "textWrapper"
    | "paper"
    | "blurb"
    | "mobile"
    | "inner"
  >;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
    fontStyle: "italic",
  },
  montserrat: {
    fontFamily: "montserrat",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 600,
  },
  mobile: {
    "& $paper": {
      width: "calc(100% - 20px)",
      margin: "0 10px 0 10px",
    },
    "& $inner": {
      paddingRight: 70,
    },
  },
  inner: {
    position: "relative",
    zIndex: 1,
  },
  quoteWrapper: {
    display: "flex",
    padding: theme.spacing(0, 3),
    marginTop: "auto",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  textWrapper: {
    position: "relative",
    padding: "30px 0",
  },
  paper: {
    backgroundColor: "#fff",
    position: "absolute",
    left: 0,
    top: 0,
    width: "75%",
    height: "100%",
    boxShadow: "-30px 30px 35px -10px #0001",
    zIndex: -1,
    "& img": {
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
  blurb: {
    display: "inline-block",
    padding: "25px 0 0 15px",
    borderLeft: "1px solid",
    position: "absolute",
    left: "10%",
    top: "100%",
    transform: `translate(0, -20px)`,
    textTransform: "none",
    fontFamily: "Montserrat",
    textAlign: "left",
    zIndex: -2,
  },
});

export const A6P5 = withStyles(styles)(({ classes, presenter }: OwnProps) => {
  return (
    <LayoutDefault
      presenter={presenter}
      classes={{
        root: classes.root,
        mobile: classes.mobile,
        inner: classes.inner,
      }}
    >
      <ContentLayoutColumns
        animation="trickle"
        size="big"
        className={classes.textWrapper}
      >
        <RenderWhile desktop mobile>
          <div className={classes.paper}>
            <img src="/article/images/sq-pink.svg" alt="" />
            <img src="/article/images/sq-yellow.svg" alt="" />
          </div>
          <Typography variant="caption" className={classes.blurb}>
            Ingezonden brief: NCF-lid Frans Geenen
          </Typography>
        </RenderWhile>
        <ContentColumn style={{ paddingLeft: "80px", position: "relative" }}>
          <Typography variant="h6" paragraph className={classes.bold}>
            In verband met het aanvragen van een IVA-uitkering, wilde ik graag
            op voorhand weten hoe hoog mijn arbeidsongeschikt&shy;heidspensioen
            ongeveer zou gaan worden. Daarnaast wilde ik graag weten hoe hoog
            het bedrag zou zijn bij een gedeeltelijke opname van mijn pensioen,
            zijnde 10%. Met bedragen bedoel ik hier overigens bruto bedragen.
          </Typography>
          <Typography paragraph>
            Om op mijn vragen antwoord te krijgen, heb ik in eerste instantie
            het ABP benaderd.
          </Typography>
        </ContentColumn>
        <ContentColumn>
          <Typography paragraph>
            Daar kreeg ik opvallend genoeg, van verschillende medewerkers,
            verschillende bedragen/uitkomsten te horen, die ook nog eens ver uit
            elkaar lagen. Omdat ik bij het ABP helaas geen duidelijkheid kon
            krijgen, heb ik in tweede instantie mijn bond, de NCF, benaderd. In
            de persoon van Marianne Wendt, was de NCF bereid om mijn vragen uit
            handen te nemen en namens mij bij het ABP wel de juiste antwoorden
            boven tafel te krijgen.
          </Typography>
          <Typography>
            Gelukkig voor mij, is dat gelukt en is mij nu duidelijk wat mijn
            arbeidsongeschikt&shy;heidspensioen en gedeeltelijk pensioen zullen
            gaan bedragen. Ik ben daar de NCF in het algemeen en Marianne Wendt
            in het bijzonder heel dankbaar voor, want zonder de NCF was dit niet
            gelukt. <EndOfArticle dark />
          </Typography>
        </ContentColumn>
        <ContentColumn style={{ display: "flex" }}>
          <div className={classes.quoteWrapper}>
            <Quote variant="h4" dark>
              Ik ben daar de NCF in het algemeen en Marianne Wendt in het
              bijzonder heel dankbaar voor, want zonder de NCF was dit niet
              gelukt.
            </Quote>
          </div>
        </ContentColumn>
      </ContentLayoutColumns>
    </LayoutDefault>
  );
});
