import * as React from "react";
import { Typography, Theme, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/styles";

import { ContentLayoutColumns } from "../../../../layout/content-layout-columns";
import { ContentColumn } from "../../../../../interface/partials/content-column";
import { PageContentProps } from "../../../../article-page";
import { LayoutDefault } from "../../../../layout/layout-default";
import { RenderWhile } from "../../../../../content/components/render-while";

type OwnProps = PageContentProps & WithStyles<any>;

const styles = (theme: Theme): StyleRules => ({
  root: {
    backgroundColor: "#FFE288",
    color: "#4a4a4a",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "montserrat",
    fontWeight: 500,
  },
  bold: {
    fontWeight: "bold",
  },
  image: {
    margin: "-60px 0px -60px -80px",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export const ZiekZijn7 = withStyles(styles)(
  ({ classes, presenter }: OwnProps) => {
    return (
      <LayoutDefault presenter={presenter} classes={{ root: classes.root }}>
        <RenderWhile desktop mobile print>
          <ContentLayoutColumns animation="trickle">
            <ContentColumn>
              <Typography paragraph>
                Een probleem kan zijn dat de re-integrerende medewerker te veel
                het gevoel heeft dat hij onder druk gezet wordt om zo snel
                mogelijk weer (volledig) aan het werk te gaan. ‘Een voorbeeld
                daarvan is een medewerker die met RSI-verschijnselen was
                uitgevallen door het vele repeterende intoetswerk’, vervolgt
                Piet. ‘Na vijf weken rust en revalidatie begon de collega weer
                met het opbouwen van de uren. De leidinggevende maakte de
                afspraak dat er 50% van het dagelijkse intoetswerk als productie
                gehaald moest worden in de vier te werken uren per dag. Als de
                medewerker dit zou doen dan zou deze binnen een paar dagen
                opnieuw met RSI-verschijnselen uit gaan vallen.’ De
                re-integratie is in dit geval niet gericht op een duurzaam
                herstel van de medewerker.
              </Typography>
            </ContentColumn>

            <ContentColumn>
              <Typography paragraph>
                ‘In een goed gesprek met de medewerker en de leidinggevende is
                er toen gekozen voor meer afwisselende werkzaamheden met een
                langere opbouw naar de gewenste aantallen’, aldus Piet. Als je
                52 weken na je ziekmelding nog steeds volledig ziek bent dan
                begint er het één en ander te veranderen.
              </Typography>
              <Typography>
                Een ander probleem dat in de praktijk voorkomt is dat langdurig
                zieken, ernstig zieken, geen motivatie hebben om aan al die
                verplichtingen van de Wet Poortwachter te voldoen, iets wat heel
                begrijpelijk is. Als je ernstig ziek bent en allerlei
                behandelingen en therapieën moet ondergaan, zit je helemaal niet
                te wachten op gesprekken, onderzoeken en rapporten van je
                werkgever.
              </Typography>
            </ContentColumn>
            <ContentColumn>
              <Typography paragraph>
                ‘De leidinggevende is als casemanager de spin het web in dit
                proces’, vervolgt Piet. Het ligt sterk aan zijn/haar menselijke
                kant of de zakelijke kant hoe wordt omgegaan met dit soort
                situaties.
              </Typography>
              <Typography variant="h5" gutterBottom className={classes.header}>
                52 tot 104 weken ziek
              </Typography>
              <Typography paragraph>
                Als je 52 weken na je ziekmelding nog steeds volledig ziek bent
                dan begint er het één en ander te veranderen. Na een periode van
                12 aaneengesloten maanden arbeidsongeschikt zijn wordt je
                salaris teruggebracht tot 70% (uitgezonderd terminaal zieken).
                ‘Tegen deze terugval in salaris kun je je overigens verzekeren’,
                volgens Piet. Speciaal daarvoor is er een
                arbeidsongeschiktheidsverzekering die je kunt afsluiten.
              </Typography>
            </ContentColumn>
          </ContentLayoutColumns>
        </RenderWhile>
      </LayoutDefault>
    );
  }
);
