import * as React from "react";
import { withStyles, Theme } from "@material-ui/core";
import { WithStyles, StyleRules } from "@material-ui/styles";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const styles = (theme: Theme): StyleRules => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  black: {
    color: "black"
  },
  slider: {
    color: "white"
  },
  heartBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  heart: {
    margin: 10,
    cursor: "pointer",
    "&:active": {
      outline: "#0000"
    },
    WebkitTapHighlightColor: "#0000"
  }
});

interface OwnProps
  extends WithStyles<"root" | "black" | "slider" | "heartBar" | "heart"> {
  currentValue?: number[];
  minValue?: number;
  maxValue?: number;
  onChange?(value: string[]): void;
}

@observer
class SliderComponent extends React.Component<OwnProps> {
  drawHearts = () => {
    const { classes, maxValue, currentValue } = this.props;
    let el = [];
    for (let i = 0; i < (maxValue || 100); i++) {
      el.push(
        <FontAwesomeIcon
          key={i}
          className={classes.heart}
          style={{ opacity: (currentValue || 0) <= i ? 0.6 : 1 }}
          icon={faHeart}
          size="lg"
          onClick={() => {
            if (this.props.onChange) {
              this.props.onChange([(i + 1).toString()]);
            }
          }}
        />
      );
    }
    return el;
  };

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.heartBar}>{this.drawHearts()}</div>
      </div>
    );
  };
}

export const HeartSlider = withStyles(styles)(SliderComponent);
