import { ArticleFragment } from "./fragments";

export const ArticleCreated = `
subscription {
  magazineCreated {
    ${ArticleFragment}
  }
}
`;

export const ArticleUpdated = `
subscription {
  magazineUpdated {
    ${ArticleFragment}
  }
}
`;

export const ArticleDeleted = `
subscription {
  magazineDeleted {
    ${ArticleFragment}
  }
}
`;
