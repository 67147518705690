import * as React from "react";

import {
  Typography,
  Theme,
  Button,
  Collapse,
  WithStyles,
} from "@material-ui/core";
import { Magazine } from "../../../application/data/magazine/magazine";
import { StyleRules, withStyles } from "@material-ui/styles";
import classnames from "classnames";
import { RenderWhile } from "../components/render-while";

interface OwnProps extends WithStyles<any> {
  magazine: Magazine;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    margin: "auto",
  },
  drawerBackdrop: {},
  header: {},
  content: {},
  backdrop: {
    backgroundColor: "none",
  },
  role: {
    marginBottom: theme.spacing(1),
  },
});

const MagazineIndexInfoComponent = ({ magazine, classes }: OwnProps) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Typography
        className={classnames(classes.montserrat, classes.title)}
        variant="h4"
        color="primary"
        style={{ marginBottom: "1em", lineHeight: 1 }}
      >
        {magazine.description}
      </Typography>
      <RenderWhile desktop print>
        <div>
          {Object.keys(magazine.editors).map((role, user) => {
            return (
              <div key={user} className={classes.role}>
                <Typography
                  style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
                >
                  <small>
                    {/* {magazine.editors[role].type.charAt(0).toUpperCase() + magazine.editors[role].type.substring(1).replace(/([A-Z])/g, ' $1')} */}
                    {magazine.editors[role].type}
                  </small>
                </Typography>
                {magazine.editors[role].users.map((user: string) => (
                  <Typography style={{ margin: "0" }}>
                    <small>{user}</small>
                  </Typography>
                ))}
              </div>
            );
          })}
        </div>
      </RenderWhile>

      <RenderWhile mobile>
        <Collapse in={open}>
          {Object.keys(magazine.editors).map((role, user) => {
            return (
              <>
                <Typography
                  style={{ fontFamily: "Montserrat", fontWeight: "bold" }}
                >
                  {/* {magazine.editors[role].type.charAt(0).toUpperCase() + magazine.editors[role].type.substring(1).replace(/([A-Z])/g, ' $1')} */}
                  {magazine.editors[role].type}
                </Typography>
                {magazine.editors[role].users.map((user: string) => (
                  <Typography variant="h5" style={{ margin: "0" }} key={user}>
                    {user}
                  </Typography>
                ))}
              </>
            );
          })}
        </Collapse>
        <Button
          color="primary"
          variant="contained"
          style={{ display: "flex", marginLeft: "auto" }}
          onClick={() => setOpen(!open)}
        >
          {open ? "Verberg" : "Lees meer"}
        </Button>
      </RenderWhile>
    </>
  );
};

export const MagazineIndexInfo = withStyles(styles)(MagazineIndexInfoComponent);
